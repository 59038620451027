/* tslint:disable */
/* eslint-disable */
/**
 * CrefoTrust API - Core Service API
 * Full version of the API of the CrefoTrust core-services, for docs see https://docs.crefotrust.de
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AcceptOrderDto
 */
export interface AcceptOrderDto {
    /**
     * 
     * @type {string}
     * @memberof AcceptOrderDto
     */
    'userCrefoId': string;
    /**
     * 
     * @type {string}
     * @memberof AcceptOrderDto
     */
    'comment'?: string;
}
/**
 * 
 * @export
 * @interface Account
 */
export interface Account {
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'did': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'auth': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'crid': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'verified': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'crefoId': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface AddOrderStateDto
 */
export interface AddOrderStateDto {
    /**
     * 
     * @type {number}
     * @memberof AddOrderStateDto
     */
    'code': AddOrderStateDtoCodeEnum;
    /**
     * 
     * @type {object}
     * @memberof AddOrderStateDto
     */
    'payload'?: object;
    /**
     * 
     * @type {string}
     * @memberof AddOrderStateDto
     */
    'did'?: string;
}

export const AddOrderStateDtoCodeEnum = {
    NUMBER_124: 124,
    NUMBER_0: 0,
    NUMBER_128: 128
} as const;

export type AddOrderStateDtoCodeEnum = typeof AddOrderStateDtoCodeEnum[keyof typeof AddOrderStateDtoCodeEnum];

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * Street and number.
     * @type {string}
     * @memberof Address
     */
    'streetAddress': string;
    /**
     * Postal code of the city.
     * @type {string}
     * @memberof Address
     */
    'postalCode': string;
    /**
     * Name of the city.
     * @type {string}
     * @memberof Address
     */
    'addressLocality': string;
    /**
     * ISO code of the country.
     * @type {string}
     * @memberof Address
     */
    'addressCountry': string;
}
/**
 * 
 * @export
 * @interface AddressDto
 */
export interface AddressDto {
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    'streetAddress': string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    'addressLocality': string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    'addressCountry': string;
}
/**
 * 
 * @export
 * @interface AdminAccountsResponse
 */
export interface AdminAccountsResponse {
    /**
     * 
     * @type {number}
     * @memberof AdminAccountsResponse
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof AdminAccountsResponse
     */
    'totalCount': number;
    /**
     * 
     * @type {Array<Account>}
     * @memberof AdminAccountsResponse
     */
    'data': Array<Account>;
}
/**
 * 
 * @export
 * @interface AdminCompaniesResponse
 */
export interface AdminCompaniesResponse {
    /**
     * 
     * @type {number}
     * @memberof AdminCompaniesResponse
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof AdminCompaniesResponse
     */
    'totalCount': number;
    /**
     * 
     * @type {Array<AdminCompanyDto>}
     * @memberof AdminCompaniesResponse
     */
    'data': Array<AdminCompanyDto>;
}
/**
 * 
 * @export
 * @interface AdminCompanyDto
 */
export interface AdminCompanyDto {
    /**
     * 
     * @type {Array<Company>}
     * @memberof AdminCompanyDto
     */
    'company': Array<Company>;
    /**
     * 
     * @type {Array<Company>}
     * @memberof AdminCompanyDto
     */
    'credentials': Array<Company>;
    /**
     * 
     * @type {string}
     * @memberof AdminCompanyDto
     */
    'did': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCompanyDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCompanyDto
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface AdminUser
 */
export interface AdminUser {
    /**
     * The identifier of the admin user
     * @type {string}
     * @memberof AdminUser
     */
    'id': string;
    /**
     * The username, which is unique
     * @type {string}
     * @memberof AdminUser
     */
    'username': string;
    /**
     * The admin user\'s password, in encrypted form
     * @type {string}
     * @memberof AdminUser
     */
    'password': string;
    /**
     * The date when the admin user was created
     * @type {string}
     * @memberof AdminUser
     */
    'createdAt': string;
    /**
     * The date when the admin user was last updated
     * @type {string}
     * @memberof AdminUser
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface AdminVerifiersResponse
 */
export interface AdminVerifiersResponse {
    /**
     * 
     * @type {number}
     * @memberof AdminVerifiersResponse
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof AdminVerifiersResponse
     */
    'totalCount': number;
    /**
     * 
     * @type {Array<Verifier>}
     * @memberof AdminVerifiersResponse
     */
    'data': Array<Verifier>;
}
/**
 * 
 * @export
 * @interface AuthConfig
 */
export interface AuthConfig {
    /**
     * 
     * @type {string}
     * @memberof AuthConfig
     */
    'name': string;
    /**
     * Decides which SSO to use
     * @type {string}
     * @memberof AuthConfig
     */
    'prk'?: string;
    /**
     * base url of auth provider: e.g. keycloak-inv
     * @type {string}
     * @memberof AuthConfig
     */
    'redirectUri'?: string;
    /**
     * base url of auth provider: e.g. keycloak-inv
     * @type {string}
     * @memberof AuthConfig
     */
    'loginUri'?: string;
    /**
     * base url of auth provider: e.g. keycloak-inv
     * @type {string}
     * @memberof AuthConfig
     */
    'registrationUri'?: string;
    /**
     * TODO: add detailed description what clientId is used for
     * @type {string}
     * @memberof AuthConfig
     */
    'protocol'?: string;
    /**
     * TODO: add detailed description what clientId is used for
     * @type {string}
     * @memberof AuthConfig
     */
    'clientId'?: string;
    /**
     * Contains the title displayed in the SSO view
     * @type {string}
     * @memberof AuthConfig
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthConfig
     */
    'realm'?: string;
}
/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'legalName': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'crefoId': string;
    /**
     * 
     * @type {CrefoAddress}
     * @memberof Company
     */
    'address': CrefoAddress;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'legalForm': string;
}
/**
 * 
 * @export
 * @interface CompanyAddressDto
 */
export interface CompanyAddressDto {
    /**
     * 
     * @type {string}
     * @memberof CompanyAddressDto
     */
    'street': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyAddressDto
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyAddressDto
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyAddressDto
     */
    'country': string;
}
/**
 * 
 * @export
 * @interface CompanyDto
 */
export interface CompanyDto {
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    'legalName': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    'crefoId': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    'legalForm'?: string;
    /**
     * 
     * @type {AddressDto}
     * @memberof CompanyDto
     */
    'address': AddressDto;
}
/**
 * 
 * @export
 * @interface CompanyInformationDto
 */
export interface CompanyInformationDto {
    /**
     * 
     * @type {PersonAndCompanyCredentials}
     * @memberof CompanyInformationDto
     */
    'personAndCompanyCredentials': PersonAndCompanyCredentials;
    /**
     * 
     * @type {Array<Employee>}
     * @memberof CompanyInformationDto
     */
    'employees': Array<Employee>;
    /**
     * 
     * @type {Array<Task>}
     * @memberof CompanyInformationDto
     */
    'tasks': Array<Task>;
}
/**
 * 
 * @export
 * @interface ComparisonResultObject
 */
export interface ComparisonResultObject {
    /**
     * 
     * @type {string}
     * @memberof ComparisonResultObject
     */
    'name': ComparisonResultObjectNameEnum;
    /**
     * 
     * @type {object}
     * @memberof ComparisonResultObject
     */
    'compared': object;
    /**
     * 
     * @type {string}
     * @memberof ComparisonResultObject
     */
    'key': string;
    /**
     * 
     * @type {boolean}
     * @memberof ComparisonResultObject
     */
    'isMatching': boolean;
}

export const ComparisonResultObjectNameEnum = {
    GivenNameMatches: 'GIVEN_NAME_MATCHES',
    FirstGivenNameMatches: 'FIRST_GIVEN_NAME_MATCHES',
    AnyGivenNameMatches: 'ANY_GIVEN_NAME_MATCHES',
    FamilyNameMatches: 'FAMILY_NAME_MATCHES',
    PostalCodeMatches: 'POSTAL_CODE_MATCHES',
    BirthdayMatches: 'BIRTHDAY_MATCHES'
} as const;

export type ComparisonResultObjectNameEnum = typeof ComparisonResultObjectNameEnum[keyof typeof ComparisonResultObjectNameEnum];

/**
 * 
 * @export
 * @interface Context
 */
export interface Context {
    /**
     * 
     * @type {string}
     * @memberof Context
     */
    'did': string;
    /**
     * 
     * @type {string}
     * @memberof Context
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof Context
     */
    'flavor': ContextFlavorEnum;
}

export const ContextFlavorEnum = {
    B2B: 'B2B',
    B2C: 'B2C'
} as const;

export type ContextFlavorEnum = typeof ContextFlavorEnum[keyof typeof ContextFlavorEnum];

/**
 * 
 * @export
 * @interface ContextPayloadDto
 */
export interface ContextPayloadDto {
    /**
     * 
     * @type {IdentRequestDto}
     * @memberof ContextPayloadDto
     */
    'identRequest'?: IdentRequestDto;
    /**
     * 
     * @type {object}
     * @memberof ContextPayloadDto
     */
    'customerProvidedData'?: object;
    /**
     * 
     * @type {string}
     * @memberof ContextPayloadDto
     */
    'affiliation'?: ContextPayloadDtoAffiliationEnum;
}

export const ContextPayloadDtoAffiliationEnum = {
    Person: 'PERSON',
    Company: 'COMPANY'
} as const;

export type ContextPayloadDtoAffiliationEnum = typeof ContextPayloadDtoAffiliationEnum[keyof typeof ContextPayloadDtoAffiliationEnum];

/**
 * 
 * @export
 * @interface CreateAdminUser
 */
export interface CreateAdminUser {
    /**
     * The username, which is unique
     * @type {string}
     * @memberof CreateAdminUser
     */
    'username': string;
    /**
     * The admin user\'s password, in encrypted form
     * @type {string}
     * @memberof CreateAdminUser
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface CreateAuthConfigDto
 */
export interface CreateAuthConfigDto {
    /**
     * 
     * @type {string}
     * @memberof CreateAuthConfigDto
     */
    'name': string;
    /**
     * Decides which SSO to use
     * @type {string}
     * @memberof CreateAuthConfigDto
     */
    'prk'?: string;
    /**
     * base url of auth provider: e.g. keycloak-inv
     * @type {string}
     * @memberof CreateAuthConfigDto
     */
    'redirectUri'?: string;
    /**
     * base url of auth provider: e.g. keycloak-inv
     * @type {string}
     * @memberof CreateAuthConfigDto
     */
    'loginUri'?: string;
    /**
     * base url of auth provider: e.g. keycloak-inv
     * @type {string}
     * @memberof CreateAuthConfigDto
     */
    'registrationUri'?: string;
    /**
     * TODO: add detailed description what clientId is used for
     * @type {string}
     * @memberof CreateAuthConfigDto
     */
    'protocol'?: string;
    /**
     * TODO: add detailed description what clientId is used for
     * @type {string}
     * @memberof CreateAuthConfigDto
     */
    'clientId'?: string;
    /**
     * Contains the title displayed in the SSO view
     * @type {string}
     * @memberof CreateAuthConfigDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAuthConfigDto
     */
    'realm'?: string;
}
/**
 * 
 * @export
 * @interface CreateOrderDto
 */
export interface CreateOrderDto {
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDto
     */
    'verifierId'?: string;
    /**
     * 
     * @type {RequiredCredentials}
     * @memberof CreateOrderDto
     */
    'requiredCredentials': RequiredCredentials;
    /**
     * Webhook triggered after completion. See VerifierWebhookResponse below for the response payload structure. Order-id will be appended.
     * @type {string}
     * @memberof CreateOrderDto
     */
    'webhook': string;
    /**
     * 
     * @type {OrderRedirect}
     * @memberof CreateOrderDto
     */
    'redirect': OrderRedirect;
    /**
     * 
     * @type {OrderOptions}
     * @memberof CreateOrderDto
     */
    'options'?: OrderOptions;
    /**
     * Defines all ident methods, that the verifier accepts for its\' holders identification. Methods which are accepted do not necessarily have to be offered.
     * @type {Array<string>}
     * @memberof CreateOrderDto
     */
    'acceptedIdMethods'?: Array<CreateOrderDtoAcceptedIdMethodsEnum>;
    /**
     * Defines all ident methods, that the verifier wants to offer its\' holders for identification. All methods which are offered must be accepted.
     * @type {Array<string>}
     * @memberof CreateOrderDto
     */
    'offeredIdMethods'?: Array<CreateOrderDtoOfferedIdMethodsEnum>;
    /**
     * 
     * @type {OrderPreset}
     * @memberof CreateOrderDto
     */
    'forOrg'?: OrderPreset;
    /**
     * 
     * @type {CreateOrderDtoForPerson}
     * @memberof CreateOrderDto
     */
    'forPerson'?: CreateOrderDtoForPerson;
    /**
     * 
     * @type {CreateOrderDtoAuth}
     * @memberof CreateOrderDto
     */
    'auth'?: CreateOrderDtoAuth;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDto
     */
    'selectedIdentProvider'?: CreateOrderDtoSelectedIdentProviderEnum;
    /**
     * 
     * @type {Person}
     * @memberof CreateOrderDto
     */
    'person'?: Person;
    /**
     * 
     * @type {Company}
     * @memberof CreateOrderDto
     */
    'company'?: Company;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDto
     */
    'type'?: CreateOrderDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDto
     */
    'useCaseName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDto
     */
    'entryUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDto
     */
    'version'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDto
     */
    'lang'?: CreateOrderDtoLangEnum;
    /**
     * 
     * @type {IssuerDto}
     * @memberof CreateOrderDto
     */
    'issuer'?: IssuerDto;
    /**
     * 
     * @type {Person}
     * @memberof CreateOrderDto
     */
    'delegationForPerson'?: Person;
}

export const CreateOrderDtoAcceptedIdMethodsEnum = {
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident',
    PinletterIdent: 'pinletter_ident'
} as const;

export type CreateOrderDtoAcceptedIdMethodsEnum = typeof CreateOrderDtoAcceptedIdMethodsEnum[keyof typeof CreateOrderDtoAcceptedIdMethodsEnum];
export const CreateOrderDtoOfferedIdMethodsEnum = {
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident',
    PinletterIdent: 'pinletter_ident'
} as const;

export type CreateOrderDtoOfferedIdMethodsEnum = typeof CreateOrderDtoOfferedIdMethodsEnum[keyof typeof CreateOrderDtoOfferedIdMethodsEnum];
export const CreateOrderDtoSelectedIdentProviderEnum = {
    CrefoFirmenwissen: 'crefo_firmenwissen',
    Admin: 'admin',
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident',
    PinletterIdent: 'pinletter_ident',
    Did: 'did',
    AuthrepDelegation: 'authrep_delegation',
    PinDelegation: 'pin_delegation',
    PinDelegationPersonal: 'pin_delegation_personal',
    AuthrepOrCompanyAdminDelegation: 'authrep_or_company_admin_delegation'
} as const;

export type CreateOrderDtoSelectedIdentProviderEnum = typeof CreateOrderDtoSelectedIdentProviderEnum[keyof typeof CreateOrderDtoSelectedIdentProviderEnum];
export const CreateOrderDtoTypeEnum = {
    Personal: 'PERSONAL',
    Delegation: 'DELEGATION',
    UseAuthrepDelegation: 'USE_AUTHREP_DELEGATION'
} as const;

export type CreateOrderDtoTypeEnum = typeof CreateOrderDtoTypeEnum[keyof typeof CreateOrderDtoTypeEnum];
export const CreateOrderDtoLangEnum = {
    De: 'de',
    At: 'at',
    Lu: 'lu',
    Fr: 'fr',
    En: 'en'
} as const;

export type CreateOrderDtoLangEnum = typeof CreateOrderDtoLangEnum[keyof typeof CreateOrderDtoLangEnum];

/**
 * Configuration used to authenticate the holders account.
 * @export
 * @interface CreateOrderDtoAuth
 */
export interface CreateOrderDtoAuth {
    /**
     * Decides which SSO to use
     * @type {string}
     * @memberof CreateOrderDtoAuth
     */
    'prk'?: string;
    /**
     * base url of auth provider: e.g. keycloak-inv
     * @type {string}
     * @memberof CreateOrderDtoAuth
     */
    'redirectUri'?: string;
    /**
     * base url of auth provider: e.g. keycloak-inv
     * @type {string}
     * @memberof CreateOrderDtoAuth
     */
    'loginUri'?: string;
    /**
     * base url of auth provider: e.g. keycloak-inv
     * @type {string}
     * @memberof CreateOrderDtoAuth
     */
    'registrationUri'?: string;
    /**
     * TODO: add detailed description what clientId is used for
     * @type {string}
     * @memberof CreateOrderDtoAuth
     */
    'protocol'?: string;
    /**
     * TODO: add detailed description what clientId is used for
     * @type {string}
     * @memberof CreateOrderDtoAuth
     */
    'clientId'?: string;
    /**
     * Contains the title displayed in the SSO view
     * @type {string}
     * @memberof CreateOrderDtoAuth
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDtoAuth
     */
    'realm'?: string;
}
/**
 * Can only be used in combination with \"forOrg\". Marking this as \"mandatory\" requires \"forOrg\" to be also set to \"mandatory\".
 * @export
 * @interface CreateOrderDtoForPerson
 */
export interface CreateOrderDtoForPerson {
    /**
     * An order preset set to mandatory forces the holder to verify for the provided crefoId.
     * @type {boolean}
     * @memberof CreateOrderDtoForPerson
     */
    'mandatory': boolean;
    /**
     * Defines the person / company the holder should be verify for / as.
     * @type {string}
     * @memberof CreateOrderDtoForPerson
     */
    'crefoId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDtoForPerson
     */
    'did'?: string;
}
/**
 * 
 * @export
 * @interface CreatePostRegistrationDto
 */
export interface CreatePostRegistrationDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePostRegistrationDto
     */
    'url': string;
    /**
     * 
     * @type {OICDConfig}
     * @memberof CreatePostRegistrationDto
     */
    'oicdConfig': OICDConfig;
    /**
     * 
     * @type {string}
     * @memberof CreatePostRegistrationDto
     */
    'useCaseName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePostRegistrationDto
     */
    'crefoId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePostRegistrationDto
     */
    'prePrt'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePostRegistrationDto
     */
    'orderId'?: string;
}
/**
 * 
 * @export
 * @interface CreateUseCaseDto
 */
export interface CreateUseCaseDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUseCaseDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUseCaseDto
     */
    'name': string;
    /**
     * 
     * @type {RequiredCredentials}
     * @memberof CreateUseCaseDto
     */
    'requiredCredentials': RequiredCredentials;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUseCaseDto
     */
    'offeredIdMethods': Array<CreateUseCaseDtoOfferedIdMethodsEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUseCaseDto
     */
    'acceptedIdMethods': Array<CreateUseCaseDtoAcceptedIdMethodsEnum>;
    /**
     * 
     * @type {string}
     * @memberof CreateUseCaseDto
     */
    'webhook': string;
    /**
     * 
     * @type {OrderRedirect}
     * @memberof CreateUseCaseDto
     */
    'redirect': OrderRedirect;
    /**
     * 
     * @type {UseCaseContent}
     * @memberof CreateUseCaseDto
     */
    'content'?: UseCaseContent;
    /**
     * 
     * @type {string}
     * @memberof CreateUseCaseDto
     */
    'authConfigName'?: string;
    /**
     * 
     * @type {object}
     * @memberof CreateUseCaseDto
     */
    'selectedIdentProvider'?: object;
    /**
     * 
     * @type {object}
     * @memberof CreateUseCaseDto
     */
    'credentialReleaseMode'?: object;
    /**
     * 
     * @type {AuthConfig}
     * @memberof CreateUseCaseDto
     */
    'auth'?: AuthConfig;
    /**
     * 
     * @type {OrderOptions}
     * @memberof CreateUseCaseDto
     */
    'options'?: OrderOptions;
    /**
     * 
     * @type {string}
     * @memberof CreateUseCaseDto
     */
    'serviceUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUseCaseDto
     */
    'logoUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUseCaseDto
     */
    'verifierId': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUseCaseDto
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUseCaseDto
     */
    'type': CreateUseCaseDtoTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUseCaseDto
     */
    'relatedServices'?: Array<string>;
    /**
     * 
     * @type {Array<UseCase>}
     * @memberof CreateUseCaseDto
     */
    'services'?: Array<UseCase>;
    /**
     * 
     * @type {Verifier}
     * @memberof CreateUseCaseDto
     */
    'verifier'?: Verifier;
    /**
     * 
     * @type {string}
     * @memberof CreateUseCaseDto
     */
    'lang': CreateUseCaseDtoLangEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUseCaseDto
     */
    'baseUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUseCaseDto
     */
    'entryPath'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUseCaseDto
     */
    'entryUrl'?: string;
}

export const CreateUseCaseDtoOfferedIdMethodsEnum = {
    CrefoFirmenwissen: 'crefo_firmenwissen',
    Admin: 'admin',
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident',
    PinletterIdent: 'pinletter_ident',
    Did: 'did',
    AuthrepDelegation: 'authrep_delegation',
    PinDelegation: 'pin_delegation',
    PinDelegationPersonal: 'pin_delegation_personal',
    AuthrepOrCompanyAdminDelegation: 'authrep_or_company_admin_delegation'
} as const;

export type CreateUseCaseDtoOfferedIdMethodsEnum = typeof CreateUseCaseDtoOfferedIdMethodsEnum[keyof typeof CreateUseCaseDtoOfferedIdMethodsEnum];
export const CreateUseCaseDtoAcceptedIdMethodsEnum = {
    CrefoFirmenwissen: 'crefo_firmenwissen',
    Admin: 'admin',
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident',
    PinletterIdent: 'pinletter_ident',
    Did: 'did',
    AuthrepDelegation: 'authrep_delegation',
    PinDelegation: 'pin_delegation',
    PinDelegationPersonal: 'pin_delegation_personal',
    AuthrepOrCompanyAdminDelegation: 'authrep_or_company_admin_delegation'
} as const;

export type CreateUseCaseDtoAcceptedIdMethodsEnum = typeof CreateUseCaseDtoAcceptedIdMethodsEnum[keyof typeof CreateUseCaseDtoAcceptedIdMethodsEnum];
export const CreateUseCaseDtoTypeEnum = {
    Personal: 'PERSONAL',
    Delegation: 'DELEGATION',
    UseAuthrepDelegation: 'USE_AUTHREP_DELEGATION'
} as const;

export type CreateUseCaseDtoTypeEnum = typeof CreateUseCaseDtoTypeEnum[keyof typeof CreateUseCaseDtoTypeEnum];
export const CreateUseCaseDtoLangEnum = {
    De: 'de',
    At: 'at',
    Lu: 'lu',
    Fr: 'fr',
    En: 'en'
} as const;

export type CreateUseCaseDtoLangEnum = typeof CreateUseCaseDtoLangEnum[keyof typeof CreateUseCaseDtoLangEnum];

/**
 * 
 * @export
 * @interface CreateVerifierDto
 */
export interface CreateVerifierDto {
    /**
     * 
     * @type {string}
     * @memberof CreateVerifierDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateVerifierDto
     */
    'customHeaderKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateVerifierDto
     */
    'customHeaderValue'?: string;
    /**
     * 
     * @type {object}
     * @memberof CreateVerifierDto
     */
    'dataEnabler'?: object;
    /**
     * 
     * @type {string}
     * @memberof CreateVerifierDto
     */
    'logoUrl'?: string;
}
/**
 * 
 * @export
 * @interface Credential
 */
export interface Credential {
    /**
     * 
     * @type {string}
     * @memberof Credential
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Credential
     */
    'type': CredentialTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Credential
     */
    'did': string;
    /**
     * 
     * @type {string}
     * @memberof Credential
     */
    'dataSource': CredentialDataSourceEnum;
    /**
     * 
     * @type {object}
     * @memberof Credential
     */
    'payload': object;
    /**
     * 
     * @type {string}
     * @memberof Credential
     */
    'jwt': string;
    /**
     * 
     * @type {string}
     * @memberof Credential
     */
    'status': CredentialStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Credential
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Credential
     */
    'updatedAt': string;
    /**
     * 
     * @type {object}
     * @memberof Credential
     */
    'dataEnabler'?: object;
    /**
     * 
     * @type {string}
     * @memberof Credential
     */
    'reason'?: string;
}

export const CredentialTypeEnum = {
    Crid: 'crid',
    Name: 'name',
    Address: 'address',
    Nationality: 'nationality',
    Birthdate: 'birthdate',
    CrefoId: 'crefoId',
    LegalName: 'legalName',
    LegalForm: 'legalForm',
    AuthorizedRepresentative: 'authorizedRepresentative',
    CompanyAgent: 'companyAgent',
    Authorization: 'authorization',
    CompanyAdmin: 'companyAdmin'
} as const;

export type CredentialTypeEnum = typeof CredentialTypeEnum[keyof typeof CredentialTypeEnum];
export const CredentialDataSourceEnum = {
    CrefoFirmenwissen: 'crefo_firmenwissen',
    Admin: 'admin',
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident',
    PinletterIdent: 'pinletter_ident',
    Did: 'did',
    AuthrepDelegation: 'authrep_delegation',
    PinDelegation: 'pin_delegation',
    PinDelegationPersonal: 'pin_delegation_personal',
    AuthrepOrCompanyAdminDelegation: 'authrep_or_company_admin_delegation'
} as const;

export type CredentialDataSourceEnum = typeof CredentialDataSourceEnum[keyof typeof CredentialDataSourceEnum];
export const CredentialStatusEnum = {
    Valid: 'VALID',
    Revoked: 'REVOKED',
    Expired: 'EXPIRED',
    NotFound: 'NOT_FOUND'
} as const;

export type CredentialStatusEnum = typeof CredentialStatusEnum[keyof typeof CredentialStatusEnum];

/**
 * 
 * @export
 * @interface CredentialState
 */
export interface CredentialState {
    /**
     * 
     * @type {boolean}
     * @memberof CredentialState
     */
    'fulfilled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CredentialState
     */
    'missingCredentials': Array<string>;
}
/**
 * 
 * @export
 * @interface CrefoAddress
 */
export interface CrefoAddress {
    /**
     * 
     * @type {string}
     * @memberof CrefoAddress
     */
    'streetAddress': string;
    /**
     * 
     * @type {string}
     * @memberof CrefoAddress
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof CrefoAddress
     */
    'addressLocality': string;
    /**
     * 
     * @type {string}
     * @memberof CrefoAddress
     */
    'addressCountry': string;
}
/**
 * 
 * @export
 * @interface CrefoIdentOrderLink
 */
export interface CrefoIdentOrderLink {
    /**
     * 
     * @type {string}
     * @memberof CrefoIdentOrderLink
     */
    'OrderID': string;
    /**
     * 
     * @type {string}
     * @memberof CrefoIdentOrderLink
     */
    'TargetURL': string;
    /**
     * 
     * @type {string}
     * @memberof CrefoIdentOrderLink
     */
    'VideoTargetLink'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrefoIdentOrderLink
     */
    'Ref'?: string;
}
/**
 * 
 * @export
 * @interface Employee
 */
export interface Employee {
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'credentialType': string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'familyName': string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'givenName': string;
}
/**
 * 
 * @export
 * @interface ExtOrderResponse
 */
export interface ExtOrderResponse {
    /**
     * 
     * @type {string}
     * @memberof ExtOrderResponse
     */
    'id': string;
    /**
     * The verifier\'s id used for uniquely identifying the verifier.
     * @type {string}
     * @memberof ExtOrderResponse
     */
    'verifierId': string;
    /**
     * 
     * @type {RequiredCredentials}
     * @memberof ExtOrderResponse
     */
    'requiredCredentials': RequiredCredentials;
    /**
     * Webhook triggered after completion. See VerifierWebhookResponse below for the reponse payload structure. Order-id will be appended.
     * @type {string}
     * @memberof ExtOrderResponse
     */
    'webhook': string;
    /**
     * 
     * @type {OrderRedirect}
     * @memberof ExtOrderResponse
     */
    'redirect': OrderRedirect;
    /**
     * 
     * @type {boolean}
     * @memberof ExtOrderResponse
     */
    'completed': boolean;
    /**
     * 
     * @type {OrderOptions}
     * @memberof ExtOrderResponse
     */
    'options': OrderOptions;
    /**
     * 
     * @type {OrderPreset}
     * @memberof ExtOrderResponse
     */
    'forOrg': OrderPreset;
    /**
     * 
     * @type {OrderPreset}
     * @memberof ExtOrderResponse
     */
    'forPerson': OrderPreset;
    /**
     * Defines all ident methods, that the verifier accepts for its\' holders identification. Methods which are accepted do not necessarily have to be offered.
     * @type {Array<string>}
     * @memberof ExtOrderResponse
     */
    'acceptedIdMethods'?: Array<ExtOrderResponseAcceptedIdMethodsEnum>;
    /**
     * Defines all ident methods, that the verifier wants to offer its\' holders for identification. All methods which are offered must be accepted.
     * @type {Array<string>}
     * @memberof ExtOrderResponse
     */
    'offeredIdMethods'?: Array<ExtOrderResponseOfferedIdMethodsEnum>;
    /**
     * An arbitrary data structure provided by the verifier while creating the order.
     * @type {object}
     * @memberof ExtOrderResponse
     */
    'verifierRef'?: object;
    /**
     * 
     * @type {OrderAuthRedirects}
     * @memberof ExtOrderResponse
     */
    'redirects'?: OrderAuthRedirects;
    /**
     * 
     * @type {string}
     * @memberof ExtOrderResponse
     */
    'crefoId': string;
    /**
     * 
     * @type {string}
     * @memberof ExtOrderResponse
     */
    'verifierName': string;
    /**
     * 
     * @type {Person}
     * @memberof ExtOrderResponse
     */
    'person'?: Person;
    /**
     * 
     * @type {Company}
     * @memberof ExtOrderResponse
     */
    'company'?: Company;
    /**
     * 
     * @type {string}
     * @memberof ExtOrderResponse
     */
    'selectedIdentProvider'?: ExtOrderResponseSelectedIdentProviderEnum;
    /**
     * 
     * @type {PersonMatchError}
     * @memberof ExtOrderResponse
     */
    'personMatchErrorLog'?: PersonMatchError;
    /**
     * 
     * @type {OrderState}
     * @memberof ExtOrderResponse
     */
    'currentState': OrderState;
    /**
     * 
     * @type {string}
     * @memberof ExtOrderResponse
     */
    'useCaseName'?: string;
    /**
     * Entry point (url) for the holder to start into the CrefoTrust process.
     * @type {string}
     * @memberof ExtOrderResponse
     */
    'entryUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ExtOrderResponse
     */
    'lang'?: ExtOrderResponseLangEnum;
    /**
     * 
     * @type {OrderIssuer}
     * @memberof ExtOrderResponse
     */
    'issuer'?: OrderIssuer;
    /**
     * 
     * @type {Array<OrderState>}
     * @memberof ExtOrderResponse
     */
    'previousStates'?: Array<OrderState>;
    /**
     * 
     * @type {Person}
     * @memberof ExtOrderResponse
     */
    'delegationForPerson'?: Person;
}

export const ExtOrderResponseAcceptedIdMethodsEnum = {
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident'
} as const;

export type ExtOrderResponseAcceptedIdMethodsEnum = typeof ExtOrderResponseAcceptedIdMethodsEnum[keyof typeof ExtOrderResponseAcceptedIdMethodsEnum];
export const ExtOrderResponseOfferedIdMethodsEnum = {
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident'
} as const;

export type ExtOrderResponseOfferedIdMethodsEnum = typeof ExtOrderResponseOfferedIdMethodsEnum[keyof typeof ExtOrderResponseOfferedIdMethodsEnum];
export const ExtOrderResponseSelectedIdentProviderEnum = {
    CrefoFirmenwissen: 'crefo_firmenwissen',
    Admin: 'admin',
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident',
    PinletterIdent: 'pinletter_ident',
    Did: 'did',
    AuthrepDelegation: 'authrep_delegation',
    PinDelegation: 'pin_delegation',
    PinDelegationPersonal: 'pin_delegation_personal',
    AuthrepOrCompanyAdminDelegation: 'authrep_or_company_admin_delegation'
} as const;

export type ExtOrderResponseSelectedIdentProviderEnum = typeof ExtOrderResponseSelectedIdentProviderEnum[keyof typeof ExtOrderResponseSelectedIdentProviderEnum];
export const ExtOrderResponseLangEnum = {
    De: 'de',
    At: 'at',
    Lu: 'lu',
    Fr: 'fr',
    En: 'en'
} as const;

export type ExtOrderResponseLangEnum = typeof ExtOrderResponseLangEnum[keyof typeof ExtOrderResponseLangEnum];

/**
 * 
 * @export
 * @interface FileUpload
 */
export interface FileUpload {
    /**
     * 
     * @type {string}
     * @memberof FileUpload
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileUpload
     */
    'did': string;
    /**
     * 
     * @type {string}
     * @memberof FileUpload
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof FileUpload
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FileUpload
     */
    'mimetype': string;
    /**
     * 
     * @type {string}
     * @memberof FileUpload
     */
    'ref': string;
}
/**
 * 
 * @export
 * @interface Holder
 */
export interface Holder {
    /**
     * 
     * @type {string}
     * @memberof Holder
     */
    'did': string;
    /**
     * 
     * @type {string}
     * @memberof Holder
     */
    'companyDid': string;
    /**
     * 
     * @type {PersonDto}
     * @memberof Holder
     */
    'person': PersonDto;
    /**
     * 
     * @type {Array<Company>}
     * @memberof Holder
     */
    'companies': Array<Company>;
    /**
     * 
     * @type {Array<Company>}
     * @memberof Holder
     */
    'representedCompanies': Array<Company>;
    /**
     * 
     * @type {HolderCredentialsMap}
     * @memberof Holder
     */
    'credentials': HolderCredentialsMap;
}
/**
 * 
 * @export
 * @interface HolderCredentialState
 */
export interface HolderCredentialState {
    /**
     * 
     * @type {CredentialState}
     * @memberof HolderCredentialState
     */
    'personCredentialState': CredentialState;
    /**
     * 
     * @type {CredentialState}
     * @memberof HolderCredentialState
     */
    'companyCredentialState': CredentialState;
    /**
     * 
     * @type {CredentialState}
     * @memberof HolderCredentialState
     */
    'representationCredentialState': CredentialState;
}
/**
 * 
 * @export
 * @interface HolderCredentialsMap
 */
export interface HolderCredentialsMap {
    /**
     * 
     * @type {Array<Credential>}
     * @memberof HolderCredentialsMap
     */
    'person': Array<Credential>;
    /**
     * 
     * @type {Array<Credential>}
     * @memberof HolderCredentialsMap
     */
    'org': Array<Credential>;
    /**
     * 
     * @type {Array<Credential>}
     * @memberof HolderCredentialsMap
     */
    'representation': Array<Credential>;
}
/**
 * 
 * @export
 * @interface HolderIdentificationState
 */
export interface HolderIdentificationState {
    /**
     * 
     * @type {string}
     * @memberof HolderIdentificationState
     */
    'name': HolderIdentificationStateNameEnum;
    /**
     * 
     * @type {object}
     * @memberof HolderIdentificationState
     */
    'value'?: object;
}

export const HolderIdentificationStateNameEnum = {
    Mismatch: 'mismatch',
    Success: 'success',
    CustomerInputRequired: 'customerInputRequired',
    PendingIdentDataCorrection: 'pendingIdentDataCorrection',
    NeedCompanySelection: 'needCompanySelection',
    Error: 'error',
    Unknown: 'unknown',
    IdentError: 'identError'
} as const;

export type HolderIdentificationStateNameEnum = typeof HolderIdentificationStateNameEnum[keyof typeof HolderIdentificationStateNameEnum];

/**
 * 
 * @export
 * @interface HolderState
 */
export interface HolderState {
    /**
     * 
     * @type {HolderStateData}
     * @memberof HolderState
     */
    'data': HolderStateData;
    /**
     * 
     * @type {Context}
     * @memberof HolderState
     */
    'context': Context;
    /**
     * 
     * @type {HolderStateMeta}
     * @memberof HolderState
     */
    'meta': HolderStateMeta;
    /**
     * 
     * @type {RoutingState}
     * @memberof HolderState
     */
    'routing': RoutingState;
    /**
     * 
     * @type {HolderStateActions}
     * @memberof HolderState
     */
    'actions'?: HolderStateActions;
}
/**
 * 
 * @export
 * @interface HolderStateActions
 */
export interface HolderStateActions {
    /**
     * 
     * @type {Array<string>}
     * @memberof HolderStateActions
     */
    'customerRequiredData': Array<HolderStateActionsCustomerRequiredDataEnum>;
}

export const HolderStateActionsCustomerRequiredDataEnum = {
    Crid: 'crid',
    Name: 'name',
    Address: 'address',
    Nationality: 'nationality',
    Birthdate: 'birthdate',
    CrefoId: 'crefoId',
    LegalName: 'legalName',
    LegalForm: 'legalForm',
    AuthorizedRepresentative: 'authorizedRepresentative',
    CompanyAgent: 'companyAgent',
    Authorization: 'authorization',
    CompanyAdmin: 'companyAdmin'
} as const;

export type HolderStateActionsCustomerRequiredDataEnum = typeof HolderStateActionsCustomerRequiredDataEnum[keyof typeof HolderStateActionsCustomerRequiredDataEnum];

/**
 * 
 * @export
 * @interface HolderStateData
 */
export interface HolderStateData {
    /**
     * 
     * @type {Array<Credential>}
     * @memberof HolderStateData
     */
    'personCredentials': Array<Credential>;
    /**
     * 
     * @type {Array<Credential>}
     * @memberof HolderStateData
     */
    'companyCredentials': Array<Credential>;
    /**
     * 
     * @type {Array<PersonRequest>}
     * @memberof HolderStateData
     */
    'requests': Array<PersonRequest>;
    /**
     * 
     * @type {ExtOrderResponse}
     * @memberof HolderStateData
     */
    'order': ExtOrderResponse;
}
/**
 * 
 * @export
 * @interface HolderStateLink
 */
export interface HolderStateLink {
    /**
     * 
     * @type {string}
     * @memberof HolderStateLink
     */
    'href'?: string;
    /**
     * 
     * @type {string}
     * @memberof HolderStateLink
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface HolderStateMeta
 */
export interface HolderStateMeta {
    /**
     * 
     * @type {IDMethods}
     * @memberof HolderStateMeta
     */
    'identProviders': IDMethods;
    /**
     * 
     * @type {HolderStateMetaOrderPresets}
     * @memberof HolderStateMeta
     */
    'orderPresets'?: HolderStateMetaOrderPresets;
    /**
     * 
     * @type {string}
     * @memberof HolderStateMeta
     */
    'verifierName'?: string;
    /**
     * 
     * @type {RequiredCredentials}
     * @memberof HolderStateMeta
     */
    'requiredCredentials': RequiredCredentials;
    /**
     * 
     * @type {HolderCredentialState}
     * @memberof HolderStateMeta
     */
    'credentialState'?: HolderCredentialState;
    /**
     * 
     * @type {HolderIdentificationState}
     * @memberof HolderStateMeta
     */
    'identificationState'?: HolderIdentificationState;
    /**
     * 
     * @type {HolderStateLink}
     * @memberof HolderStateMeta
     */
    'link'?: HolderStateLink;
    /**
     * 
     * @type {Array<string>}
     * @memberof HolderStateMeta
     */
    'authorizedFor'?: Array<string>;
}
/**
 * 
 * @export
 * @interface HolderStateMetaOrderPresets
 */
export interface HolderStateMetaOrderPresets {
    /**
     * 
     * @type {OrderPreset}
     * @memberof HolderStateMetaOrderPresets
     */
    'forOrg': OrderPreset;
    /**
     * 
     * @type {OrderPreset}
     * @memberof HolderStateMetaOrderPresets
     */
    'forPerson': OrderPreset;
    /**
     * 
     * @type {string}
     * @memberof HolderStateMetaOrderPresets
     */
    'selectedIdentProvider': HolderStateMetaOrderPresetsSelectedIdentProviderEnum;
}

export const HolderStateMetaOrderPresetsSelectedIdentProviderEnum = {
    CrefoFirmenwissen: 'crefo_firmenwissen',
    Admin: 'admin',
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident',
    PinletterIdent: 'pinletter_ident',
    Did: 'did',
    AuthrepDelegation: 'authrep_delegation',
    PinDelegation: 'pin_delegation',
    PinDelegationPersonal: 'pin_delegation_personal',
    AuthrepOrCompanyAdminDelegation: 'authrep_or_company_admin_delegation'
} as const;

export type HolderStateMetaOrderPresetsSelectedIdentProviderEnum = typeof HolderStateMetaOrderPresetsSelectedIdentProviderEnum[keyof typeof HolderStateMetaOrderPresetsSelectedIdentProviderEnum];

/**
 * 
 * @export
 * @interface HttpErrorDescription
 */
export interface HttpErrorDescription {
    /**
     * 
     * @type {HttpErrorDescriptions}
     * @memberof HttpErrorDescription
     */
    'descriptions': HttpErrorDescriptions;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const HttpErrorDescriptions = {
    NoOrderFound: 'NO_ORDER_FOUND',
    NoVerifierFound: 'NO_VERIFIER_FOUND',
    NoRequestFound: 'NO_REQUEST_FOUND',
    NoAuthToIssueCredentials: 'NO_AUTH_TO_ISSUE_CREDENTIALS',
    NoUsecaseFound: 'NO_USECASE_FOUND',
    NoAccountFound: 'NO_ACCOUNT_FOUND',
    NoIdentData: 'NO_IDENT_DATA',
    NoCredFound: 'NO_CRED_FOUND',
    NoAuthConfig: 'NO_AUTH_CONFIG',
    AuthConfigCreationError: 'AUTH_CONFIG_CREATION_ERROR',
    PropMissing: 'PROP_MISSING',
    NoDidFound: 'NO_DID_FOUND',
    NoAccountFoundOnUpdate: 'NO_ACCOUNT_FOUND_ON_UPDATE',
    NoUsecaseFoundOnUpdate: 'NO_USECASE_FOUND_ON_UPDATE',
    IdentProviderMismatch: 'IDENT_PROVIDER_MISMATCH',
    ApiKeyInvalid: 'API_KEY_INVALID',
    MissingJwt: 'MISSING_JWT',
    NoAuthHeader: 'NO_AUTH_HEADER',
    HeaderValueMissing: 'HEADER_VALUE_MISSING',
    ApiKeyMissing: 'API_KEY_MISSING',
    NotAuthVerifier: 'NOT_AUTH_VERIFIER',
    CannotIssueCreds: 'CANNOT_ISSUE_CREDS',
    CrefoIdMismatch: 'CREFO_ID_MISMATCH',
    UploadLimitReached: 'UPLOAD_LIMIT_REACHED',
    ValidationError: 'VALIDATION_ERROR',
    InvalidDatasource: 'INVALID_DATASOURCE',
    InvalidIdentData: 'INVALID_IDENT_DATA',
    MonitoringError: 'MONITORING_ERROR',
    SchedulerError: 'SCHEDULER_ERROR',
    StorageError: 'STORAGE_ERROR',
    OrderCreation: 'ORDER_CREATION',
    PinOrderCreation: 'PIN_ORDER_CREATION',
    RedirectUrlSubstituitionFailed: 'REDIRECT_URL_SUBSTITUITION_FAILED',
    Econnrefused: 'ECONNREFUSED',
    CompanyCredentialCreationError: 'COMPANY_CREDENTIAL_CREATION_ERROR',
    InvalidType: 'INVALID_TYPE',
    NoFilesFound: 'NO_FILES_FOUND',
    NoAdminUserFound: 'NO_ADMIN_USER_FOUND'
} as const;

export type HttpErrorDescriptions = typeof HttpErrorDescriptions[keyof typeof HttpErrorDescriptions];


/**
 * 
 * @export
 * @interface IDMethods
 */
export interface IDMethods {
    /**
     * Defines all ident methods, that the verifier accepts for its\' holders identification. Methods which are accepted do not necessarily have to be offered.
     * @type {Array<string>}
     * @memberof IDMethods
     */
    'acceptedIDMethods': Array<IDMethodsAcceptedIDMethodsEnum>;
    /**
     * Defines all ident methods, that the verifier wants to offer its\' holders for identification. All methods which are offered must be accepted.
     * @type {Array<string>}
     * @memberof IDMethods
     */
    'offeredIDMethods': Array<IDMethodsOfferedIDMethodsEnum>;
}

export const IDMethodsAcceptedIDMethodsEnum = {
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident',
    PinletterIdent: 'pinletter_ident',
    AuthrepDelegation: 'authrep_delegation',
    PinDelegation: 'pin_delegation',
    PinDelegationPersonal: 'pin_delegation_personal'
} as const;

export type IDMethodsAcceptedIDMethodsEnum = typeof IDMethodsAcceptedIDMethodsEnum[keyof typeof IDMethodsAcceptedIDMethodsEnum];
export const IDMethodsOfferedIDMethodsEnum = {
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident',
    PinletterIdent: 'pinletter_ident',
    AuthrepDelegation: 'authrep_delegation',
    PinDelegation: 'pin_delegation',
    PinDelegationPersonal: 'pin_delegation_personal'
} as const;

export type IDMethodsOfferedIDMethodsEnum = typeof IDMethodsOfferedIDMethodsEnum[keyof typeof IDMethodsOfferedIDMethodsEnum];

/**
 * 
 * @export
 * @interface IdentData
 */
export interface IdentData {
    /**
     * 
     * @type {string}
     * @memberof IdentData
     */
    'externalOrderId': string;
    /**
     * 
     * @type {string}
     * @memberof IdentData
     */
    'givenName': string;
    /**
     * 
     * @type {string}
     * @memberof IdentData
     */
    'familyName': string;
    /**
     * 
     * @type {string}
     * @memberof IdentData
     */
    'nameSuffix'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentData
     */
    'street': string;
    /**
     * 
     * @type {string}
     * @memberof IdentData
     */
    'zipCode': string;
    /**
     * 
     * @type {string}
     * @memberof IdentData
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof IdentData
     */
    'birthdate': string;
    /**
     * 
     * @type {string}
     * @memberof IdentData
     */
    'birthplace': string;
    /**
     * 
     * @type {number}
     * @memberof IdentData
     */
    'type'?: number;
    /**
     * 
     * @type {string}
     * @memberof IdentData
     */
    'nationality'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentData
     */
    'gender'?: string;
    /**
     * 
     * @type {IdentDocument}
     * @memberof IdentData
     */
    'idCard'?: IdentDocument;
    /**
     * 
     * @type {string}
     * @memberof IdentData
     */
    'mobile'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentData
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentData
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentData
     */
    'identProvider': IdentDataIdentProviderEnum;
    /**
     * 
     * @type {string}
     * @memberof IdentData
     */
    'orderId': string;
}

export const IdentDataIdentProviderEnum = {
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident',
    PinletterIdent: 'pinletter_ident'
} as const;

export type IdentDataIdentProviderEnum = typeof IdentDataIdentProviderEnum[keyof typeof IdentDataIdentProviderEnum];

/**
 * 
 * @export
 * @interface IdentDocument
 */
export interface IdentDocument {
    /**
     * 
     * @type {string}
     * @memberof IdentDocument
     */
    'cardNo'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentDocument
     */
    'placeOfIssue'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentDocument
     */
    'validUntil'?: string;
    /**
     * 
     * @type {object}
     * @memberof IdentDocument
     */
    'type'?: object;
    /**
     * 
     * @type {string}
     * @memberof IdentDocument
     */
    'issuer'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IdentDocument
     */
    'hasAddressSticker'?: boolean;
}
/**
 * 
 * @export
 * @interface IdentRequestDto
 */
export interface IdentRequestDto {
    /**
     * 
     * @type {string}
     * @memberof IdentRequestDto
     */
    'selectedIdentProvider'?: IdentRequestDtoSelectedIdentProviderEnum;
    /**
     * 
     * @type {PersonDto}
     * @memberof IdentRequestDto
     */
    'selectedPerson'?: PersonDto;
    /**
     * 
     * @type {CompanyDto}
     * @memberof IdentRequestDto
     */
    'selectedCompany'?: CompanyDto;
}

export const IdentRequestDtoSelectedIdentProviderEnum = {
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident',
    PinletterIdent: 'pinletter_ident',
    PinDelegation: 'pin_delegation',
    PinDelegationPersonal: 'pin_delegation_personal',
    AuthrepDelegation: 'authrep_delegation'
} as const;

export type IdentRequestDtoSelectedIdentProviderEnum = typeof IdentRequestDtoSelectedIdentProviderEnum[keyof typeof IdentRequestDtoSelectedIdentProviderEnum];

/**
 * 
 * @export
 * @interface IsSameCrefoIdResponse
 */
export interface IsSameCrefoIdResponse {
    /**
     * 
     * @type {boolean}
     * @memberof IsSameCrefoIdResponse
     */
    'isSameCrefoId': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IsSameCrefoIdResponse
     */
    'isMember': boolean;
    /**
     * 
     * @type {Company}
     * @memberof IsSameCrefoIdResponse
     */
    'verifiedFor'?: Company;
    /**
     * 
     * @type {Company}
     * @memberof IsSameCrefoIdResponse
     */
    'depositedOnAccount'?: Company;
}
/**
 * 
 * @export
 * @interface IsValidVerifierDto
 */
export interface IsValidVerifierDto {
    /**
     * 
     * @type {string}
     * @memberof IsValidVerifierDto
     */
    'verifierId': string;
    /**
     * 
     * @type {string}
     * @memberof IsValidVerifierDto
     */
    'apiKey': string;
}
/**
 * 
 * @export
 * @interface IssuerDto
 */
export interface IssuerDto {
    /**
     * 
     * @type {string}
     * @memberof IssuerDto
     */
    'givenName': string;
    /**
     * 
     * @type {string}
     * @memberof IssuerDto
     */
    'familyName': string;
    /**
     * 
     * @type {string}
     * @memberof IssuerDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof IssuerDto
     */
    'birthDate'?: string;
    /**
     * 
     * @type {CrefoAddress}
     * @memberof IssuerDto
     */
    'address'?: CrefoAddress;
    /**
     * 
     * @type {string}
     * @memberof IssuerDto
     */
    'did'?: string;
    /**
     * 
     * @type {string}
     * @memberof IssuerDto
     */
    'crefoId'?: string;
}
/**
 * 
 * @export
 * @interface Manager
 */
export interface Manager {
    /**
     * 
     * @type {string}
     * @memberof Manager
     */
    'givenName': string;
    /**
     * 
     * @type {string}
     * @memberof Manager
     */
    'familyName': string;
    /**
     * 
     * @type {string}
     * @memberof Manager
     */
    'birthDate': string;
    /**
     * 
     * @type {string}
     * @memberof Manager
     */
    'crefoId': string;
    /**
     * 
     * @type {CrefoAddress}
     * @memberof Manager
     */
    'address': CrefoAddress;
    /**
     * 
     * @type {boolean}
     * @memberof Manager
     */
    'isAuthorizedRepresentative'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Manager
     */
    'did'?: string;
}
/**
 * 
 * @export
 * @interface MetaData
 */
export interface MetaData {
    /**
     * Timestamp (unix epoch) when this information was created.
     * @type {number}
     * @memberof MetaData
     */
    'issuedAt': number;
    /**
     * The user (DID) to that this information regards to.
     * @type {string}
     * @memberof MetaData
     */
    'id': string;
    /**
     * This endpoint (url) provided by CrefoTrust can be used to check the current staus of the provided information.
     * @type {string}
     * @memberof MetaData
     */
    'status': string;
    /**
     * Issuer (DID) of the credential data.
     * @type {string}
     * @memberof MetaData
     */
    'issuer': string;
}
/**
 * 
 * @export
 * @interface Name
 */
export interface Name {
    /**
     * 
     * @type {string}
     * @memberof Name
     */
    'familyName': string;
    /**
     * 
     * @type {string}
     * @memberof Name
     */
    'givenName': string;
}
/**
 * 
 * @export
 * @interface OICDConfig
 */
export interface OICDConfig {
    /**
     * Login URI to be used.
     * @type {string}
     * @memberof OICDConfig
     */
    'loginUri': string;
    /**
     * Registration URI to be used
     * @type {string}
     * @memberof OICDConfig
     */
    'registrationUri': string;
    /**
     * ClientId to be used for the auth provider.
     * @type {string}
     * @memberof OICDConfig
     */
    'clientId': string;
    /**
     * Base Url to the authority
     * @type {string}
     * @memberof OICDConfig
     */
    'authority': string;
    /**
     * 
     * @type {string}
     * @memberof OICDConfig
     */
    'prk': string;
    /**
     * 
     * @type {string}
     * @memberof OICDConfig
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof OICDConfig
     */
    'realm': string;
    /**
     * Entry language
     * @type {string}
     * @memberof OICDConfig
     */
    'lang': string;
    /**
     * Post registration token. Used to restore session after registration process.
     * @type {string}
     * @memberof OICDConfig
     */
    'prt': string;
    /**
     * Uri used after registration process to redirect the user to the correct page.
     * @type {string}
     * @memberof OICDConfig
     */
    'redirectUri'?: string;
}
/**
 * 
 * @export
 * @interface Order
 */
export interface Order {
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'verifierId': string;
    /**
     * 
     * @type {RequiredCredentials}
     * @memberof Order
     */
    'requiredCredentials': RequiredCredentials;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'webhook': string;
    /**
     * 
     * @type {OrderRedirect}
     * @memberof Order
     */
    'redirect': OrderRedirect;
    /**
     * 
     * @type {IDMethods}
     * @memberof Order
     */
    'idMethods': IDMethods;
    /**
     * 
     * @type {Verifier}
     * @memberof Order
     */
    'verifier'?: Verifier;
    /**
     * 
     * @type {Account}
     * @memberof Order
     */
    'account'?: Account;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'selectedIdentProvider'?: OrderSelectedIdentProviderEnum;
    /**
     * 
     * @type {Person}
     * @memberof Order
     */
    'person'?: Person;
    /**
     * 
     * @type {Company}
     * @memberof Order
     */
    'company'?: Company;
    /**
     * 
     * @type {PersonMatchError}
     * @memberof Order
     */
    'personMatchErrorLog'?: PersonMatchError;
    /**
     * 
     * @type {OrderState}
     * @memberof Order
     */
    'currentState': OrderState;
    /**
     * 
     * @type {boolean}
     * @memberof Order
     */
    'completed': boolean;
    /**
     * 
     * @type {OrderOptions}
     * @memberof Order
     */
    'options'?: OrderOptions;
    /**
     * 
     * @type {OrderPreset}
     * @memberof Order
     */
    'forOrg'?: OrderPreset;
    /**
     * 
     * @type {OrderPreset}
     * @memberof Order
     */
    'forPerson'?: OrderPreset;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {OrderAuthConfig}
     * @memberof Order
     */
    'auth'?: OrderAuthConfig;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'contextFlavor'?: OrderContextFlavorEnum;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'credentialReleaseMode'?: OrderCredentialReleaseModeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof Order
     */
    'pendingPersonRequests'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'type'?: OrderTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'useCaseName'?: string;
    /**
     * 
     * @type {UseCase}
     * @memberof Order
     */
    'useCase'?: UseCase;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'entryUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'version'?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'lang'?: OrderLangEnum;
    /**
     * 
     * @type {OrderIssuer}
     * @memberof Order
     */
    'issuer'?: OrderIssuer;
    /**
     * 
     * @type {Array<string>}
     * @memberof Order
     */
    'previousStates'?: Array<string>;
    /**
     * 
     * @type {Person}
     * @memberof Order
     */
    'delegationForPerson'?: Person;
}

export const OrderSelectedIdentProviderEnum = {
    CrefoFirmenwissen: 'crefo_firmenwissen',
    Admin: 'admin',
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident',
    PinletterIdent: 'pinletter_ident',
    Did: 'did',
    AuthrepDelegation: 'authrep_delegation',
    PinDelegation: 'pin_delegation',
    PinDelegationPersonal: 'pin_delegation_personal',
    AuthrepOrCompanyAdminDelegation: 'authrep_or_company_admin_delegation'
} as const;

export type OrderSelectedIdentProviderEnum = typeof OrderSelectedIdentProviderEnum[keyof typeof OrderSelectedIdentProviderEnum];
export const OrderContextFlavorEnum = {
    B2B: 'B2B',
    B2C: 'B2C'
} as const;

export type OrderContextFlavorEnum = typeof OrderContextFlavorEnum[keyof typeof OrderContextFlavorEnum];
export const OrderCredentialReleaseModeEnum = {
    Manual: 'manual',
    Automatic: 'automatic'
} as const;

export type OrderCredentialReleaseModeEnum = typeof OrderCredentialReleaseModeEnum[keyof typeof OrderCredentialReleaseModeEnum];
export const OrderTypeEnum = {
    Personal: 'PERSONAL',
    Delegation: 'DELEGATION',
    UseAuthrepDelegation: 'USE_AUTHREP_DELEGATION'
} as const;

export type OrderTypeEnum = typeof OrderTypeEnum[keyof typeof OrderTypeEnum];
export const OrderLangEnum = {
    De: 'de',
    At: 'at',
    Lu: 'lu',
    Fr: 'fr',
    En: 'en'
} as const;

export type OrderLangEnum = typeof OrderLangEnum[keyof typeof OrderLangEnum];

/**
 * 
 * @export
 * @interface OrderAuthConfig
 */
export interface OrderAuthConfig {
    /**
     * Decides which SSO to use
     * @type {string}
     * @memberof OrderAuthConfig
     */
    'prk'?: string;
    /**
     * base url of auth provider: e.g. keycloak-inv
     * @type {string}
     * @memberof OrderAuthConfig
     */
    'redirectUri'?: string;
    /**
     * base url of auth provider: e.g. keycloak-inv
     * @type {string}
     * @memberof OrderAuthConfig
     */
    'loginUri'?: string;
    /**
     * base url of auth provider: e.g. keycloak-inv
     * @type {string}
     * @memberof OrderAuthConfig
     */
    'registrationUri'?: string;
    /**
     * TODO: add detailed description what clientId is used for
     * @type {string}
     * @memberof OrderAuthConfig
     */
    'protocol'?: string;
    /**
     * TODO: add detailed description what clientId is used for
     * @type {string}
     * @memberof OrderAuthConfig
     */
    'clientId'?: string;
    /**
     * Contains the title displayed in the SSO view
     * @type {string}
     * @memberof OrderAuthConfig
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderAuthConfig
     */
    'realm'?: string;
}
/**
 * 
 * @export
 * @interface OrderAuthRedirects
 */
export interface OrderAuthRedirects {
    /**
     * ClientId to be used for the auth provider.
     * @type {string}
     * @memberof OrderAuthRedirects
     */
    'clientId': string;
    /**
     * Base Url to the authority
     * @type {string}
     * @memberof OrderAuthRedirects
     */
    'authority': string;
    /**
     * Base domain of the redirect.
     * @type {string}
     * @memberof OrderAuthRedirects
     */
    'host': string;
    /**
     * Redirect URI to the user frontend.
     * @type {string}
     * @memberof OrderAuthRedirects
     */
    'redirectUri': string;
    /**
     * Redirect URI to the login page of the auth provider.
     * @type {string}
     * @memberof OrderAuthRedirects
     */
    'loginUri': string;
    /**
     * Redirect URI to the registration page of the auth provider.
     * @type {string}
     * @memberof OrderAuthRedirects
     */
    'registrationUri': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAuthRedirects
     */
    'prk': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAuthRedirects
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAuthRedirects
     */
    'prt'?: string;
}
/**
 * 
 * @export
 * @interface OrderCreationResponse
 */
export interface OrderCreationResponse {
    /**
     * Entry point (url) for the holder to start into the CrefoTrust process.
     * @type {string}
     * @memberof OrderCreationResponse
     */
    'entryUrl': string;
    /**
     * Entry point (url) for the holder to start into the CrefoTrust process.
     * @type {string}
     * @memberof OrderCreationResponse
     * @deprecated
     */
    'url': string;
    /**
     * Unique identifier to reference the order.
     * @type {string}
     * @memberof OrderCreationResponse
     */
    'orderId': string;
    /**
     * An arbitrary data structure provided by the verifier while creating the order.
     * @type {object}
     * @memberof OrderCreationResponse
     */
    'verifierRef'?: object;
    /**
     * 
     * @type {OrderAuthRedirects}
     * @memberof OrderCreationResponse
     */
    'redirects': OrderAuthRedirects;
    /**
     * 
     * @type {string}
     * @memberof OrderCreationResponse
     */
    'crefoId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderCreationResponse
     */
    'verifierName': string;
    /**
     * 
     * @type {Company}
     * @memberof OrderCreationResponse
     */
    'company'?: Company;
}
/**
 * 
 * @export
 * @interface OrderIssuer
 */
export interface OrderIssuer {
    /**
     * 
     * @type {string}
     * @memberof OrderIssuer
     */
    'givenName': string;
    /**
     * 
     * @type {string}
     * @memberof OrderIssuer
     */
    'familyName': string;
    /**
     * 
     * @type {string}
     * @memberof OrderIssuer
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof OrderIssuer
     */
    'birthDate'?: string;
    /**
     * 
     * @type {CrefoAddress}
     * @memberof OrderIssuer
     */
    'address'?: CrefoAddress;
    /**
     * 
     * @type {string}
     * @memberof OrderIssuer
     */
    'did'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderIssuer
     */
    'crefoId'?: string;
}
/**
 * 
 * @export
 * @interface OrderOptions
 */
export interface OrderOptions {
    /**
     * 
     * @type {string}
     * @memberof OrderOptions
     */
    'correlationId'?: string;
    /**
     * Name of the usecase that is going to be related to this
     * @type {string}
     * @memberof OrderOptions
     */
    'useCase'?: string;
    /**
     * Brand name of the verifier.
     * @type {string}
     * @memberof OrderOptions
     */
    'verifierName'?: string;
    /**
     * An arbitrary data structure provided by the verifier. Content will be transmitted on every response to the verifier.
     * @type {object}
     * @memberof OrderOptions
     */
    'verifierRef'?: object;
}
/**
 * 
 * @export
 * @interface OrderPreset
 */
export interface OrderPreset {
    /**
     * An order preset set to mandatory forces the holder to verify for the provided crefoId.
     * @type {boolean}
     * @memberof OrderPreset
     */
    'mandatory': boolean;
    /**
     * Defines the person / company the holder should be verify for / as.
     * @type {string}
     * @memberof OrderPreset
     */
    'crefoId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPreset
     */
    'did'?: string;
}
/**
 * 
 * @export
 * @interface OrderRedirect
 */
export interface OrderRedirect {
    /**
     * Defines a url from which the holder originates from..
     * @type {string}
     * @memberof OrderRedirect
     */
    'origin'?: string;
    /**
     * Defines a url where the holder is redirected to when the verification and the order succeeds.
     * @type {string}
     * @memberof OrderRedirect
     */
    'success': string;
    /**
     * Defines a url where the holder is redirected to when the verification and the order fails.
     * @type {string}
     * @memberof OrderRedirect
     */
    'failure': string;
    /**
     * 
     * @type {Array<OrderRedirectQueryParam>}
     * @memberof OrderRedirect
     */
    'additionalQueryParams'?: Array<OrderRedirectQueryParam>;
}
/**
 * 
 * @export
 * @interface OrderRedirectQueryParam
 */
export interface OrderRedirectQueryParam {
    /**
     * 
     * @type {string}
     * @memberof OrderRedirectQueryParam
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OrderRedirectQueryParam
     */
    'field': string;
    /**
     * 
     * @type {boolean}
     * @memberof OrderRedirectQueryParam
     */
    'required': boolean;
}
/**
 * 
 * @export
 * @interface OrderResponseDto
 */
export interface OrderResponseDto {
    /**
     * 
     * @type {Array<Order>}
     * @memberof OrderResponseDto
     */
    'order': Array<Order>;
    /**
     * 
     * @type {number}
     * @memberof OrderResponseDto
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface OrderState
 */
export interface OrderState {
    /**
     * A unique id
     * @type {string}
     * @memberof OrderState
     */
    'id'?: string;
    /**
     * Step code, see external docs.
     * @type {string}
     * @memberof OrderState
     */
    'code': OrderStateCodeEnum;
    /**
     * A short description.
     * @type {string}
     * @memberof OrderState
     */
    'message': string;
    /**
     * Defines wether or not state locks order from identification.
     * @type {boolean}
     * @memberof OrderState
     */
    'locked': boolean;
    /**
     * The content of the step, see external docs.
     * @type {object}
     * @memberof OrderState
     */
    'payload'?: object;
    /**
     * 
     * @type {string}
     * @memberof OrderState
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderState
     */
    'did'?: string;
    /**
     * The date when the order state was created
     * @type {string}
     * @memberof OrderState
     */
    'createdAt'?: string;
    /**
     * The date when the order state was last updated
     * @type {string}
     * @memberof OrderState
     */
    'updatedAt'?: string;
}

export const OrderStateCodeEnum = {
    Created: 'CREATED',
    Register: 'REGISTER',
    Login: 'LOGIN',
    PersonRequestCreated: 'PERSON_REQUEST_CREATED',
    PersonVerified: 'PERSON_VERIFIED',
    AuthorizedRepresentative: 'AUTHORIZED_REPRESENTATIVE',
    CredentialsSent: 'CREDENTIALS_SENT',
    CompanyAgent: 'COMPANY_AGENT',
    SelectedCompany: 'SELECTED_COMPANY',
    TimedOut: 'TIMED_OUT',
    CredentialsSendingError: 'CREDENTIALS_SENDING_ERROR',
    ProcessCanceled: 'PROCESS_CANCELED',
    RequestsRejected: 'REQUESTS_REJECTED',
    AuthorizedRepresentativeManuallyIssued: 'AUTHORIZED_REPRESENTATIVE_MANUALLY_ISSUED',
    RequestReopened: 'REQUEST_REOPENED',
    AuthorizedRepresentativeManuallyRejected: 'AUTHORIZED_REPRESENTATIVE_MANUALLY_REJECTED',
    DuplicateOrder: 'DUPLICATE_ORDER',
    PendingIdentdataCorrection: 'PENDING_IDENTDATA_CORRECTION',
    AuthorizedByCompanyRepresentative: 'AUTHORIZED_BY_COMPANY_REPRESENTATIVE',
    RejectedByCompanyRepresentative: 'REJECTED_BY_COMPANY_REPRESENTATIVE',
    RequestedVerificatonByAuthrep: 'REQUESTED_VERIFICATON_BY_AUTHREP',
    SelectedPerson: 'SELECTED_PERSON',
    SelectedIdentProvider: 'SELECTED_IDENT_PROVIDER',
    CreatedCompanyCredentials: 'CREATED_COMPANY_CREDENTIALS',
    WebhookCalled: 'WEBHOOK_CALLED',
    IdentificationStarted: 'IDENTIFICATION_STARTED',
    IdentificationFinished: 'IDENTIFICATION_FINISHED',
    CreatedPersonCredentials: 'CREATED_PERSON_CREDENTIALS',
    UserRevokedPersonCredentials: 'USER_REVOKED_PERSON_CREDENTIALS',
    RequestPinOrderLetter: 'REQUEST_PIN_ORDER_LETTER',
    PinOrderLetterSent: 'PIN_ORDER_LETTER_SENT',
    Completed: 'COMPLETED',
    PinOrderValidated: 'PIN_ORDER_VALIDATED',
    AuthorizedRepresentativeRejected: 'AUTHORIZED_REPRESENTATIVE_REJECTED',
    CompanyAgentRejected: 'COMPANY_AGENT_REJECTED',
    EmailSent: 'EMAIL_SENT',
    UseAuthorization: 'USE_AUTHORIZATION',
    RecheckCredentialProcess: 'RECHECK_CREDENTIAL_PROCESS',
    RequestedVerificationGrantedAfterRecheck: 'REQUESTED_VERIFICATION_GRANTED_AFTER_RECHECK',
    EmailSendAfterRecheckAndCredentialVerification: 'EMAIL_SEND_AFTER_RECHECK_AND_CREDENTIAL_VERIFICATION'
} as const;

export type OrderStateCodeEnum = typeof OrderStateCodeEnum[keyof typeof OrderStateCodeEnum];

/**
 * 
 * @export
 * @interface OrgCredentialsMeta
 */
export interface OrgCredentialsMeta {
    /**
     * 
     * @type {OrgCredentialsMetaLegalName}
     * @memberof OrgCredentialsMeta
     */
    'legalName'?: OrgCredentialsMetaLegalName;
    /**
     * 
     * @type {OrgCredentialsMetaLegalForm}
     * @memberof OrgCredentialsMeta
     */
    'legalForm'?: OrgCredentialsMetaLegalForm;
    /**
     * 
     * @type {PersonCredentialsMetaAddress}
     * @memberof OrgCredentialsMeta
     */
    'address'?: PersonCredentialsMetaAddress;
    /**
     * 
     * @type {PersonCredentialsMetaCrefoId}
     * @memberof OrgCredentialsMeta
     */
    'crefoId'?: PersonCredentialsMetaCrefoId;
}
/**
 * Meta information about the legal form related data.
 * @export
 * @interface OrgCredentialsMetaLegalForm
 */
export interface OrgCredentialsMetaLegalForm {
    /**
     * Timestamp (unix epoch) when this information was created.
     * @type {number}
     * @memberof OrgCredentialsMetaLegalForm
     */
    'issuedAt': number;
    /**
     * The user (DID) to that this information regards to.
     * @type {string}
     * @memberof OrgCredentialsMetaLegalForm
     */
    'id': string;
    /**
     * This endpoint (url) provided by CrefoTrust can be used to check the current staus of the provided information.
     * @type {string}
     * @memberof OrgCredentialsMetaLegalForm
     */
    'status': string;
    /**
     * Issuer (DID) of the credential data.
     * @type {string}
     * @memberof OrgCredentialsMetaLegalForm
     */
    'issuer': string;
}
/**
 * Meta information about the legal name related data.
 * @export
 * @interface OrgCredentialsMetaLegalName
 */
export interface OrgCredentialsMetaLegalName {
    /**
     * Timestamp (unix epoch) when this information was created.
     * @type {number}
     * @memberof OrgCredentialsMetaLegalName
     */
    'issuedAt': number;
    /**
     * The user (DID) to that this information regards to.
     * @type {string}
     * @memberof OrgCredentialsMetaLegalName
     */
    'id': string;
    /**
     * This endpoint (url) provided by CrefoTrust can be used to check the current staus of the provided information.
     * @type {string}
     * @memberof OrgCredentialsMetaLegalName
     */
    'status': string;
    /**
     * Issuer (DID) of the credential data.
     * @type {string}
     * @memberof OrgCredentialsMetaLegalName
     */
    'issuer': string;
}
/**
 * 
 * @export
 * @interface OrgMeta
 */
export interface OrgMeta {
    /**
     * The did of the company.
     * @type {string}
     * @memberof OrgMeta
     */
    'id'?: string;
    /**
     * 
     * @type {OrgMetaCredentials}
     * @memberof OrgMeta
     */
    'credentials'?: OrgMetaCredentials;
}
/**
 * Meta information about credentials.
 * @export
 * @interface OrgMetaCredentials
 */
export interface OrgMetaCredentials {
    /**
     * 
     * @type {OrgCredentialsMetaLegalName}
     * @memberof OrgMetaCredentials
     */
    'legalName'?: OrgCredentialsMetaLegalName;
    /**
     * 
     * @type {OrgCredentialsMetaLegalForm}
     * @memberof OrgMetaCredentials
     */
    'legalForm'?: OrgCredentialsMetaLegalForm;
    /**
     * 
     * @type {PersonCredentialsMetaAddress}
     * @memberof OrgMetaCredentials
     */
    'address'?: PersonCredentialsMetaAddress;
    /**
     * 
     * @type {PersonCredentialsMetaCrefoId}
     * @memberof OrgMetaCredentials
     */
    'crefoId'?: PersonCredentialsMetaCrefoId;
}
/**
 * 
 * @export
 * @interface PartialTypeClass
 */
export interface PartialTypeClass {
    /**
     * 
     * @type {string}
     * @memberof PartialTypeClass
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialTypeClass
     */
    'type'?: PartialTypeClassTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PartialTypeClass
     */
    'did'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialTypeClass
     */
    'dataSource'?: PartialTypeClassDataSourceEnum;
    /**
     * 
     * @type {object}
     * @memberof PartialTypeClass
     */
    'payload'?: object;
    /**
     * 
     * @type {string}
     * @memberof PartialTypeClass
     */
    'jwt'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialTypeClass
     */
    'status'?: PartialTypeClassStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PartialTypeClass
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialTypeClass
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {object}
     * @memberof PartialTypeClass
     */
    'dataEnabler'?: object;
    /**
     * 
     * @type {string}
     * @memberof PartialTypeClass
     */
    'reason'?: string;
}

export const PartialTypeClassTypeEnum = {
    Crid: 'crid',
    Name: 'name',
    Address: 'address',
    Nationality: 'nationality',
    Birthdate: 'birthdate',
    CrefoId: 'crefoId',
    LegalName: 'legalName',
    LegalForm: 'legalForm',
    AuthorizedRepresentative: 'authorizedRepresentative',
    CompanyAgent: 'companyAgent',
    Authorization: 'authorization',
    CompanyAdmin: 'companyAdmin'
} as const;

export type PartialTypeClassTypeEnum = typeof PartialTypeClassTypeEnum[keyof typeof PartialTypeClassTypeEnum];
export const PartialTypeClassDataSourceEnum = {
    CrefoFirmenwissen: 'crefo_firmenwissen',
    Admin: 'admin',
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident',
    PinletterIdent: 'pinletter_ident',
    Did: 'did',
    AuthrepDelegation: 'authrep_delegation',
    PinDelegation: 'pin_delegation',
    PinDelegationPersonal: 'pin_delegation_personal',
    AuthrepOrCompanyAdminDelegation: 'authrep_or_company_admin_delegation'
} as const;

export type PartialTypeClassDataSourceEnum = typeof PartialTypeClassDataSourceEnum[keyof typeof PartialTypeClassDataSourceEnum];
export const PartialTypeClassStatusEnum = {
    Valid: 'VALID',
    Revoked: 'REVOKED',
    Expired: 'EXPIRED',
    NotFound: 'NOT_FOUND'
} as const;

export type PartialTypeClassStatusEnum = typeof PartialTypeClassStatusEnum[keyof typeof PartialTypeClassStatusEnum];

/**
 * 
 * @export
 * @interface Person
 */
export interface Person {
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    'givenName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    'familyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    'birthDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    'postalCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    'email'?: string;
    /**
     * 
     * @type {CrefoAddress}
     * @memberof Person
     */
    'address'?: CrefoAddress;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    'crefoId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    'department'?: string;
}
/**
 * 
 * @export
 * @interface PersonAndCompanyCredentials
 */
export interface PersonAndCompanyCredentials {
    /**
     * 
     * @type {Array<TransformedCredential>}
     * @memberof PersonAndCompanyCredentials
     */
    'companyCredentials': Array<TransformedCredential>;
    /**
     * 
     * @type {Array<TransformedCredential>}
     * @memberof PersonAndCompanyCredentials
     */
    'personCredentials': Array<TransformedCredential>;
    /**
     * 
     * @type {Array<TransformedCredential>}
     * @memberof PersonAndCompanyCredentials
     */
    'companyRepresentationCredentials': Array<TransformedCredential>;
}
/**
 * 
 * @export
 * @interface PersonCompanyCredentialsDto
 */
export interface PersonCompanyCredentialsDto {
    /**
     * 
     * @type {string}
     * @memberof PersonCompanyCredentialsDto
     */
    'legalName': string;
    /**
     * 
     * @type {string}
     * @memberof PersonCompanyCredentialsDto
     */
    'crefoId': string;
    /**
     * 
     * @type {string}
     * @memberof PersonCompanyCredentialsDto
     */
    'legalForm'?: string;
    /**
     * 
     * @type {object}
     * @memberof PersonCompanyCredentialsDto
     */
    'credentialType'?: object;
    /**
     * 
     * @type {string}
     * @memberof PersonCompanyCredentialsDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {CompanyAddressDto}
     * @memberof PersonCompanyCredentialsDto
     */
    'address'?: CompanyAddressDto;
    /**
     * 
     * @type {string}
     * @memberof PersonCompanyCredentialsDto
     */
    'companyDid': string;
}
/**
 * 
 * @export
 * @interface PersonCredentialsMeta
 */
export interface PersonCredentialsMeta {
    /**
     * 
     * @type {PersonCredentialsMetaCrid}
     * @memberof PersonCredentialsMeta
     */
    'crid'?: PersonCredentialsMetaCrid;
    /**
     * 
     * @type {PersonCredentialsMetaCrefoId}
     * @memberof PersonCredentialsMeta
     */
    'crefoId'?: PersonCredentialsMetaCrefoId;
    /**
     * 
     * @type {PersonCredentialsMetaAddress}
     * @memberof PersonCredentialsMeta
     */
    'address'?: PersonCredentialsMetaAddress;
    /**
     * 
     * @type {PersonCredentialsMetaName}
     * @memberof PersonCredentialsMeta
     */
    'name'?: PersonCredentialsMetaName;
    /**
     * 
     * @type {PersonCredentialsMetaBirthdate}
     * @memberof PersonCredentialsMeta
     */
    'birthdate'?: PersonCredentialsMetaBirthdate;
    /**
     * 
     * @type {PersonCredentialsMetaNationality}
     * @memberof PersonCredentialsMeta
     */
    'nationality'?: PersonCredentialsMetaNationality;
}
/**
 * Meta information about the address related data.
 * @export
 * @interface PersonCredentialsMetaAddress
 */
export interface PersonCredentialsMetaAddress {
    /**
     * Timestamp (unix epoch) when this information was created.
     * @type {number}
     * @memberof PersonCredentialsMetaAddress
     */
    'issuedAt': number;
    /**
     * The user (DID) to that this information regards to.
     * @type {string}
     * @memberof PersonCredentialsMetaAddress
     */
    'id': string;
    /**
     * This endpoint (url) provided by CrefoTrust can be used to check the current staus of the provided information.
     * @type {string}
     * @memberof PersonCredentialsMetaAddress
     */
    'status': string;
    /**
     * Issuer (DID) of the credential data.
     * @type {string}
     * @memberof PersonCredentialsMetaAddress
     */
    'issuer': string;
}
/**
 * Meta information about the birthdate related data.
 * @export
 * @interface PersonCredentialsMetaBirthdate
 */
export interface PersonCredentialsMetaBirthdate {
    /**
     * Timestamp (unix epoch) when this information was created.
     * @type {number}
     * @memberof PersonCredentialsMetaBirthdate
     */
    'issuedAt': number;
    /**
     * The user (DID) to that this information regards to.
     * @type {string}
     * @memberof PersonCredentialsMetaBirthdate
     */
    'id': string;
    /**
     * This endpoint (url) provided by CrefoTrust can be used to check the current staus of the provided information.
     * @type {string}
     * @memberof PersonCredentialsMetaBirthdate
     */
    'status': string;
    /**
     * Issuer (DID) of the credential data.
     * @type {string}
     * @memberof PersonCredentialsMetaBirthdate
     */
    'issuer': string;
}
/**
 * Meta information about the crefoId related data.
 * @export
 * @interface PersonCredentialsMetaCrefoId
 */
export interface PersonCredentialsMetaCrefoId {
    /**
     * Timestamp (unix epoch) when this information was created.
     * @type {number}
     * @memberof PersonCredentialsMetaCrefoId
     */
    'issuedAt': number;
    /**
     * The user (DID) to that this information regards to.
     * @type {string}
     * @memberof PersonCredentialsMetaCrefoId
     */
    'id': string;
    /**
     * This endpoint (url) provided by CrefoTrust can be used to check the current staus of the provided information.
     * @type {string}
     * @memberof PersonCredentialsMetaCrefoId
     */
    'status': string;
    /**
     * Issuer (DID) of the credential data.
     * @type {string}
     * @memberof PersonCredentialsMetaCrefoId
     */
    'issuer': string;
}
/**
 * Meta information about the crid related data.
 * @export
 * @interface PersonCredentialsMetaCrid
 */
export interface PersonCredentialsMetaCrid {
    /**
     * Timestamp (unix epoch) when this information was created.
     * @type {number}
     * @memberof PersonCredentialsMetaCrid
     */
    'issuedAt': number;
    /**
     * The user (DID) to that this information regards to.
     * @type {string}
     * @memberof PersonCredentialsMetaCrid
     */
    'id': string;
    /**
     * This endpoint (url) provided by CrefoTrust can be used to check the current staus of the provided information.
     * @type {string}
     * @memberof PersonCredentialsMetaCrid
     */
    'status': string;
    /**
     * Issuer (DID) of the credential data.
     * @type {string}
     * @memberof PersonCredentialsMetaCrid
     */
    'issuer': string;
}
/**
 * Meta information about the name related data.
 * @export
 * @interface PersonCredentialsMetaName
 */
export interface PersonCredentialsMetaName {
    /**
     * Timestamp (unix epoch) when this information was created.
     * @type {number}
     * @memberof PersonCredentialsMetaName
     */
    'issuedAt': number;
    /**
     * The user (DID) to that this information regards to.
     * @type {string}
     * @memberof PersonCredentialsMetaName
     */
    'id': string;
    /**
     * This endpoint (url) provided by CrefoTrust can be used to check the current staus of the provided information.
     * @type {string}
     * @memberof PersonCredentialsMetaName
     */
    'status': string;
    /**
     * Issuer (DID) of the credential data.
     * @type {string}
     * @memberof PersonCredentialsMetaName
     */
    'issuer': string;
}
/**
 * Meta information about the nationality related data.
 * @export
 * @interface PersonCredentialsMetaNationality
 */
export interface PersonCredentialsMetaNationality {
    /**
     * Timestamp (unix epoch) when this information was created.
     * @type {number}
     * @memberof PersonCredentialsMetaNationality
     */
    'issuedAt': number;
    /**
     * The user (DID) to that this information regards to.
     * @type {string}
     * @memberof PersonCredentialsMetaNationality
     */
    'id': string;
    /**
     * This endpoint (url) provided by CrefoTrust can be used to check the current staus of the provided information.
     * @type {string}
     * @memberof PersonCredentialsMetaNationality
     */
    'status': string;
    /**
     * Issuer (DID) of the credential data.
     * @type {string}
     * @memberof PersonCredentialsMetaNationality
     */
    'issuer': string;
}
/**
 * 
 * @export
 * @interface PersonDto
 */
export interface PersonDto {
    /**
     * 
     * @type {string}
     * @memberof PersonDto
     */
    'givenName': string;
    /**
     * 
     * @type {string}
     * @memberof PersonDto
     */
    'familyName': string;
    /**
     * 
     * @type {string}
     * @memberof PersonDto
     */
    'birthDate': string;
    /**
     * 
     * @type {string}
     * @memberof PersonDto
     */
    'crefoId': string;
    /**
     * 
     * @type {AddressDto}
     * @memberof PersonDto
     */
    'address': AddressDto;
    /**
     * 
     * @type {string}
     * @memberof PersonDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof PersonDto
     */
    'department'?: string;
}
/**
 * 
 * @export
 * @interface PersonMatch
 */
export interface PersonMatch {
    /**
     * 
     * @type {Person}
     * @memberof PersonMatch
     */
    'ssu': Person;
    /**
     * 
     * @type {Person}
     * @memberof PersonMatch
     */
    'ident': Person;
}
/**
 * 
 * @export
 * @interface PersonMatchError
 */
export interface PersonMatchError {
    /**
     * 
     * @type {string}
     * @memberof PersonMatchError
     */
    'did': string;
    /**
     * 
     * @type {string}
     * @memberof PersonMatchError
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof PersonMatchError
     */
    'crefoId': string;
    /**
     * 
     * @type {Array<ComparisonResultObject>}
     * @memberof PersonMatchError
     */
    'stacktrace': Array<ComparisonResultObject>;
    /**
     * 
     * @type {PersonMatch}
     * @memberof PersonMatchError
     */
    'personMatch': PersonMatch;
}
/**
 * 
 * @export
 * @interface PersonMeta
 */
export interface PersonMeta {
    /**
     * The did of the person.
     * @type {string}
     * @memberof PersonMeta
     */
    'id'?: string;
    /**
     * 
     * @type {PersonMetaCredentials}
     * @memberof PersonMeta
     */
    'credentials'?: PersonMetaCredentials;
}
/**
 * Meta information about credentials.
 * @export
 * @interface PersonMetaCredentials
 */
export interface PersonMetaCredentials {
    /**
     * 
     * @type {PersonCredentialsMetaCrid}
     * @memberof PersonMetaCredentials
     */
    'crid'?: PersonCredentialsMetaCrid;
    /**
     * 
     * @type {PersonCredentialsMetaCrefoId}
     * @memberof PersonMetaCredentials
     */
    'crefoId'?: PersonCredentialsMetaCrefoId;
    /**
     * 
     * @type {PersonCredentialsMetaAddress}
     * @memberof PersonMetaCredentials
     */
    'address'?: PersonCredentialsMetaAddress;
    /**
     * 
     * @type {PersonCredentialsMetaName}
     * @memberof PersonMetaCredentials
     */
    'name'?: PersonCredentialsMetaName;
    /**
     * 
     * @type {PersonCredentialsMetaBirthdate}
     * @memberof PersonMetaCredentials
     */
    'birthdate'?: PersonCredentialsMetaBirthdate;
    /**
     * 
     * @type {PersonCredentialsMetaNationality}
     * @memberof PersonMetaCredentials
     */
    'nationality'?: PersonCredentialsMetaNationality;
}
/**
 * 
 * @export
 * @interface PersonRequest
 */
export interface PersonRequest {
    /**
     * 
     * @type {string}
     * @memberof PersonRequest
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PersonRequest
     */
    'type': PersonRequestTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PersonRequest
     */
    'did': string;
    /**
     * 
     * @type {string}
     * @memberof PersonRequest
     */
    'orderId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonRequest
     */
    'dataSource': PersonRequestDataSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof PersonRequest
     */
    'state': PersonRequestStateEnum;
    /**
     * 
     * @type {Array<RequestActionClass>}
     * @memberof PersonRequest
     */
    'actions': Array<RequestActionClass>;
    /**
     * 
     * @type {string}
     * @memberof PersonRequest
     */
    'stateReason'?: PersonRequestStateReasonEnum;
    /**
     * 
     * @type {string}
     * @memberof PersonRequest
     */
    'comment'?: string;
    /**
     * 
     * @type {object}
     * @memberof PersonRequest
     */
    'createdAt': object;
    /**
     * 
     * @type {object}
     * @memberof PersonRequest
     */
    'updatedAt': object;
    /**
     * 
     * @type {string}
     * @memberof PersonRequest
     */
    'idMethod': PersonRequestIdMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof PersonRequest
     */
    'requestor': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PersonRequest
     */
    'customerRequiredData': Array<PersonRequestCustomerRequiredDataEnum>;
    /**
     * 
     * @type {PersonRequestPayload}
     * @memberof PersonRequest
     */
    'payload': PersonRequestPayload;
    /**
     * 
     * @type {object}
     * @memberof PersonRequest
     */
    'customerProvidedData'?: object;
}

export const PersonRequestTypeEnum = {
    Org: 'org',
    Person: 'person'
} as const;

export type PersonRequestTypeEnum = typeof PersonRequestTypeEnum[keyof typeof PersonRequestTypeEnum];
export const PersonRequestDataSourceEnum = {
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident'
} as const;

export type PersonRequestDataSourceEnum = typeof PersonRequestDataSourceEnum[keyof typeof PersonRequestDataSourceEnum];
export const PersonRequestStateEnum = {
    Open: 'OPEN',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED',
    Inprogress: 'INPROGRESS',
    CustomerPostprocessing: 'CUSTOMER_POSTPROCESSING',
    Closed: 'CLOSED'
} as const;

export type PersonRequestStateEnum = typeof PersonRequestStateEnum[keyof typeof PersonRequestStateEnum];
export const PersonRequestStateReasonEnum = {
    Success: 'SUCCESS',
    CustomerInput: 'CUSTOMER_INPUT',
    UnknownProblem: 'UNKNOWN_PROBLEM',
    UnexpectedCancellation: 'UNEXPECTED_CANCELLATION',
    NameMismatch: 'NAME_MISMATCH',
    TechnicalProblem: 'TECHNICAL_PROBLEM',
    DocumentExpired: 'DOCUMENT_EXPIRED',
    MissingAuthorizedRepresentative: 'MISSING_AUTHORIZED_REPRESENTATIVE',
    CredentialsInsufficient: 'CREDENTIALS_INSUFFICIENT',
    OrderPresetsMismatch: 'ORDER_PRESETS_MISMATCH',
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident',
    PinletterIdent: 'pinletter_ident',
    Timeout: 'TIMEOUT',
    OtherPersonRequestAccepted: 'Other person request accepted.',
    UserRevokedCredentials: 'User revoked credentials',
    Webhook: 'WEBHOOK'
} as const;

export type PersonRequestStateReasonEnum = typeof PersonRequestStateReasonEnum[keyof typeof PersonRequestStateReasonEnum];
export const PersonRequestIdMethodEnum = {
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident'
} as const;

export type PersonRequestIdMethodEnum = typeof PersonRequestIdMethodEnum[keyof typeof PersonRequestIdMethodEnum];
export const PersonRequestCustomerRequiredDataEnum = {
    Crid: 'crid',
    Name: 'name',
    Address: 'address',
    Nationality: 'nationality',
    Birthdate: 'birthdate',
    CrefoId: 'crefoId',
    LegalName: 'legalName',
    LegalForm: 'legalForm',
    AuthorizedRepresentative: 'authorizedRepresentative',
    CompanyAgent: 'companyAgent',
    Authorization: 'authorization',
    CompanyAdmin: 'companyAdmin'
} as const;

export type PersonRequestCustomerRequiredDataEnum = typeof PersonRequestCustomerRequiredDataEnum[keyof typeof PersonRequestCustomerRequiredDataEnum];

/**
 * 
 * @export
 * @interface PersonRequestPayload
 */
export interface PersonRequestPayload {
    /**
     * 
     * @type {string}
     * @memberof PersonRequestPayload
     */
    'givenName': string;
    /**
     * 
     * @type {string}
     * @memberof PersonRequestPayload
     */
    'familyName': string;
    /**
     * 
     * @type {string}
     * @memberof PersonRequestPayload
     */
    'birthDate': string;
    /**
     * 
     * @type {string}
     * @memberof PersonRequestPayload
     */
    'crefoId': string;
    /**
     * 
     * @type {string}
     * @memberof PersonRequestPayload
     */
    'email': string;
    /**
     * 
     * @type {RequestAddress}
     * @memberof PersonRequestPayload
     */
    'address': RequestAddress;
}
/**
 * 
 * @export
 * @interface PostRegistration
 */
export interface PostRegistration {
    /**
     * 
     * @type {string}
     * @memberof PostRegistration
     */
    'prt': string;
    /**
     * 
     * @type {string}
     * @memberof PostRegistration
     */
    'redirectUri': string;
}
/**
 * 
 * @export
 * @interface RejectOrderDto
 */
export interface RejectOrderDto {
    /**
     * 
     * @type {string}
     * @memberof RejectOrderDto
     */
    'comment'?: string;
}
/**
 * 
 * @export
 * @interface RepresentationCredentialMeta
 */
export interface RepresentationCredentialMeta {
    /**
     * Timestamp (unix epoch) when this information was created.
     * @type {number}
     * @memberof RepresentationCredentialMeta
     */
    'issuedAt': number;
    /**
     * The user (DID) to that this information regards to.
     * @type {string}
     * @memberof RepresentationCredentialMeta
     */
    'person': string;
    /**
     * The company (DID) to that this information regards to.
     * @type {string}
     * @memberof RepresentationCredentialMeta
     */
    'org': string;
    /**
     * This endpoint (url) provided by CrefoTrust can be used to check the current staus of the provided information.
     * @type {string}
     * @memberof RepresentationCredentialMeta
     */
    'status': string;
    /**
     * Issuer (DID) of the credential data.
     * @type {string}
     * @memberof RepresentationCredentialMeta
     */
    'issuer': string;
}
/**
 * 
 * @export
 * @interface RequestActionClass
 */
export interface RequestActionClass {
    /**
     * 
     * @type {string}
     * @memberof RequestActionClass
     */
    'actionType': string;
    /**
     * 
     * @type {CrefoIdentOrderLink}
     * @memberof RequestActionClass
     */
    'value': CrefoIdentOrderLink;
}
/**
 * 
 * @export
 * @interface RequestAddress
 */
export interface RequestAddress {
    /**
     * 
     * @type {string}
     * @memberof RequestAddress
     */
    'streetAddress': string;
    /**
     * 
     * @type {string}
     * @memberof RequestAddress
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof RequestAddress
     */
    'addressLocality': string;
    /**
     * 
     * @type {string}
     * @memberof RequestAddress
     */
    'addressCountry': string;
}
/**
 * 
 * @export
 * @interface Requestor
 */
export interface Requestor {
    /**
     * 
     * @type {string}
     * @memberof Requestor
     */
    'givenName': string;
    /**
     * 
     * @type {string}
     * @memberof Requestor
     */
    'familyName': string;
    /**
     * 
     * @type {string}
     * @memberof Requestor
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface RequestsControllerUpdateRequest200Response
 */
export interface RequestsControllerUpdateRequest200Response {
    /**
     * 
     * @type {string}
     * @memberof RequestsControllerUpdateRequest200Response
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RequestsControllerUpdateRequest200Response
     */
    'type': RequestsControllerUpdateRequest200ResponseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof RequestsControllerUpdateRequest200Response
     */
    'did': string;
    /**
     * 
     * @type {string}
     * @memberof RequestsControllerUpdateRequest200Response
     */
    'orderId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestsControllerUpdateRequest200Response
     */
    'dataSource': RequestsControllerUpdateRequest200ResponseDataSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof RequestsControllerUpdateRequest200Response
     */
    'state': RequestsControllerUpdateRequest200ResponseStateEnum;
    /**
     * 
     * @type {Array<RequestActionClass>}
     * @memberof RequestsControllerUpdateRequest200Response
     */
    'actions': Array<RequestActionClass>;
    /**
     * 
     * @type {string}
     * @memberof RequestsControllerUpdateRequest200Response
     */
    'stateReason'?: RequestsControllerUpdateRequest200ResponseStateReasonEnum;
    /**
     * 
     * @type {string}
     * @memberof RequestsControllerUpdateRequest200Response
     */
    'comment'?: string;
    /**
     * 
     * @type {object}
     * @memberof RequestsControllerUpdateRequest200Response
     */
    'createdAt': object;
    /**
     * 
     * @type {object}
     * @memberof RequestsControllerUpdateRequest200Response
     */
    'updatedAt': object;
    /**
     * 
     * @type {string}
     * @memberof RequestsControllerUpdateRequest200Response
     */
    'idMethod': RequestsControllerUpdateRequest200ResponseIdMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof RequestsControllerUpdateRequest200Response
     */
    'requestor': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RequestsControllerUpdateRequest200Response
     */
    'customerRequiredData': Array<RequestsControllerUpdateRequest200ResponseCustomerRequiredDataEnum>;
    /**
     * 
     * @type {PersonRequestPayload}
     * @memberof RequestsControllerUpdateRequest200Response
     */
    'payload': PersonRequestPayload;
    /**
     * 
     * @type {object}
     * @memberof RequestsControllerUpdateRequest200Response
     */
    'customerProvidedData'?: object;
}

export const RequestsControllerUpdateRequest200ResponseTypeEnum = {
    Org: 'org',
    Person: 'person'
} as const;

export type RequestsControllerUpdateRequest200ResponseTypeEnum = typeof RequestsControllerUpdateRequest200ResponseTypeEnum[keyof typeof RequestsControllerUpdateRequest200ResponseTypeEnum];
export const RequestsControllerUpdateRequest200ResponseDataSourceEnum = {
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident'
} as const;

export type RequestsControllerUpdateRequest200ResponseDataSourceEnum = typeof RequestsControllerUpdateRequest200ResponseDataSourceEnum[keyof typeof RequestsControllerUpdateRequest200ResponseDataSourceEnum];
export const RequestsControllerUpdateRequest200ResponseStateEnum = {
    Open: 'OPEN',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED',
    Inprogress: 'INPROGRESS',
    CustomerPostprocessing: 'CUSTOMER_POSTPROCESSING',
    Closed: 'CLOSED'
} as const;

export type RequestsControllerUpdateRequest200ResponseStateEnum = typeof RequestsControllerUpdateRequest200ResponseStateEnum[keyof typeof RequestsControllerUpdateRequest200ResponseStateEnum];
export const RequestsControllerUpdateRequest200ResponseStateReasonEnum = {
    Success: 'SUCCESS',
    CustomerInput: 'CUSTOMER_INPUT',
    UnknownProblem: 'UNKNOWN_PROBLEM',
    UnexpectedCancellation: 'UNEXPECTED_CANCELLATION',
    NameMismatch: 'NAME_MISMATCH',
    TechnicalProblem: 'TECHNICAL_PROBLEM',
    DocumentExpired: 'DOCUMENT_EXPIRED',
    MissingAuthorizedRepresentative: 'MISSING_AUTHORIZED_REPRESENTATIVE',
    CredentialsInsufficient: 'CREDENTIALS_INSUFFICIENT',
    OrderPresetsMismatch: 'ORDER_PRESETS_MISMATCH',
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident',
    PinletterIdent: 'pinletter_ident',
    Timeout: 'TIMEOUT',
    OtherPersonRequestAccepted: 'Other person request accepted.',
    UserRevokedCredentials: 'User revoked credentials',
    Webhook: 'WEBHOOK'
} as const;

export type RequestsControllerUpdateRequest200ResponseStateReasonEnum = typeof RequestsControllerUpdateRequest200ResponseStateReasonEnum[keyof typeof RequestsControllerUpdateRequest200ResponseStateReasonEnum];
export const RequestsControllerUpdateRequest200ResponseIdMethodEnum = {
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident'
} as const;

export type RequestsControllerUpdateRequest200ResponseIdMethodEnum = typeof RequestsControllerUpdateRequest200ResponseIdMethodEnum[keyof typeof RequestsControllerUpdateRequest200ResponseIdMethodEnum];
export const RequestsControllerUpdateRequest200ResponseCustomerRequiredDataEnum = {
    Crid: 'crid',
    Name: 'name',
    Address: 'address',
    Nationality: 'nationality',
    Birthdate: 'birthdate',
    CrefoId: 'crefoId',
    LegalName: 'legalName',
    LegalForm: 'legalForm',
    AuthorizedRepresentative: 'authorizedRepresentative',
    CompanyAgent: 'companyAgent',
    Authorization: 'authorization',
    CompanyAdmin: 'companyAdmin'
} as const;

export type RequestsControllerUpdateRequest200ResponseCustomerRequiredDataEnum = typeof RequestsControllerUpdateRequest200ResponseCustomerRequiredDataEnum[keyof typeof RequestsControllerUpdateRequest200ResponseCustomerRequiredDataEnum];

/**
 * 
 * @export
 * @interface RequiredCredentials
 */
export interface RequiredCredentials {
    /**
     * Required person credentials to complete the order.
     * @type {Array<string>}
     * @memberof RequiredCredentials
     */
    'person': Array<RequiredCredentialsPersonEnum>;
    /**
     * Required company credentials to complete the order.
     * @type {Array<string>}
     * @memberof RequiredCredentials
     */
    'org': Array<RequiredCredentialsOrgEnum>;
    /**
     * Authorized representative credential to complete the order.
     * @type {boolean}
     * @memberof RequiredCredentials
     */
    'authorizedRepresentative'?: boolean;
    /**
     * Company agent credential to complete the order.
     * @type {boolean}
     * @memberof RequiredCredentials
     */
    'companyAgent'?: boolean;
}

export const RequiredCredentialsPersonEnum = {
    Crid: 'crid',
    CrefoId: 'crefoId',
    Name: 'name',
    Address: 'address',
    Nationality: 'nationality',
    Birthdate: 'birthdate'
} as const;

export type RequiredCredentialsPersonEnum = typeof RequiredCredentialsPersonEnum[keyof typeof RequiredCredentialsPersonEnum];
export const RequiredCredentialsOrgEnum = {
    LegalForm: 'legalForm',
    LegalName: 'legalName',
    CrefoId: 'crefoId',
    Address: 'address'
} as const;

export type RequiredCredentialsOrgEnum = typeof RequiredCredentialsOrgEnum[keyof typeof RequiredCredentialsOrgEnum];

/**
 * 
 * @export
 * @interface ResolvedRoute
 */
export interface ResolvedRoute {
    /**
     * 
     * @type {Array<string>}
     * @memberof ResolvedRoute
     */
    'enabledRoutes': Array<ResolvedRouteEnabledRoutesEnum>;
    /**
     * 
     * @type {string}
     * @memberof ResolvedRoute
     */
    'activeRoute': ResolvedRouteActiveRouteEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResolvedRoute
     */
    'code': Array<ResolvedRouteCodeEnum>;
}

export const ResolvedRouteEnabledRoutesEnum = {
    CompanySearch: 'COMPANY_SEARCH',
    SelectIdentprovider: 'SELECT_IDENTPROVIDER',
    Verification: 'VERIFICATION',
    ApproveCredentials: 'APPROVE_CREDENTIALS',
    CustomerInputRequired: 'CUSTOMER_INPUT_REQUIRED',
    CredentialsInsufficient: 'CREDENTIALS_INSUFFICIENT',
    MyEsgVerification: 'MY_ESG_VERIFICATION',
    PendingDataCorrection: 'PENDING_DATA_CORRECTION'
} as const;

export type ResolvedRouteEnabledRoutesEnum = typeof ResolvedRouteEnabledRoutesEnum[keyof typeof ResolvedRouteEnabledRoutesEnum];
export const ResolvedRouteActiveRouteEnum = {
    CompanySearch: 'COMPANY_SEARCH',
    SelectIdentprovider: 'SELECT_IDENTPROVIDER',
    Verification: 'VERIFICATION',
    ApproveCredentials: 'APPROVE_CREDENTIALS',
    CustomerInputRequired: 'CUSTOMER_INPUT_REQUIRED',
    CredentialsInsufficient: 'CREDENTIALS_INSUFFICIENT',
    MyEsgVerification: 'MY_ESG_VERIFICATION',
    PendingDataCorrection: 'PENDING_DATA_CORRECTION'
} as const;

export type ResolvedRouteActiveRouteEnum = typeof ResolvedRouteActiveRouteEnum[keyof typeof ResolvedRouteActiveRouteEnum];
export const ResolvedRouteCodeEnum = {
    Default: 'DEFAULT',
    OrderB2C01: 'ORDER_B2C_01',
    OrderB2C02: 'ORDER_B2C_02',
    OrderB2C0202: 'ORDER_B2C_02_02',
    OrderB2C03: 'ORDER_B2C_03',
    OrderB2C04: 'ORDER_B2C_04',
    OrderB2C05: 'ORDER_B2C_05'
} as const;

export type ResolvedRouteCodeEnum = typeof ResolvedRouteCodeEnum[keyof typeof ResolvedRouteCodeEnum];

/**
 * 
 * @export
 * @interface RevokeCredentialsDto
 */
export interface RevokeCredentialsDto {
    /**
     * 
     * @type {Array<PartialTypeClass>}
     * @memberof RevokeCredentialsDto
     */
    'credentialFilters': Array<PartialTypeClass>;
    /**
     * 
     * @type {string}
     * @memberof RevokeCredentialsDto
     */
    'reason': string;
}
/**
 * 
 * @export
 * @interface RoutingState
 */
export interface RoutingState {
    /**
     * 
     * @type {Array<string>}
     * @memberof RoutingState
     */
    'routes': Array<RoutingStateRoutesEnum>;
    /**
     * 
     * @type {ResolvedRoute}
     * @memberof RoutingState
     */
    'resolvedRoutes'?: ResolvedRoute;
}

export const RoutingStateRoutesEnum = {
    CompanySearch: 'COMPANY_SEARCH',
    SelectIdentprovider: 'SELECT_IDENTPROVIDER',
    Verification: 'VERIFICATION',
    ApproveCredentials: 'APPROVE_CREDENTIALS',
    CustomerInputRequired: 'CUSTOMER_INPUT_REQUIRED',
    CredentialsInsufficient: 'CREDENTIALS_INSUFFICIENT',
    MyEsgVerification: 'MY_ESG_VERIFICATION',
    PendingDataCorrection: 'PENDING_DATA_CORRECTION'
} as const;

export type RoutingStateRoutesEnum = typeof RoutingStateRoutesEnum[keyof typeof RoutingStateRoutesEnum];

/**
 * 
 * @export
 * @interface SelectedCompanyDto
 */
export interface SelectedCompanyDto {
    /**
     * 
     * @type {CompanyDto}
     * @memberof SelectedCompanyDto
     */
    'company': CompanyDto;
}
/**
 * 
 * @export
 * @interface SelectedIdentProviderDto
 */
export interface SelectedIdentProviderDto {
    /**
     * 
     * @type {string}
     * @memberof SelectedIdentProviderDto
     */
    'selectedIdentProvider': SelectedIdentProviderDtoSelectedIdentProviderEnum;
}

export const SelectedIdentProviderDtoSelectedIdentProviderEnum = {
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident',
    PinletterIdent: 'pinletter_ident',
    AuthrepDelegation: 'authrep_delegation'
} as const;

export type SelectedIdentProviderDtoSelectedIdentProviderEnum = typeof SelectedIdentProviderDtoSelectedIdentProviderEnum[keyof typeof SelectedIdentProviderDtoSelectedIdentProviderEnum];

/**
 * 
 * @export
 * @interface SelectedIssuerDto
 */
export interface SelectedIssuerDto {
    /**
     * 
     * @type {IssuerDto}
     * @memberof SelectedIssuerDto
     */
    'selectedIssuer': IssuerDto;
}
/**
 * 
 * @export
 * @interface SelectedPersonDto
 */
export interface SelectedPersonDto {
    /**
     * 
     * @type {PersonDto}
     * @memberof SelectedPersonDto
     */
    'selectedPerson': PersonDto;
}
/**
 * 
 * @export
 * @interface Task
 */
export interface Task {
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    'createdAt': string;
    /**
     * 
     * @type {Requestor}
     * @memberof Task
     */
    'requestor': Requestor;
}
/**
 * 
 * @export
 * @interface TransformedCredential
 */
export interface TransformedCredential {
    /**
     * 
     * @type {object}
     * @memberof TransformedCredential
     */
    'payload': object;
    /**
     * 
     * @type {string}
     * @memberof TransformedCredential
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof TransformedCredential
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof TransformedCredential
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface UpdateAdminUser
 */
export interface UpdateAdminUser {
    /**
     * The admin user\'s password, in encrypted form
     * @type {string}
     * @memberof UpdateAdminUser
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface UpdateAuthConfigDto
 */
export interface UpdateAuthConfigDto {
    /**
     * Decides which SSO to use
     * @type {string}
     * @memberof UpdateAuthConfigDto
     */
    'prk'?: string;
    /**
     * base url of auth provider: e.g. keycloak-inv
     * @type {string}
     * @memberof UpdateAuthConfigDto
     */
    'redirectUri'?: string;
    /**
     * base url of auth provider: e.g. keycloak-inv
     * @type {string}
     * @memberof UpdateAuthConfigDto
     */
    'loginUri'?: string;
    /**
     * base url of auth provider: e.g. keycloak-inv
     * @type {string}
     * @memberof UpdateAuthConfigDto
     */
    'registrationUri'?: string;
    /**
     * TODO: add detailed description what clientId is used for
     * @type {string}
     * @memberof UpdateAuthConfigDto
     */
    'protocol'?: string;
    /**
     * TODO: add detailed description what clientId is used for
     * @type {string}
     * @memberof UpdateAuthConfigDto
     */
    'clientId'?: string;
    /**
     * Contains the title displayed in the SSO view
     * @type {string}
     * @memberof UpdateAuthConfigDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAuthConfigDto
     */
    'realm'?: string;
}
/**
 * 
 * @export
 * @interface UpdateRequestDto
 */
export interface UpdateRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateRequestDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRequestDto
     */
    'type': UpdateRequestDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateRequestDto
     */
    'orderId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRequestDto
     */
    'dataSource'?: UpdateRequestDtoDataSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateRequestDto
     */
    'state'?: UpdateRequestDtoStateEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateRequestDto
     */
    'stateReason'?: UpdateRequestDtoStateReasonEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateRequestDto
     */
    'comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRequestDto
     */
    'idMethod'?: UpdateRequestDtoIdMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateRequestDto
     */
    'requestor'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateRequestDto
     */
    'customerRequiredData'?: Array<UpdateRequestDtoCustomerRequiredDataEnum>;
    /**
     * 
     * @type {PersonRequestPayload}
     * @memberof UpdateRequestDto
     */
    'payload'?: PersonRequestPayload;
    /**
     * 
     * @type {object}
     * @memberof UpdateRequestDto
     */
    'customerProvidedData'?: object;
}

export const UpdateRequestDtoTypeEnum = {
    Org: 'org',
    Person: 'person'
} as const;

export type UpdateRequestDtoTypeEnum = typeof UpdateRequestDtoTypeEnum[keyof typeof UpdateRequestDtoTypeEnum];
export const UpdateRequestDtoDataSourceEnum = {
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident'
} as const;

export type UpdateRequestDtoDataSourceEnum = typeof UpdateRequestDtoDataSourceEnum[keyof typeof UpdateRequestDtoDataSourceEnum];
export const UpdateRequestDtoStateEnum = {
    Open: 'OPEN',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED',
    Inprogress: 'INPROGRESS',
    CustomerPostprocessing: 'CUSTOMER_POSTPROCESSING',
    Closed: 'CLOSED'
} as const;

export type UpdateRequestDtoStateEnum = typeof UpdateRequestDtoStateEnum[keyof typeof UpdateRequestDtoStateEnum];
export const UpdateRequestDtoStateReasonEnum = {
    Success: 'SUCCESS',
    CustomerInput: 'CUSTOMER_INPUT',
    UnknownProblem: 'UNKNOWN_PROBLEM',
    UnexpectedCancellation: 'UNEXPECTED_CANCELLATION',
    NameMismatch: 'NAME_MISMATCH',
    TechnicalProblem: 'TECHNICAL_PROBLEM',
    DocumentExpired: 'DOCUMENT_EXPIRED',
    MissingAuthorizedRepresentative: 'MISSING_AUTHORIZED_REPRESENTATIVE',
    CredentialsInsufficient: 'CREDENTIALS_INSUFFICIENT',
    OrderPresetsMismatch: 'ORDER_PRESETS_MISMATCH',
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident',
    PinletterIdent: 'pinletter_ident',
    Timeout: 'TIMEOUT',
    OtherPersonRequestAccepted: 'Other person request accepted.',
    UserRevokedCredentials: 'User revoked credentials',
    Webhook: 'WEBHOOK'
} as const;

export type UpdateRequestDtoStateReasonEnum = typeof UpdateRequestDtoStateReasonEnum[keyof typeof UpdateRequestDtoStateReasonEnum];
export const UpdateRequestDtoIdMethodEnum = {
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident'
} as const;

export type UpdateRequestDtoIdMethodEnum = typeof UpdateRequestDtoIdMethodEnum[keyof typeof UpdateRequestDtoIdMethodEnum];
export const UpdateRequestDtoCustomerRequiredDataEnum = {
    Crid: 'crid',
    Name: 'name',
    Address: 'address',
    Nationality: 'nationality',
    Birthdate: 'birthdate',
    CrefoId: 'crefoId',
    LegalName: 'legalName',
    LegalForm: 'legalForm',
    AuthorizedRepresentative: 'authorizedRepresentative',
    CompanyAgent: 'companyAgent',
    Authorization: 'authorization',
    CompanyAdmin: 'companyAdmin'
} as const;

export type UpdateRequestDtoCustomerRequiredDataEnum = typeof UpdateRequestDtoCustomerRequiredDataEnum[keyof typeof UpdateRequestDtoCustomerRequiredDataEnum];

/**
 * 
 * @export
 * @interface UpdateUseCaseContentDto
 */
export interface UpdateUseCaseContentDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateUseCaseContentDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUseCaseContentDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUseCaseContentDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUseCaseContentDto
     */
    'logoUrl'?: string;
}
/**
 * 
 * @export
 * @interface UpdateUseCaseDto
 */
export interface UpdateUseCaseDto {
    /**
     * 
     * @type {RequiredCredentials}
     * @memberof UpdateUseCaseDto
     */
    'requiredCredentials'?: RequiredCredentials;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateUseCaseDto
     */
    'offeredIdentMethods'?: Array<UpdateUseCaseDtoOfferedIdentMethodsEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateUseCaseDto
     */
    'acceptedIdentMethods'?: Array<UpdateUseCaseDtoAcceptedIdentMethodsEnum>;
    /**
     * 
     * @type {string}
     * @memberof UpdateUseCaseDto
     */
    'webhook'?: string;
    /**
     * 
     * @type {OrderRedirect}
     * @memberof UpdateUseCaseDto
     */
    'redirect'?: OrderRedirect;
    /**
     * 
     * @type {UseCaseContent}
     * @memberof UpdateUseCaseDto
     */
    'content': UseCaseContent;
    /**
     * 
     * @type {string}
     * @memberof UpdateUseCaseDto
     */
    'serviceUrl'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateUseCaseDto
     */
    'relatedServices'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateUseCaseDto
     */
    'authConfigName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUseCaseDto
     */
    'lang'?: UpdateUseCaseDtoLangEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateUseCaseDto
     */
    'baseUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUseCaseDto
     */
    'entryPath'?: string;
}

export const UpdateUseCaseDtoOfferedIdentMethodsEnum = {
    CrefoFirmenwissen: 'crefo_firmenwissen',
    Admin: 'admin',
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident',
    PinletterIdent: 'pinletter_ident',
    Did: 'did',
    AuthrepDelegation: 'authrep_delegation',
    PinDelegation: 'pin_delegation',
    PinDelegationPersonal: 'pin_delegation_personal',
    AuthrepOrCompanyAdminDelegation: 'authrep_or_company_admin_delegation'
} as const;

export type UpdateUseCaseDtoOfferedIdentMethodsEnum = typeof UpdateUseCaseDtoOfferedIdentMethodsEnum[keyof typeof UpdateUseCaseDtoOfferedIdentMethodsEnum];
export const UpdateUseCaseDtoAcceptedIdentMethodsEnum = {
    CrefoFirmenwissen: 'crefo_firmenwissen',
    Admin: 'admin',
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident',
    PinletterIdent: 'pinletter_ident',
    Did: 'did',
    AuthrepDelegation: 'authrep_delegation',
    PinDelegation: 'pin_delegation',
    PinDelegationPersonal: 'pin_delegation_personal',
    AuthrepOrCompanyAdminDelegation: 'authrep_or_company_admin_delegation'
} as const;

export type UpdateUseCaseDtoAcceptedIdentMethodsEnum = typeof UpdateUseCaseDtoAcceptedIdentMethodsEnum[keyof typeof UpdateUseCaseDtoAcceptedIdentMethodsEnum];
export const UpdateUseCaseDtoLangEnum = {
    De: 'de',
    At: 'at',
    Lu: 'lu',
    Fr: 'fr',
    En: 'en'
} as const;

export type UpdateUseCaseDtoLangEnum = typeof UpdateUseCaseDtoLangEnum[keyof typeof UpdateUseCaseDtoLangEnum];

/**
 * 
 * @export
 * @interface UseCase
 */
export interface UseCase {
    /**
     * 
     * @type {string}
     * @memberof UseCase
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UseCase
     */
    'name': string;
    /**
     * 
     * @type {RequiredCredentials}
     * @memberof UseCase
     */
    'requiredCredentials': RequiredCredentials;
    /**
     * 
     * @type {Array<string>}
     * @memberof UseCase
     */
    'offeredIdMethods': Array<UseCaseOfferedIdMethodsEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UseCase
     */
    'acceptedIdMethods': Array<UseCaseAcceptedIdMethodsEnum>;
    /**
     * 
     * @type {string}
     * @memberof UseCase
     */
    'webhook': string;
    /**
     * 
     * @type {OrderRedirect}
     * @memberof UseCase
     */
    'redirect': OrderRedirect;
    /**
     * 
     * @type {UseCaseContent}
     * @memberof UseCase
     */
    'content'?: UseCaseContent;
    /**
     * 
     * @type {string}
     * @memberof UseCase
     */
    'authConfigName'?: string;
    /**
     * 
     * @type {object}
     * @memberof UseCase
     */
    'selectedIdentProvider'?: object;
    /**
     * 
     * @type {object}
     * @memberof UseCase
     */
    'credentialReleaseMode'?: object;
    /**
     * 
     * @type {AuthConfig}
     * @memberof UseCase
     */
    'auth'?: AuthConfig;
    /**
     * 
     * @type {OrderOptions}
     * @memberof UseCase
     */
    'options'?: OrderOptions;
    /**
     * 
     * @type {string}
     * @memberof UseCase
     */
    'serviceUrl': string;
    /**
     * 
     * @type {string}
     * @memberof UseCase
     */
    'logoUrl': string;
    /**
     * 
     * @type {string}
     * @memberof UseCase
     */
    'verifierId': string;
    /**
     * 
     * @type {boolean}
     * @memberof UseCase
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof UseCase
     */
    'type': UseCaseTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof UseCase
     */
    'relatedServices'?: Array<string>;
    /**
     * 
     * @type {Array<UseCase>}
     * @memberof UseCase
     */
    'services'?: Array<UseCase>;
    /**
     * 
     * @type {Verifier}
     * @memberof UseCase
     */
    'verifier'?: Verifier;
    /**
     * 
     * @type {string}
     * @memberof UseCase
     */
    'lang': UseCaseLangEnum;
    /**
     * 
     * @type {string}
     * @memberof UseCase
     */
    'baseUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof UseCase
     */
    'entryPath'?: string;
    /**
     * 
     * @type {string}
     * @memberof UseCase
     */
    'entryUrl'?: string;
}

export const UseCaseOfferedIdMethodsEnum = {
    CrefoFirmenwissen: 'crefo_firmenwissen',
    Admin: 'admin',
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident',
    PinletterIdent: 'pinletter_ident',
    Did: 'did',
    AuthrepDelegation: 'authrep_delegation',
    PinDelegation: 'pin_delegation',
    PinDelegationPersonal: 'pin_delegation_personal',
    AuthrepOrCompanyAdminDelegation: 'authrep_or_company_admin_delegation'
} as const;

export type UseCaseOfferedIdMethodsEnum = typeof UseCaseOfferedIdMethodsEnum[keyof typeof UseCaseOfferedIdMethodsEnum];
export const UseCaseAcceptedIdMethodsEnum = {
    CrefoFirmenwissen: 'crefo_firmenwissen',
    Admin: 'admin',
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident',
    PinletterIdent: 'pinletter_ident',
    Did: 'did',
    AuthrepDelegation: 'authrep_delegation',
    PinDelegation: 'pin_delegation',
    PinDelegationPersonal: 'pin_delegation_personal',
    AuthrepOrCompanyAdminDelegation: 'authrep_or_company_admin_delegation'
} as const;

export type UseCaseAcceptedIdMethodsEnum = typeof UseCaseAcceptedIdMethodsEnum[keyof typeof UseCaseAcceptedIdMethodsEnum];
export const UseCaseTypeEnum = {
    Personal: 'PERSONAL',
    Delegation: 'DELEGATION',
    UseAuthrepDelegation: 'USE_AUTHREP_DELEGATION'
} as const;

export type UseCaseTypeEnum = typeof UseCaseTypeEnum[keyof typeof UseCaseTypeEnum];
export const UseCaseLangEnum = {
    De: 'de',
    At: 'at',
    Lu: 'lu',
    Fr: 'fr',
    En: 'en'
} as const;

export type UseCaseLangEnum = typeof UseCaseLangEnum[keyof typeof UseCaseLangEnum];

/**
 * 
 * @export
 * @interface UseCaseContent
 */
export interface UseCaseContent {
    /**
     * 
     * @type {string}
     * @memberof UseCaseContent
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UseCaseContent
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof UseCaseContent
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof UseCaseContent
     */
    'logoUrl'?: string;
}
/**
 * 
 * @export
 * @interface Verifier
 */
export interface Verifier {
    /**
     * 
     * @type {string}
     * @memberof Verifier
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Verifier
     */
    'verifierId': string;
    /**
     * 
     * @type {string}
     * @memberof Verifier
     */
    'apiKey': string;
    /**
     * 
     * @type {string}
     * @memberof Verifier
     */
    'name': string;
    /**
     * 
     * @type {object}
     * @memberof Verifier
     */
    'dataEnabler'?: object;
    /**
     * 
     * @type {string}
     * @memberof Verifier
     */
    'logoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof Verifier
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Verifier
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface VerifierResponse
 */
export interface VerifierResponse {
    /**
     * 
     * @type {Array<Verifier>}
     * @memberof VerifierResponse
     */
    'data': Array<Verifier>;
    /**
     * 
     * @type {number}
     * @memberof VerifierResponse
     */
    'totalCount': number;
}
/**
 * 
 * @export
 * @interface VerifierWebhookResponse
 */
export interface VerifierWebhookResponse {
    /**
     * The unique identifier for this order.
     * @type {string}
     * @memberof VerifierWebhookResponse
     */
    'orderId': string;
    /**
     * 
     * @type {VerifierWebhookResponseMeta}
     * @memberof VerifierWebhookResponse
     */
    'meta': VerifierWebhookResponseMeta;
    /**
     * 
     * @type {VerifierWebhookResponsePerson}
     * @memberof VerifierWebhookResponse
     */
    'person': VerifierWebhookResponsePerson;
    /**
     * Describes whether or not the person has the role of an authorized representative for this company.
     * @type {boolean}
     * @memberof VerifierWebhookResponse
     */
    'authorizedRepresentative': boolean;
    /**
     * Describes whether or not the person has the role of a company agent for this company.
     * @type {boolean}
     * @memberof VerifierWebhookResponse
     */
    'verifiedAgent': boolean;
    /**
     * 
     * @type {VerifierWebhookResponseOrg}
     * @memberof VerifierWebhookResponse
     */
    'org': VerifierWebhookResponseOrg;
}
/**
 * Meta information for this order webhook response.
 * @export
 * @interface VerifierWebhookResponseMeta
 */
export interface VerifierWebhookResponseMeta {
    /**
     * The id of the verifier this order webhook response belongs to.
     * @type {string}
     * @memberof VerifierWebhookResponseMeta
     */
    'verifier': string;
    /**
     * An arbitrary data structure provided by the verifier while creating the order.
     * @type {object}
     * @memberof VerifierWebhookResponseMeta
     */
    'verifierRef': object;
    /**
     * 
     * @type {VerifierWebhookResponseMetaPerson}
     * @memberof VerifierWebhookResponseMeta
     */
    'person': VerifierWebhookResponseMetaPerson;
    /**
     * 
     * @type {VerifierWebhookResponseMetaAuthorizedRepresentative}
     * @memberof VerifierWebhookResponseMeta
     */
    'authorizedRepresentative'?: VerifierWebhookResponseMetaAuthorizedRepresentative;
    /**
     * 
     * @type {VerifierWebhookResponseMetaAuthorizedRepresentative}
     * @memberof VerifierWebhookResponseMeta
     */
    'verifiedAgent'?: VerifierWebhookResponseMetaAuthorizedRepresentative;
    /**
     * 
     * @type {VerifierWebhookResponseMetaOrg}
     * @memberof VerifierWebhookResponseMeta
     */
    'org': VerifierWebhookResponseMetaOrg;
}
/**
 * Meta data the relation \'authorized representative\' between the person and the company.
 * @export
 * @interface VerifierWebhookResponseMetaAuthorizedRepresentative
 */
export interface VerifierWebhookResponseMetaAuthorizedRepresentative {
    /**
     * Timestamp (unix epoch) when this information was created.
     * @type {number}
     * @memberof VerifierWebhookResponseMetaAuthorizedRepresentative
     */
    'issuedAt': number;
    /**
     * The user (DID) to that this information regards to.
     * @type {string}
     * @memberof VerifierWebhookResponseMetaAuthorizedRepresentative
     */
    'person': string;
    /**
     * The company (DID) to that this information regards to.
     * @type {string}
     * @memberof VerifierWebhookResponseMetaAuthorizedRepresentative
     */
    'org': string;
    /**
     * This endpoint (url) provided by CrefoTrust can be used to check the current staus of the provided information.
     * @type {string}
     * @memberof VerifierWebhookResponseMetaAuthorizedRepresentative
     */
    'status': string;
    /**
     * Issuer (DID) of the credential data.
     * @type {string}
     * @memberof VerifierWebhookResponseMetaAuthorizedRepresentative
     */
    'issuer': string;
}
/**
 * Meta data for all company related data.
 * @export
 * @interface VerifierWebhookResponseMetaOrg
 */
export interface VerifierWebhookResponseMetaOrg {
    /**
     * The did of the company.
     * @type {string}
     * @memberof VerifierWebhookResponseMetaOrg
     */
    'id'?: string;
    /**
     * 
     * @type {OrgMetaCredentials}
     * @memberof VerifierWebhookResponseMetaOrg
     */
    'credentials'?: OrgMetaCredentials;
}
/**
 * Meta data for all person related data.
 * @export
 * @interface VerifierWebhookResponseMetaPerson
 */
export interface VerifierWebhookResponseMetaPerson {
    /**
     * The did of the person.
     * @type {string}
     * @memberof VerifierWebhookResponseMetaPerson
     */
    'id'?: string;
    /**
     * 
     * @type {PersonMetaCredentials}
     * @memberof VerifierWebhookResponseMetaPerson
     */
    'credentials'?: PersonMetaCredentials;
}
/**
 * All company related information.
 * @export
 * @interface VerifierWebhookResponseOrg
 */
export interface VerifierWebhookResponseOrg {
    /**
     * The legal name of the company.
     * @type {string}
     * @memberof VerifierWebhookResponseOrg
     */
    'legalName'?: string;
    /**
     * The legal form of the company.
     * @type {string}
     * @memberof VerifierWebhookResponseOrg
     */
    'legalForm'?: string;
    /**
     * 
     * @type {VerifierWebhookResponseOrgAddress}
     * @memberof VerifierWebhookResponseOrg
     */
    'address'?: VerifierWebhookResponseOrgAddress;
    /**
     * The crefoId of the company.
     * @type {string}
     * @memberof VerifierWebhookResponseOrg
     */
    'crefoId'?: string;
}
/**
 * The address of the company.
 * @export
 * @interface VerifierWebhookResponseOrgAddress
 */
export interface VerifierWebhookResponseOrgAddress {
    /**
     * Street and number.
     * @type {string}
     * @memberof VerifierWebhookResponseOrgAddress
     */
    'streetAddress': string;
    /**
     * Postal code of the city.
     * @type {string}
     * @memberof VerifierWebhookResponseOrgAddress
     */
    'postalCode': string;
    /**
     * Name of the city.
     * @type {string}
     * @memberof VerifierWebhookResponseOrgAddress
     */
    'addressLocality': string;
    /**
     * ISO code of the country.
     * @type {string}
     * @memberof VerifierWebhookResponseOrgAddress
     */
    'addressCountry': string;
}
/**
 * All person related information.
 * @export
 * @interface VerifierWebhookResponsePerson
 */
export interface VerifierWebhookResponsePerson {
    /**
     * The crid of the person.
     * @type {string}
     * @memberof VerifierWebhookResponsePerson
     */
    'crid'?: string;
    /**
     * The crefoId of the person.
     * @type {string}
     * @memberof VerifierWebhookResponsePerson
     */
    'crefoId'?: string;
    /**
     * 
     * @type {VerifierWebhookResponsePersonAddress}
     * @memberof VerifierWebhookResponsePerson
     */
    'address'?: VerifierWebhookResponsePersonAddress;
    /**
     * 
     * @type {VerifierWebhookResponsePersonName}
     * @memberof VerifierWebhookResponsePerson
     */
    'name'?: VerifierWebhookResponsePersonName;
    /**
     * The birthdate of the person.
     * @type {string}
     * @memberof VerifierWebhookResponsePerson
     */
    'birthdate'?: string;
    /**
     * The nationality of the person.
     * @type {string}
     * @memberof VerifierWebhookResponsePerson
     */
    'nationality'?: string;
    /**
     * The person is an agent for the company.
     * @type {string}
     * @memberof VerifierWebhookResponsePerson
     */
    'companyAgent'?: string;
}
/**
 * The address of the person.
 * @export
 * @interface VerifierWebhookResponsePersonAddress
 */
export interface VerifierWebhookResponsePersonAddress {
    /**
     * Street and number.
     * @type {string}
     * @memberof VerifierWebhookResponsePersonAddress
     */
    'streetAddress': string;
    /**
     * Postal code of the city.
     * @type {string}
     * @memberof VerifierWebhookResponsePersonAddress
     */
    'postalCode': string;
    /**
     * Name of the city.
     * @type {string}
     * @memberof VerifierWebhookResponsePersonAddress
     */
    'addressLocality': string;
    /**
     * ISO code of the country.
     * @type {string}
     * @memberof VerifierWebhookResponsePersonAddress
     */
    'addressCountry': string;
}
/**
 * The name of the person.
 * @export
 * @interface VerifierWebhookResponsePersonName
 */
export interface VerifierWebhookResponsePersonName {
    /**
     * 
     * @type {string}
     * @memberof VerifierWebhookResponsePersonName
     */
    'familyName': string;
    /**
     * 
     * @type {string}
     * @memberof VerifierWebhookResponsePersonName
     */
    'givenName': string;
}

/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {AcceptOrderDto} acceptOrderDto 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGatewayControllerAcceptRequest: async (id: string, acceptOrderDto: AcceptOrderDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminGatewayControllerAcceptRequest', 'id', id)
            // verify required parameter 'acceptOrderDto' is not null or undefined
            assertParamExists('adminGatewayControllerAcceptRequest', 'acceptOrderDto', acceptOrderDto)
            const localVarPath = `/api/admin/requests/{id}/accept`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCrefotrustSystemId != null) {
                localVarHeaderParameter['x-crefotrust-system-id'] = String(xCrefotrustSystemId);
            }

            if (xCrefotrustApiKey != null) {
                localVarHeaderParameter['x-crefotrust-api-key'] = String(xCrefotrustApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(acceptOrderDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the a list of accounts.
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {string} [email] 
         * @param {string} [verified] 
         * @param {string} [crid] 
         * @param {string} [name] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [order] 
         * @param {string} [sort] 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGatewayControllerFindAccounts: async (start?: string, end?: string, email?: string, verified?: string, crid?: string, name?: string, from?: string, to?: string, order?: string, sort?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (verified !== undefined) {
                localVarQueryParameter['verified'] = verified;
            }

            if (crid !== undefined) {
                localVarQueryParameter['crid'] = crid;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (xCrefotrustSystemId != null) {
                localVarHeaderParameter['x-crefotrust-system-id'] = String(xCrefotrustSystemId);
            }

            if (xCrefotrustApiKey != null) {
                localVarHeaderParameter['x-crefotrust-api-key'] = String(xCrefotrustApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the a list of accounts.
         * @param {string} id 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGatewayControllerFindAccountsById: async (id: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminGatewayControllerFindAccountsById', 'id', id)
            const localVarPath = `/api/admin/accounts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCrefotrustSystemId != null) {
                localVarHeaderParameter['x-crefotrust-system-id'] = String(xCrefotrustSystemId);
            }

            if (xCrefotrustApiKey != null) {
                localVarHeaderParameter['x-crefotrust-api-key'] = String(xCrefotrustApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the an order based on the provided id.
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {string} [companyCrefoId] 
         * @param {string} [companyLegalName] 
         * @param {string} [companyLegalForm] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [order] 
         * @param {string} [sort] 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGatewayControllerFindCompanies: async (start?: string, end?: string, companyCrefoId?: string, companyLegalName?: string, companyLegalForm?: string, from?: string, to?: string, order?: string, sort?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (companyCrefoId !== undefined) {
                localVarQueryParameter['company.crefoId'] = companyCrefoId;
            }

            if (companyLegalName !== undefined) {
                localVarQueryParameter['company.legalName'] = companyLegalName;
            }

            if (companyLegalForm !== undefined) {
                localVarQueryParameter['company.legalForm'] = companyLegalForm;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (xCrefotrustSystemId != null) {
                localVarHeaderParameter['x-crefotrust-system-id'] = String(xCrefotrustSystemId);
            }

            if (xCrefotrustApiKey != null) {
                localVarHeaderParameter['x-crefotrust-api-key'] = String(xCrefotrustApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns ident data by orderId.
         * @param {string} id 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGatewayControllerFindIdentData: async (id: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminGatewayControllerFindIdentData', 'id', id)
            const localVarPath = `/api/admin/ident-data{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCrefotrustSystemId != null) {
                localVarHeaderParameter['x-crefotrust-system-id'] = String(xCrefotrustSystemId);
            }

            if (xCrefotrustApiKey != null) {
                localVarHeaderParameter['x-crefotrust-api-key'] = String(xCrefotrustApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a single order.
         * @param {string} id 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGatewayControllerFindOneOrder: async (id: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminGatewayControllerFindOneOrder', 'id', id)
            const localVarPath = `/api/admin/orders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCrefotrustSystemId != null) {
                localVarHeaderParameter['x-crefotrust-system-id'] = String(xCrefotrustSystemId);
            }

            if (xCrefotrustApiKey != null) {
                localVarHeaderParameter['x-crefotrust-api-key'] = String(xCrefotrustApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the an order based on the provided filter.
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {string} [id] 
         * @param {string} [companyLegalName] 
         * @param {string} [personGivenName] 
         * @param {string} [personFamilyName] 
         * @param {string} [optionsVerifierName] 
         * @param {string} [optionsUseCase] 
         * @param {string} [accountUsername] 
         * @param {string} [forOrgCrefoId] 
         * @param {string} [completed] 
         * @param {string} [hasErrorLog] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [order] 
         * @param {string} [sort] 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGatewayControllerFindOrders: async (start?: string, end?: string, id?: string, companyLegalName?: string, personGivenName?: string, personFamilyName?: string, optionsVerifierName?: string, optionsUseCase?: string, accountUsername?: string, forOrgCrefoId?: string, completed?: string, hasErrorLog?: string, from?: string, to?: string, order?: string, sort?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (companyLegalName !== undefined) {
                localVarQueryParameter['company.legalName'] = companyLegalName;
            }

            if (personGivenName !== undefined) {
                localVarQueryParameter['person.givenName'] = personGivenName;
            }

            if (personFamilyName !== undefined) {
                localVarQueryParameter['person.familyName'] = personFamilyName;
            }

            if (optionsVerifierName !== undefined) {
                localVarQueryParameter['options.verifierName'] = optionsVerifierName;
            }

            if (optionsUseCase !== undefined) {
                localVarQueryParameter['options.useCase'] = optionsUseCase;
            }

            if (accountUsername !== undefined) {
                localVarQueryParameter['account.username'] = accountUsername;
            }

            if (forOrgCrefoId !== undefined) {
                localVarQueryParameter['forOrg.crefoId'] = forOrgCrefoId;
            }

            if (completed !== undefined) {
                localVarQueryParameter['completed'] = completed;
            }

            if (hasErrorLog !== undefined) {
                localVarQueryParameter['hasErrorLog'] = hasErrorLog;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (xCrefotrustSystemId != null) {
                localVarHeaderParameter['x-crefotrust-system-id'] = String(xCrefotrustSystemId);
            }

            if (xCrefotrustApiKey != null) {
                localVarHeaderParameter['x-crefotrust-api-key'] = String(xCrefotrustApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns orders belonging to an user.
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {string} [id] 
         * @param {string} [forPersonDid] 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGatewayControllerFindOrdersByPerson: async (start?: string, end?: string, id?: string, forPersonDid?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/orders/person`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (forPersonDid !== undefined) {
                localVarQueryParameter['forPerson.did'] = forPersonDid;
            }

            if (xCrefotrustSystemId != null) {
                localVarHeaderParameter['x-crefotrust-system-id'] = String(xCrefotrustSystemId);
            }

            if (xCrefotrustApiKey != null) {
                localVarHeaderParameter['x-crefotrust-api-key'] = String(xCrefotrustApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {string} [name] 
         * @param {string} [verifierId] 
         * @param {string} [order] 
         * @param {string} [sort] 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGatewayControllerFindVerifiers: async (start?: string, end?: string, name?: string, verifierId?: string, order?: string, sort?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/verifiers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (verifierId !== undefined) {
                localVarQueryParameter['verifierId'] = verifierId;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (xCrefotrustSystemId != null) {
                localVarHeaderParameter['x-crefotrust-system-id'] = String(xCrefotrustSystemId);
            }

            if (xCrefotrustApiKey != null) {
                localVarHeaderParameter['x-crefotrust-api-key'] = String(xCrefotrustApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGatewayControllerGetFile: async (id: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminGatewayControllerGetFile', 'id', id)
            const localVarPath = `/api/admin/files/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCrefotrustSystemId != null) {
                localVarHeaderParameter['x-crefotrust-system-id'] = String(xCrefotrustSystemId);
            }

            if (xCrefotrustApiKey != null) {
                localVarHeaderParameter['x-crefotrust-api-key'] = String(xCrefotrustApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGatewayControllerGetFilesMetadataByOrderId: async (orderId: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('adminGatewayControllerGetFilesMetadataByOrderId', 'orderId', orderId)
            const localVarPath = `/api/admin/order/files/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCrefotrustSystemId != null) {
                localVarHeaderParameter['x-crefotrust-system-id'] = String(xCrefotrustSystemId);
            }

            if (xCrefotrustApiKey != null) {
                localVarHeaderParameter['x-crefotrust-api-key'] = String(xCrefotrustApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns person match errors
         * @param {string} id 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGatewayControllerGetPersonMatchErrors: async (id: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminGatewayControllerGetPersonMatchErrors', 'id', id)
            const localVarPath = `/api/admin/matching`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (xCrefotrustSystemId != null) {
                localVarHeaderParameter['x-crefotrust-system-id'] = String(xCrefotrustSystemId);
            }

            if (xCrefotrustApiKey != null) {
                localVarHeaderParameter['x-crefotrust-api-key'] = String(xCrefotrustApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {RejectOrderDto} rejectOrderDto 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGatewayControllerRejectRequest: async (id: string, rejectOrderDto: RejectOrderDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminGatewayControllerRejectRequest', 'id', id)
            // verify required parameter 'rejectOrderDto' is not null or undefined
            assertParamExists('adminGatewayControllerRejectRequest', 'rejectOrderDto', rejectOrderDto)
            const localVarPath = `/api/admin/requests/{id}/reject`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCrefotrustSystemId != null) {
                localVarHeaderParameter['x-crefotrust-system-id'] = String(xCrefotrustSystemId);
            }

            if (xCrefotrustApiKey != null) {
                localVarHeaderParameter['x-crefotrust-api-key'] = String(xCrefotrustApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rejectOrderDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateAdminUser} createAdminUser 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserControllerCreateAdminUser: async (createAdminUser: CreateAdminUser, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAdminUser' is not null or undefined
            assertParamExists('adminUserControllerCreateAdminUser', 'createAdminUser', createAdminUser)
            const localVarPath = `/api/admin-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCrefotrustSystemId != null) {
                localVarHeaderParameter['x-crefotrust-system-id'] = String(xCrefotrustSystemId);
            }

            if (xCrefotrustApiKey != null) {
                localVarHeaderParameter['x-crefotrust-api-key'] = String(xCrefotrustApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAdminUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} username 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserControllerGetAdminUser: async (username: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('adminUserControllerGetAdminUser', 'username', username)
            const localVarPath = `/api/admin-users/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCrefotrustSystemId != null) {
                localVarHeaderParameter['x-crefotrust-system-id'] = String(xCrefotrustSystemId);
            }

            if (xCrefotrustApiKey != null) {
                localVarHeaderParameter['x-crefotrust-api-key'] = String(xCrefotrustApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} username 
         * @param {UpdateAdminUser} updateAdminUser 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserControllerUpdateAdminPassword: async (username: string, updateAdminUser: UpdateAdminUser, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('adminUserControllerUpdateAdminPassword', 'username', username)
            // verify required parameter 'updateAdminUser' is not null or undefined
            assertParamExists('adminUserControllerUpdateAdminPassword', 'updateAdminUser', updateAdminUser)
            const localVarPath = `/api/admin-users/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCrefotrustSystemId != null) {
                localVarHeaderParameter['x-crefotrust-system-id'] = String(xCrefotrustSystemId);
            }

            if (xCrefotrustApiKey != null) {
                localVarHeaderParameter['x-crefotrust-api-key'] = String(xCrefotrustApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAdminUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {AcceptOrderDto} acceptOrderDto 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGatewayControllerAcceptRequest(id: string, acceptOrderDto: AcceptOrderDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGatewayControllerAcceptRequest(id, acceptOrderDto, xCrefotrustSystemId, xCrefotrustApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the a list of accounts.
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {string} [email] 
         * @param {string} [verified] 
         * @param {string} [crid] 
         * @param {string} [name] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [order] 
         * @param {string} [sort] 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGatewayControllerFindAccounts(start?: string, end?: string, email?: string, verified?: string, crid?: string, name?: string, from?: string, to?: string, order?: string, sort?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminAccountsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGatewayControllerFindAccounts(start, end, email, verified, crid, name, from, to, order, sort, xCrefotrustSystemId, xCrefotrustApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the a list of accounts.
         * @param {string} id 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGatewayControllerFindAccountsById(id: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Account>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGatewayControllerFindAccountsById(id, xCrefotrustSystemId, xCrefotrustApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the an order based on the provided id.
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {string} [companyCrefoId] 
         * @param {string} [companyLegalName] 
         * @param {string} [companyLegalForm] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [order] 
         * @param {string} [sort] 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGatewayControllerFindCompanies(start?: string, end?: string, companyCrefoId?: string, companyLegalName?: string, companyLegalForm?: string, from?: string, to?: string, order?: string, sort?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminCompaniesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGatewayControllerFindCompanies(start, end, companyCrefoId, companyLegalName, companyLegalForm, from, to, order, sort, xCrefotrustSystemId, xCrefotrustApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns ident data by orderId.
         * @param {string} id 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGatewayControllerFindIdentData(id: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGatewayControllerFindIdentData(id, xCrefotrustSystemId, xCrefotrustApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a single order.
         * @param {string} id 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGatewayControllerFindOneOrder(id: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGatewayControllerFindOneOrder(id, xCrefotrustSystemId, xCrefotrustApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the an order based on the provided filter.
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {string} [id] 
         * @param {string} [companyLegalName] 
         * @param {string} [personGivenName] 
         * @param {string} [personFamilyName] 
         * @param {string} [optionsVerifierName] 
         * @param {string} [optionsUseCase] 
         * @param {string} [accountUsername] 
         * @param {string} [forOrgCrefoId] 
         * @param {string} [completed] 
         * @param {string} [hasErrorLog] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [order] 
         * @param {string} [sort] 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGatewayControllerFindOrders(start?: string, end?: string, id?: string, companyLegalName?: string, personGivenName?: string, personFamilyName?: string, optionsVerifierName?: string, optionsUseCase?: string, accountUsername?: string, forOrgCrefoId?: string, completed?: string, hasErrorLog?: string, from?: string, to?: string, order?: string, sort?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGatewayControllerFindOrders(start, end, id, companyLegalName, personGivenName, personFamilyName, optionsVerifierName, optionsUseCase, accountUsername, forOrgCrefoId, completed, hasErrorLog, from, to, order, sort, xCrefotrustSystemId, xCrefotrustApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns orders belonging to an user.
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {string} [id] 
         * @param {string} [forPersonDid] 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGatewayControllerFindOrdersByPerson(start?: string, end?: string, id?: string, forPersonDid?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Order>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGatewayControllerFindOrdersByPerson(start, end, id, forPersonDid, xCrefotrustSystemId, xCrefotrustApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {string} [name] 
         * @param {string} [verifierId] 
         * @param {string} [order] 
         * @param {string} [sort] 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGatewayControllerFindVerifiers(start?: string, end?: string, name?: string, verifierId?: string, order?: string, sort?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminVerifiersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGatewayControllerFindVerifiers(start, end, name, verifierId, order, sort, xCrefotrustSystemId, xCrefotrustApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGatewayControllerGetFile(id: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGatewayControllerGetFile(id, xCrefotrustSystemId, xCrefotrustApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orderId 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGatewayControllerGetFilesMetadataByOrderId(orderId: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FileUpload>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGatewayControllerGetFilesMetadataByOrderId(orderId, xCrefotrustSystemId, xCrefotrustApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns person match errors
         * @param {string} id 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGatewayControllerGetPersonMatchErrors(id: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PersonMatchError>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGatewayControllerGetPersonMatchErrors(id, xCrefotrustSystemId, xCrefotrustApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {RejectOrderDto} rejectOrderDto 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGatewayControllerRejectRequest(id: string, rejectOrderDto: RejectOrderDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGatewayControllerRejectRequest(id, rejectOrderDto, xCrefotrustSystemId, xCrefotrustApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateAdminUser} createAdminUser 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserControllerCreateAdminUser(createAdminUser: CreateAdminUser, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserControllerCreateAdminUser(createAdminUser, xCrefotrustSystemId, xCrefotrustApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} username 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserControllerGetAdminUser(username: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserControllerGetAdminUser(username, xCrefotrustSystemId, xCrefotrustApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} username 
         * @param {UpdateAdminUser} updateAdminUser 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserControllerUpdateAdminPassword(username: string, updateAdminUser: UpdateAdminUser, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserControllerUpdateAdminPassword(username, updateAdminUser, xCrefotrustSystemId, xCrefotrustApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {AcceptOrderDto} acceptOrderDto 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGatewayControllerAcceptRequest(id: string, acceptOrderDto: AcceptOrderDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminGatewayControllerAcceptRequest(id, acceptOrderDto, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the a list of accounts.
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {string} [email] 
         * @param {string} [verified] 
         * @param {string} [crid] 
         * @param {string} [name] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [order] 
         * @param {string} [sort] 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGatewayControllerFindAccounts(start?: string, end?: string, email?: string, verified?: string, crid?: string, name?: string, from?: string, to?: string, order?: string, sort?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: any): AxiosPromise<AdminAccountsResponse> {
            return localVarFp.adminGatewayControllerFindAccounts(start, end, email, verified, crid, name, from, to, order, sort, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the a list of accounts.
         * @param {string} id 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGatewayControllerFindAccountsById(id: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: any): AxiosPromise<Array<Account>> {
            return localVarFp.adminGatewayControllerFindAccountsById(id, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the an order based on the provided id.
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {string} [companyCrefoId] 
         * @param {string} [companyLegalName] 
         * @param {string} [companyLegalForm] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [order] 
         * @param {string} [sort] 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGatewayControllerFindCompanies(start?: string, end?: string, companyCrefoId?: string, companyLegalName?: string, companyLegalForm?: string, from?: string, to?: string, order?: string, sort?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: any): AxiosPromise<AdminCompaniesResponse> {
            return localVarFp.adminGatewayControllerFindCompanies(start, end, companyCrefoId, companyLegalName, companyLegalForm, from, to, order, sort, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns ident data by orderId.
         * @param {string} id 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGatewayControllerFindIdentData(id: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: any): AxiosPromise<IdentData> {
            return localVarFp.adminGatewayControllerFindIdentData(id, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a single order.
         * @param {string} id 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGatewayControllerFindOneOrder(id: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: any): AxiosPromise<Order> {
            return localVarFp.adminGatewayControllerFindOneOrder(id, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the an order based on the provided filter.
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {string} [id] 
         * @param {string} [companyLegalName] 
         * @param {string} [personGivenName] 
         * @param {string} [personFamilyName] 
         * @param {string} [optionsVerifierName] 
         * @param {string} [optionsUseCase] 
         * @param {string} [accountUsername] 
         * @param {string} [forOrgCrefoId] 
         * @param {string} [completed] 
         * @param {string} [hasErrorLog] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [order] 
         * @param {string} [sort] 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGatewayControllerFindOrders(start?: string, end?: string, id?: string, companyLegalName?: string, personGivenName?: string, personFamilyName?: string, optionsVerifierName?: string, optionsUseCase?: string, accountUsername?: string, forOrgCrefoId?: string, completed?: string, hasErrorLog?: string, from?: string, to?: string, order?: string, sort?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: any): AxiosPromise<OrderResponseDto> {
            return localVarFp.adminGatewayControllerFindOrders(start, end, id, companyLegalName, personGivenName, personFamilyName, optionsVerifierName, optionsUseCase, accountUsername, forOrgCrefoId, completed, hasErrorLog, from, to, order, sort, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns orders belonging to an user.
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {string} [id] 
         * @param {string} [forPersonDid] 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGatewayControllerFindOrdersByPerson(start?: string, end?: string, id?: string, forPersonDid?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: any): AxiosPromise<Array<Order>> {
            return localVarFp.adminGatewayControllerFindOrdersByPerson(start, end, id, forPersonDid, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {string} [name] 
         * @param {string} [verifierId] 
         * @param {string} [order] 
         * @param {string} [sort] 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGatewayControllerFindVerifiers(start?: string, end?: string, name?: string, verifierId?: string, order?: string, sort?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: any): AxiosPromise<AdminVerifiersResponse> {
            return localVarFp.adminGatewayControllerFindVerifiers(start, end, name, verifierId, order, sort, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGatewayControllerGetFile(id: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminGatewayControllerGetFile(id, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderId 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGatewayControllerGetFilesMetadataByOrderId(orderId: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: any): AxiosPromise<Array<FileUpload>> {
            return localVarFp.adminGatewayControllerGetFilesMetadataByOrderId(orderId, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns person match errors
         * @param {string} id 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGatewayControllerGetPersonMatchErrors(id: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: any): AxiosPromise<Array<PersonMatchError>> {
            return localVarFp.adminGatewayControllerGetPersonMatchErrors(id, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {RejectOrderDto} rejectOrderDto 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGatewayControllerRejectRequest(id: string, rejectOrderDto: RejectOrderDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminGatewayControllerRejectRequest(id, rejectOrderDto, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateAdminUser} createAdminUser 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserControllerCreateAdminUser(createAdminUser: CreateAdminUser, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: any): AxiosPromise<AdminUser> {
            return localVarFp.adminUserControllerCreateAdminUser(createAdminUser, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} username 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserControllerGetAdminUser(username: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: any): AxiosPromise<AdminUser> {
            return localVarFp.adminUserControllerGetAdminUser(username, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} username 
         * @param {UpdateAdminUser} updateAdminUser 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserControllerUpdateAdminPassword(username: string, updateAdminUser: UpdateAdminUser, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: any): AxiosPromise<AdminUser> {
            return localVarFp.adminUserControllerUpdateAdminPassword(username, updateAdminUser, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {AcceptOrderDto} acceptOrderDto 
     * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
     * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGatewayControllerAcceptRequest(id: string, acceptOrderDto: AcceptOrderDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminGatewayControllerAcceptRequest(id, acceptOrderDto, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the a list of accounts.
     * @param {string} [start] 
     * @param {string} [end] 
     * @param {string} [email] 
     * @param {string} [verified] 
     * @param {string} [crid] 
     * @param {string} [name] 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {string} [order] 
     * @param {string} [sort] 
     * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
     * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGatewayControllerFindAccounts(start?: string, end?: string, email?: string, verified?: string, crid?: string, name?: string, from?: string, to?: string, order?: string, sort?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminGatewayControllerFindAccounts(start, end, email, verified, crid, name, from, to, order, sort, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the a list of accounts.
     * @param {string} id 
     * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
     * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGatewayControllerFindAccountsById(id: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminGatewayControllerFindAccountsById(id, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the an order based on the provided id.
     * @param {string} [start] 
     * @param {string} [end] 
     * @param {string} [companyCrefoId] 
     * @param {string} [companyLegalName] 
     * @param {string} [companyLegalForm] 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {string} [order] 
     * @param {string} [sort] 
     * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
     * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGatewayControllerFindCompanies(start?: string, end?: string, companyCrefoId?: string, companyLegalName?: string, companyLegalForm?: string, from?: string, to?: string, order?: string, sort?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminGatewayControllerFindCompanies(start, end, companyCrefoId, companyLegalName, companyLegalForm, from, to, order, sort, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns ident data by orderId.
     * @param {string} id 
     * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
     * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGatewayControllerFindIdentData(id: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminGatewayControllerFindIdentData(id, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a single order.
     * @param {string} id 
     * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
     * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGatewayControllerFindOneOrder(id: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminGatewayControllerFindOneOrder(id, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the an order based on the provided filter.
     * @param {string} [start] 
     * @param {string} [end] 
     * @param {string} [id] 
     * @param {string} [companyLegalName] 
     * @param {string} [personGivenName] 
     * @param {string} [personFamilyName] 
     * @param {string} [optionsVerifierName] 
     * @param {string} [optionsUseCase] 
     * @param {string} [accountUsername] 
     * @param {string} [forOrgCrefoId] 
     * @param {string} [completed] 
     * @param {string} [hasErrorLog] 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {string} [order] 
     * @param {string} [sort] 
     * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
     * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGatewayControllerFindOrders(start?: string, end?: string, id?: string, companyLegalName?: string, personGivenName?: string, personFamilyName?: string, optionsVerifierName?: string, optionsUseCase?: string, accountUsername?: string, forOrgCrefoId?: string, completed?: string, hasErrorLog?: string, from?: string, to?: string, order?: string, sort?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminGatewayControllerFindOrders(start, end, id, companyLegalName, personGivenName, personFamilyName, optionsVerifierName, optionsUseCase, accountUsername, forOrgCrefoId, completed, hasErrorLog, from, to, order, sort, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns orders belonging to an user.
     * @param {string} [start] 
     * @param {string} [end] 
     * @param {string} [id] 
     * @param {string} [forPersonDid] 
     * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
     * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGatewayControllerFindOrdersByPerson(start?: string, end?: string, id?: string, forPersonDid?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminGatewayControllerFindOrdersByPerson(start, end, id, forPersonDid, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [start] 
     * @param {string} [end] 
     * @param {string} [name] 
     * @param {string} [verifierId] 
     * @param {string} [order] 
     * @param {string} [sort] 
     * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
     * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGatewayControllerFindVerifiers(start?: string, end?: string, name?: string, verifierId?: string, order?: string, sort?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminGatewayControllerFindVerifiers(start, end, name, verifierId, order, sort, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
     * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGatewayControllerGetFile(id: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminGatewayControllerGetFile(id, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderId 
     * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
     * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGatewayControllerGetFilesMetadataByOrderId(orderId: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminGatewayControllerGetFilesMetadataByOrderId(orderId, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns person match errors
     * @param {string} id 
     * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
     * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGatewayControllerGetPersonMatchErrors(id: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminGatewayControllerGetPersonMatchErrors(id, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {RejectOrderDto} rejectOrderDto 
     * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
     * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGatewayControllerRejectRequest(id: string, rejectOrderDto: RejectOrderDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminGatewayControllerRejectRequest(id, rejectOrderDto, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateAdminUser} createAdminUser 
     * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
     * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminUserControllerCreateAdminUser(createAdminUser: CreateAdminUser, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminUserControllerCreateAdminUser(createAdminUser, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} username 
     * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
     * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminUserControllerGetAdminUser(username: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminUserControllerGetAdminUser(username, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} username 
     * @param {UpdateAdminUser} updateAdminUser 
     * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
     * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminUserControllerUpdateAdminPassword(username: string, updateAdminUser: UpdateAdminUser, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminUserControllerUpdateAdminPassword(username, updateAdminUser, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthConfigurationsApi - axios parameter creator
 * @export
 */
export const AuthConfigurationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateAuthConfigDto} createAuthConfigDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authConfigControllerCreateAuthConfiguration: async (createAuthConfigDto: CreateAuthConfigDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAuthConfigDto' is not null or undefined
            assertParamExists('authConfigControllerCreateAuthConfiguration', 'createAuthConfigDto', createAuthConfigDto)
            const localVarPath = `/api/auth-configurations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAuthConfigDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authConfigControllerGetAuthConfiguration: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('authConfigControllerGetAuthConfiguration', 'name', name)
            const localVarPath = `/api/auth-configurations/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authConfigControllerGetAuthConfigurations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth-configurations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {UpdateAuthConfigDto} updateAuthConfigDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authConfigControllerUpdateAuthConfiguration: async (name: string, updateAuthConfigDto: UpdateAuthConfigDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('authConfigControllerUpdateAuthConfiguration', 'name', name)
            // verify required parameter 'updateAuthConfigDto' is not null or undefined
            assertParamExists('authConfigControllerUpdateAuthConfiguration', 'updateAuthConfigDto', updateAuthConfigDto)
            const localVarPath = `/api/auth-configurations/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAuthConfigDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthConfigurationsApi - functional programming interface
 * @export
 */
export const AuthConfigurationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthConfigurationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateAuthConfigDto} createAuthConfigDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authConfigControllerCreateAuthConfiguration(createAuthConfigDto: CreateAuthConfigDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authConfigControllerCreateAuthConfiguration(createAuthConfigDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authConfigControllerGetAuthConfiguration(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authConfigControllerGetAuthConfiguration(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authConfigControllerGetAuthConfigurations(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authConfigControllerGetAuthConfigurations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {UpdateAuthConfigDto} updateAuthConfigDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authConfigControllerUpdateAuthConfiguration(name: string, updateAuthConfigDto: UpdateAuthConfigDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authConfigControllerUpdateAuthConfiguration(name, updateAuthConfigDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthConfigurationsApi - factory interface
 * @export
 */
export const AuthConfigurationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthConfigurationsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateAuthConfigDto} createAuthConfigDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authConfigControllerCreateAuthConfiguration(createAuthConfigDto: CreateAuthConfigDto, options?: any): AxiosPromise<AuthConfig> {
            return localVarFp.authConfigControllerCreateAuthConfiguration(createAuthConfigDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authConfigControllerGetAuthConfiguration(name: string, options?: any): AxiosPromise<AuthConfig> {
            return localVarFp.authConfigControllerGetAuthConfiguration(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authConfigControllerGetAuthConfigurations(options?: any): AxiosPromise<AuthConfig> {
            return localVarFp.authConfigControllerGetAuthConfigurations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {UpdateAuthConfigDto} updateAuthConfigDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authConfigControllerUpdateAuthConfiguration(name: string, updateAuthConfigDto: UpdateAuthConfigDto, options?: any): AxiosPromise<AuthConfig> {
            return localVarFp.authConfigControllerUpdateAuthConfiguration(name, updateAuthConfigDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthConfigurationsApi - object-oriented interface
 * @export
 * @class AuthConfigurationsApi
 * @extends {BaseAPI}
 */
export class AuthConfigurationsApi extends BaseAPI {
    /**
     * 
     * @param {CreateAuthConfigDto} createAuthConfigDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthConfigurationsApi
     */
    public authConfigControllerCreateAuthConfiguration(createAuthConfigDto: CreateAuthConfigDto, options?: AxiosRequestConfig) {
        return AuthConfigurationsApiFp(this.configuration).authConfigControllerCreateAuthConfiguration(createAuthConfigDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthConfigurationsApi
     */
    public authConfigControllerGetAuthConfiguration(name: string, options?: AxiosRequestConfig) {
        return AuthConfigurationsApiFp(this.configuration).authConfigControllerGetAuthConfiguration(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthConfigurationsApi
     */
    public authConfigControllerGetAuthConfigurations(options?: AxiosRequestConfig) {
        return AuthConfigurationsApiFp(this.configuration).authConfigControllerGetAuthConfigurations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {UpdateAuthConfigDto} updateAuthConfigDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthConfigurationsApi
     */
    public authConfigControllerUpdateAuthConfiguration(name: string, updateAuthConfigDto: UpdateAuthConfigDto, options?: AxiosRequestConfig) {
        return AuthConfigurationsApiFp(this.configuration).authConfigControllerUpdateAuthConfiguration(name, updateAuthConfigDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CompanyApi - axios parameter creator
 * @export
 */
export const CompanyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns all company information for the provided company crefoId
         * @param {string} companyDid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerGetCompanyInformation: async (companyDid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyDid' is not null or undefined
            assertParamExists('companyControllerGetCompanyInformation', 'companyDid', companyDid)
            const localVarPath = `/api/company/{companyDid}`
                .replace(`{${"companyDid"}}`, encodeURIComponent(String(companyDid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyApi - functional programming interface
 * @export
 */
export const CompanyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns all company information for the provided company crefoId
         * @param {string} companyDid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyControllerGetCompanyInformation(companyDid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyInformationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerGetCompanyInformation(companyDid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompanyApi - factory interface
 * @export
 */
export const CompanyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns all company information for the provided company crefoId
         * @param {string} companyDid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerGetCompanyInformation(companyDid: string, options?: any): AxiosPromise<CompanyInformationDto> {
            return localVarFp.companyControllerGetCompanyInformation(companyDid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyApi - object-oriented interface
 * @export
 * @class CompanyApi
 * @extends {BaseAPI}
 */
export class CompanyApi extends BaseAPI {
    /**
     * 
     * @summary Returns all company information for the provided company crefoId
     * @param {string} companyDid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyControllerGetCompanyInformation(companyDid: string, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyControllerGetCompanyInformation(companyDid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CredentialsApi - axios parameter creator
 * @export
 */
export const CredentialsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the current validity state of a credential.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        credentialsControllerGetCredentialState: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('credentialsControllerGetCredentialState', 'id', id)
            const localVarPath = `/api/credentials/{id}/status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the current validity state of a credential.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        credentialsControllerGetCredentialState_1: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('credentialsControllerGetCredentialState_1', 'id', id)
            const localVarPath = `/api/credentials/{id}/status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the a list of credentials for a given id.
         * @param {string} [did] 
         * @param {string} [payloadWorksFor] 
         * @param {string} [xCrefotrustSystemId] 
         * @param {string} [xCrefotrustApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        credentialsControllerGetCredentials: async (did?: string, payloadWorksFor?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/credentials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (did !== undefined) {
                localVarQueryParameter['did'] = did;
            }

            if (payloadWorksFor !== undefined) {
                localVarQueryParameter['payload.worksFor'] = payloadWorksFor;
            }

            if (xCrefotrustSystemId != null) {
                localVarHeaderParameter['x-crefotrust-system-id'] = String(xCrefotrustSystemId);
            }

            if (xCrefotrustApiKey != null) {
                localVarHeaderParameter['x-crefotrust-api-key'] = String(xCrefotrustApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {string} [xCrefotrustSystemId] 
         * @param {string} [xCrefotrustApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        credentialsControllerIssueCredentials: async (requestBody: Array<string>, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('credentialsControllerIssueCredentials', 'requestBody', requestBody)
            const localVarPath = `/api/credentials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCrefotrustSystemId != null) {
                localVarHeaderParameter['x-crefotrust-system-id'] = String(xCrefotrustSystemId);
            }

            if (xCrefotrustApiKey != null) {
                localVarHeaderParameter['x-crefotrust-api-key'] = String(xCrefotrustApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RevokeCredentialsDto} revokeCredentialsDto 
         * @param {string} [xCrefotrustSystemId] 
         * @param {string} [xCrefotrustApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        credentialsControllerRevokeCredentialsPublic: async (revokeCredentialsDto: RevokeCredentialsDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'revokeCredentialsDto' is not null or undefined
            assertParamExists('credentialsControllerRevokeCredentialsPublic', 'revokeCredentialsDto', revokeCredentialsDto)
            const localVarPath = `/api/credentials/revoke`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCrefotrustSystemId != null) {
                localVarHeaderParameter['x-crefotrust-system-id'] = String(xCrefotrustSystemId);
            }

            if (xCrefotrustApiKey != null) {
                localVarHeaderParameter['x-crefotrust-api-key'] = String(xCrefotrustApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(revokeCredentialsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CredentialsApi - functional programming interface
 * @export
 */
export const CredentialsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CredentialsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get the current validity state of a credential.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async credentialsControllerGetCredentialState(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.credentialsControllerGetCredentialState(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the current validity state of a credential.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async credentialsControllerGetCredentialState_1(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.credentialsControllerGetCredentialState_1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the a list of credentials for a given id.
         * @param {string} [did] 
         * @param {string} [payloadWorksFor] 
         * @param {string} [xCrefotrustSystemId] 
         * @param {string} [xCrefotrustApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async credentialsControllerGetCredentials(did?: string, payloadWorksFor?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.credentialsControllerGetCredentials(did, payloadWorksFor, xCrefotrustSystemId, xCrefotrustApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {string} [xCrefotrustSystemId] 
         * @param {string} [xCrefotrustApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async credentialsControllerIssueCredentials(requestBody: Array<string>, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.credentialsControllerIssueCredentials(requestBody, xCrefotrustSystemId, xCrefotrustApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RevokeCredentialsDto} revokeCredentialsDto 
         * @param {string} [xCrefotrustSystemId] 
         * @param {string} [xCrefotrustApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async credentialsControllerRevokeCredentialsPublic(revokeCredentialsDto: RevokeCredentialsDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.credentialsControllerRevokeCredentialsPublic(revokeCredentialsDto, xCrefotrustSystemId, xCrefotrustApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CredentialsApi - factory interface
 * @export
 */
export const CredentialsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CredentialsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get the current validity state of a credential.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        credentialsControllerGetCredentialState(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.credentialsControllerGetCredentialState(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the current validity state of a credential.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        credentialsControllerGetCredentialState_1(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.credentialsControllerGetCredentialState_1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the a list of credentials for a given id.
         * @param {string} [did] 
         * @param {string} [payloadWorksFor] 
         * @param {string} [xCrefotrustSystemId] 
         * @param {string} [xCrefotrustApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        credentialsControllerGetCredentials(did?: string, payloadWorksFor?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.credentialsControllerGetCredentials(did, payloadWorksFor, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {string} [xCrefotrustSystemId] 
         * @param {string} [xCrefotrustApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        credentialsControllerIssueCredentials(requestBody: Array<string>, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: any): AxiosPromise<void> {
            return localVarFp.credentialsControllerIssueCredentials(requestBody, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RevokeCredentialsDto} revokeCredentialsDto 
         * @param {string} [xCrefotrustSystemId] 
         * @param {string} [xCrefotrustApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        credentialsControllerRevokeCredentialsPublic(revokeCredentialsDto: RevokeCredentialsDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: any): AxiosPromise<void> {
            return localVarFp.credentialsControllerRevokeCredentialsPublic(revokeCredentialsDto, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CredentialsApi - object-oriented interface
 * @export
 * @class CredentialsApi
 * @extends {BaseAPI}
 */
export class CredentialsApi extends BaseAPI {
    /**
     * 
     * @summary Get the current validity state of a credential.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CredentialsApi
     */
    public credentialsControllerGetCredentialState(id: string, options?: AxiosRequestConfig) {
        return CredentialsApiFp(this.configuration).credentialsControllerGetCredentialState(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the current validity state of a credential.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CredentialsApi
     */
    public credentialsControllerGetCredentialState_1(id: string, options?: AxiosRequestConfig) {
        return CredentialsApiFp(this.configuration).credentialsControllerGetCredentialState_1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the a list of credentials for a given id.
     * @param {string} [did] 
     * @param {string} [payloadWorksFor] 
     * @param {string} [xCrefotrustSystemId] 
     * @param {string} [xCrefotrustApiKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CredentialsApi
     */
    public credentialsControllerGetCredentials(did?: string, payloadWorksFor?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig) {
        return CredentialsApiFp(this.configuration).credentialsControllerGetCredentials(did, payloadWorksFor, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} requestBody 
     * @param {string} [xCrefotrustSystemId] 
     * @param {string} [xCrefotrustApiKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CredentialsApi
     */
    public credentialsControllerIssueCredentials(requestBody: Array<string>, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig) {
        return CredentialsApiFp(this.configuration).credentialsControllerIssueCredentials(requestBody, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RevokeCredentialsDto} revokeCredentialsDto 
     * @param {string} [xCrefotrustSystemId] 
     * @param {string} [xCrefotrustApiKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CredentialsApi
     */
    public credentialsControllerRevokeCredentialsPublic(revokeCredentialsDto: RevokeCredentialsDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig) {
        return CredentialsApiFp(this.configuration).credentialsControllerRevokeCredentialsPublic(revokeCredentialsDto, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DIDApi - axios parameter creator
 * @export
 */
export const DIDApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        didControllerGetCrefoTrustDidDocument: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/.well-known/did.json`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} type 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        didControllerGetDidDocument: async (type: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('didControllerGetDidDocument', 'type', type)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('didControllerGetDidDocument', 'id', id)
            const localVarPath = `/{type}/{id}/did.json`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DIDApi - functional programming interface
 * @export
 */
export const DIDApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DIDApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async didControllerGetCrefoTrustDidDocument(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.didControllerGetCrefoTrustDidDocument(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} type 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async didControllerGetDidDocument(type: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.didControllerGetDidDocument(type, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DIDApi - factory interface
 * @export
 */
export const DIDApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DIDApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        didControllerGetCrefoTrustDidDocument(options?: any): AxiosPromise<void> {
            return localVarFp.didControllerGetCrefoTrustDidDocument(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} type 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        didControllerGetDidDocument(type: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.didControllerGetDidDocument(type, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DIDApi - object-oriented interface
 * @export
 * @class DIDApi
 * @extends {BaseAPI}
 */
export class DIDApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DIDApi
     */
    public didControllerGetCrefoTrustDidDocument(options?: AxiosRequestConfig) {
        return DIDApiFp(this.configuration).didControllerGetCrefoTrustDidDocument(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} type 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DIDApi
     */
    public didControllerGetDidDocument(type: string, id: string, options?: AxiosRequestConfig) {
        return DIDApiFp(this.configuration).didControllerGetDidDocument(type, id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FirmenwissenApi - axios parameter creator
 * @export
 */
export const FirmenwissenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get one company by crefoId from the Firmenwissen API.
         * @param {string} crefoId 
         * @param {string} [did] A unique decentralized identifier connected to the current session.
         * @param {string} [orderId] A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        firmenwissenGatewayControllerGetCompany: async (crefoId: string, did?: string, orderId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'crefoId' is not null or undefined
            assertParamExists('firmenwissenGatewayControllerGetCompany', 'crefoId', crefoId)
            const localVarPath = `/api/firmenwissen/companies/{crefoId}`
                .replace(`{${"crefoId"}}`, encodeURIComponent(String(crefoId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (did != null) {
                localVarHeaderParameter['did'] = String(did);
            }

            if (orderId != null) {
                localVarHeaderParameter['order-id'] = String(orderId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search company by name at Firmenwissen API.
         * @param {string} companyName 
         * @param {string} [did] A unique decentralized identifier connected to the current session.
         * @param {string} [orderId] A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        firmenwissenGatewayControllerSearchCompanies: async (companyName: string, did?: string, orderId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyName' is not null or undefined
            assertParamExists('firmenwissenGatewayControllerSearchCompanies', 'companyName', companyName)
            const localVarPath = `/api/firmenwissen/searchCompany/{companyName}`
                .replace(`{${"companyName"}}`, encodeURIComponent(String(companyName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (did != null) {
                localVarHeaderParameter['did'] = String(did);
            }

            if (orderId != null) {
                localVarHeaderParameter['order-id'] = String(orderId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search managers of a company based on the company\'s crefoId at Firmenwissen API.
         * @param {string} crefoId 
         * @param {string} [did] A unique decentralized identifier connected to the current session.
         * @param {string} [orderId] A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        firmenwissenGatewayControllerSearchManager: async (crefoId: string, did?: string, orderId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'crefoId' is not null or undefined
            assertParamExists('firmenwissenGatewayControllerSearchManager', 'crefoId', crefoId)
            const localVarPath = `/api/firmenwissen/searchManager/{crefoId}`
                .replace(`{${"crefoId"}}`, encodeURIComponent(String(crefoId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (did != null) {
                localVarHeaderParameter['did'] = String(did);
            }

            if (orderId != null) {
                localVarHeaderParameter['order-id'] = String(orderId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FirmenwissenApi - functional programming interface
 * @export
 */
export const FirmenwissenApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FirmenwissenApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get one company by crefoId from the Firmenwissen API.
         * @param {string} crefoId 
         * @param {string} [did] A unique decentralized identifier connected to the current session.
         * @param {string} [orderId] A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async firmenwissenGatewayControllerGetCompany(crefoId: string, did?: string, orderId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.firmenwissenGatewayControllerGetCompany(crefoId, did, orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search company by name at Firmenwissen API.
         * @param {string} companyName 
         * @param {string} [did] A unique decentralized identifier connected to the current session.
         * @param {string} [orderId] A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async firmenwissenGatewayControllerSearchCompanies(companyName: string, did?: string, orderId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Company>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.firmenwissenGatewayControllerSearchCompanies(companyName, did, orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search managers of a company based on the company\'s crefoId at Firmenwissen API.
         * @param {string} crefoId 
         * @param {string} [did] A unique decentralized identifier connected to the current session.
         * @param {string} [orderId] A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async firmenwissenGatewayControllerSearchManager(crefoId: string, did?: string, orderId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Manager>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.firmenwissenGatewayControllerSearchManager(crefoId, did, orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FirmenwissenApi - factory interface
 * @export
 */
export const FirmenwissenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FirmenwissenApiFp(configuration)
    return {
        /**
         * 
         * @summary Get one company by crefoId from the Firmenwissen API.
         * @param {string} crefoId 
         * @param {string} [did] A unique decentralized identifier connected to the current session.
         * @param {string} [orderId] A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        firmenwissenGatewayControllerGetCompany(crefoId: string, did?: string, orderId?: string, options?: any): AxiosPromise<Company> {
            return localVarFp.firmenwissenGatewayControllerGetCompany(crefoId, did, orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search company by name at Firmenwissen API.
         * @param {string} companyName 
         * @param {string} [did] A unique decentralized identifier connected to the current session.
         * @param {string} [orderId] A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        firmenwissenGatewayControllerSearchCompanies(companyName: string, did?: string, orderId?: string, options?: any): AxiosPromise<Array<Company>> {
            return localVarFp.firmenwissenGatewayControllerSearchCompanies(companyName, did, orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search managers of a company based on the company\'s crefoId at Firmenwissen API.
         * @param {string} crefoId 
         * @param {string} [did] A unique decentralized identifier connected to the current session.
         * @param {string} [orderId] A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        firmenwissenGatewayControllerSearchManager(crefoId: string, did?: string, orderId?: string, options?: any): AxiosPromise<Array<Manager>> {
            return localVarFp.firmenwissenGatewayControllerSearchManager(crefoId, did, orderId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FirmenwissenApi - object-oriented interface
 * @export
 * @class FirmenwissenApi
 * @extends {BaseAPI}
 */
export class FirmenwissenApi extends BaseAPI {
    /**
     * 
     * @summary Get one company by crefoId from the Firmenwissen API.
     * @param {string} crefoId 
     * @param {string} [did] A unique decentralized identifier connected to the current session.
     * @param {string} [orderId] A valid identifier, created by a verifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirmenwissenApi
     */
    public firmenwissenGatewayControllerGetCompany(crefoId: string, did?: string, orderId?: string, options?: AxiosRequestConfig) {
        return FirmenwissenApiFp(this.configuration).firmenwissenGatewayControllerGetCompany(crefoId, did, orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search company by name at Firmenwissen API.
     * @param {string} companyName 
     * @param {string} [did] A unique decentralized identifier connected to the current session.
     * @param {string} [orderId] A valid identifier, created by a verifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirmenwissenApi
     */
    public firmenwissenGatewayControllerSearchCompanies(companyName: string, did?: string, orderId?: string, options?: AxiosRequestConfig) {
        return FirmenwissenApiFp(this.configuration).firmenwissenGatewayControllerSearchCompanies(companyName, did, orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search managers of a company based on the company\'s crefoId at Firmenwissen API.
     * @param {string} crefoId 
     * @param {string} [did] A unique decentralized identifier connected to the current session.
     * @param {string} [orderId] A valid identifier, created by a verifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirmenwissenApi
     */
    public firmenwissenGatewayControllerSearchManager(crefoId: string, did?: string, orderId?: string, options?: AxiosRequestConfig) {
        return FirmenwissenApiFp(this.configuration).firmenwissenGatewayControllerSearchManager(crefoId, did, orderId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HolderApi - axios parameter creator
 * @export
 */
export const HolderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Aborts an order.
         * @param {string} did A unique decentralized identifier connected to the current session.
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holderGatewayControllerAbort: async (did: string, orderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'did' is not null or undefined
            assertParamExists('holderGatewayControllerAbort', 'did', did)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('holderGatewayControllerAbort', 'orderId', orderId)
            const localVarPath = `/api/abort`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (did != null) {
                localVarHeaderParameter['did'] = String(did);
            }

            if (orderId != null) {
                localVarHeaderParameter['order-id'] = String(orderId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get current state of identification.
         * @param {string} did A unique decentralized identifier connected to the current session.
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holderGatewayControllerDebugState: async (did: string, orderId: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'did' is not null or undefined
            assertParamExists('holderGatewayControllerDebugState', 'did', did)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('holderGatewayControllerDebugState', 'orderId', orderId)
            const localVarPath = `/api/identification/debug`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCrefotrustSystemId != null) {
                localVarHeaderParameter['x-crefotrust-system-id'] = String(xCrefotrustSystemId);
            }

            if (xCrefotrustApiKey != null) {
                localVarHeaderParameter['x-crefotrust-api-key'] = String(xCrefotrustApiKey);
            }

            if (did != null) {
                localVarHeaderParameter['did'] = String(did);
            }

            if (orderId != null) {
                localVarHeaderParameter['order-id'] = String(orderId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get current holder.
         * @param {string} [companyDid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holderGatewayControllerGetHolder: async (companyDid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/holder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyDid !== undefined) {
                localVarQueryParameter['companyDid'] = companyDid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns redirect information.
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holderGatewayControllerGetOrderAuthConfig: async (orderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('holderGatewayControllerGetOrderAuthConfig', 'orderId', orderId)
            const localVarPath = `/api/redirects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderId != null) {
                localVarHeaderParameter['order-id'] = String(orderId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get current state of identification.
         * @param {string} did A unique decentralized identifier connected to the current session.
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holderGatewayControllerGetState: async (did: string, orderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'did' is not null or undefined
            assertParamExists('holderGatewayControllerGetState', 'did', did)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('holderGatewayControllerGetState', 'orderId', orderId)
            const localVarPath = `/api/identification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (did != null) {
                localVarHeaderParameter['did'] = String(did);
            }

            if (orderId != null) {
                localVarHeaderParameter['order-id'] = String(orderId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the verifiable presentations of the JWTs of credentials.
         * @param {string} did A unique decentralized identifier connected to the current session.
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holderGatewayControllerGetVerifiablePresentations: async (did: string, orderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'did' is not null or undefined
            assertParamExists('holderGatewayControllerGetVerifiablePresentations', 'did', did)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('holderGatewayControllerGetVerifiablePresentations', 'orderId', orderId)
            const localVarPath = `/api/verifiablePresentations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (did != null) {
                localVarHeaderParameter['did'] = String(did);
            }

            if (orderId != null) {
                localVarHeaderParameter['order-id'] = String(orderId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the name of the verifier who created the order.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holderGatewayControllerGetVerifierByOrderId: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('holderGatewayControllerGetVerifierByOrderId', 'id', id)
            const localVarPath = `/api/orders/{id}/verifier`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Release credentials to verifier and get redirected to verifiers page.
         * @param {string} did A unique decentralized identifier connected to the current session.
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holderGatewayControllerReleaseCredentials: async (did: string, orderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'did' is not null or undefined
            assertParamExists('holderGatewayControllerReleaseCredentials', 'did', did)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('holderGatewayControllerReleaseCredentials', 'orderId', orderId)
            const localVarPath = `/api/releaseCredentials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (did != null) {
                localVarHeaderParameter['did'] = String(did);
            }

            if (orderId != null) {
                localVarHeaderParameter['order-id'] = String(orderId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Revoke a specific set of Credentials.
         * @param {string} did A unique decentralized identifier connected to the current session.
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holderGatewayControllerRevokePersonCredentials: async (did: string, orderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'did' is not null or undefined
            assertParamExists('holderGatewayControllerRevokePersonCredentials', 'did', did)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('holderGatewayControllerRevokePersonCredentials', 'orderId', orderId)
            const localVarPath = `/api/identification/revoke-credentials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (did != null) {
                localVarHeaderParameter['did'] = String(did);
            }

            if (orderId != null) {
                localVarHeaderParameter['order-id'] = String(orderId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set selected company for identification process.
         * @param {string} orderId 
         * @param {SelectedCompanyDto} selectedCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holderGatewayControllerUpdateSelectedCompany: async (orderId: string, selectedCompanyDto: SelectedCompanyDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('holderGatewayControllerUpdateSelectedCompany', 'orderId', orderId)
            // verify required parameter 'selectedCompanyDto' is not null or undefined
            assertParamExists('holderGatewayControllerUpdateSelectedCompany', 'selectedCompanyDto', selectedCompanyDto)
            const localVarPath = `/api/identification/{orderId}/selected-company`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(selectedCompanyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set selected ident-provider for identification process.
         * @param {string} orderId 
         * @param {SelectedIdentProviderDto} selectedIdentProviderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holderGatewayControllerUpdateSelectedIdentProvider: async (orderId: string, selectedIdentProviderDto: SelectedIdentProviderDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('holderGatewayControllerUpdateSelectedIdentProvider', 'orderId', orderId)
            // verify required parameter 'selectedIdentProviderDto' is not null or undefined
            assertParamExists('holderGatewayControllerUpdateSelectedIdentProvider', 'selectedIdentProviderDto', selectedIdentProviderDto)
            const localVarPath = `/api/identification/{orderId}/selected-ident-provider`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(selectedIdentProviderDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set selected issuer.
         * @param {string} orderId 
         * @param {SelectedIssuerDto} selectedIssuerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holderGatewayControllerUpdateSelectedIssuer: async (orderId: string, selectedIssuerDto: SelectedIssuerDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('holderGatewayControllerUpdateSelectedIssuer', 'orderId', orderId)
            // verify required parameter 'selectedIssuerDto' is not null or undefined
            assertParamExists('holderGatewayControllerUpdateSelectedIssuer', 'selectedIssuerDto', selectedIssuerDto)
            const localVarPath = `/api/identification/{orderId}/selected-issuer`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(selectedIssuerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set selected person for identification process.
         * @param {string} orderId 
         * @param {SelectedPersonDto} selectedPersonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holderGatewayControllerUpdateSelectedPerson: async (orderId: string, selectedPersonDto: SelectedPersonDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('holderGatewayControllerUpdateSelectedPerson', 'orderId', orderId)
            // verify required parameter 'selectedPersonDto' is not null or undefined
            assertParamExists('holderGatewayControllerUpdateSelectedPerson', 'selectedPersonDto', selectedPersonDto)
            const localVarPath = `/api/identification/{orderId}/selected-person`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(selectedPersonDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update identification data to process / trigger identification request.
         * @param {string} did A unique decentralized identifier connected to the current session.
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {ContextPayloadDto} contextPayloadDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holderGatewayControllerUpdateState: async (did: string, orderId: string, contextPayloadDto: ContextPayloadDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'did' is not null or undefined
            assertParamExists('holderGatewayControllerUpdateState', 'did', did)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('holderGatewayControllerUpdateState', 'orderId', orderId)
            // verify required parameter 'contextPayloadDto' is not null or undefined
            assertParamExists('holderGatewayControllerUpdateState', 'contextPayloadDto', contextPayloadDto)
            const localVarPath = `/api/identification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (did != null) {
                localVarHeaderParameter['did'] = String(did);
            }

            if (orderId != null) {
                localVarHeaderParameter['order-id'] = String(orderId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contextPayloadDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhooksGatewayControllerWebhookPersonVerificationFinalization: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/webhooks/holder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HolderApi - functional programming interface
 * @export
 */
export const HolderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HolderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Aborts an order.
         * @param {string} did A unique decentralized identifier connected to the current session.
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async holderGatewayControllerAbort(did: string, orderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.holderGatewayControllerAbort(did, orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get current state of identification.
         * @param {string} did A unique decentralized identifier connected to the current session.
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async holderGatewayControllerDebugState(did: string, orderId: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HolderState>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.holderGatewayControllerDebugState(did, orderId, xCrefotrustSystemId, xCrefotrustApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get current holder.
         * @param {string} [companyDid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async holderGatewayControllerGetHolder(companyDid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Holder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.holderGatewayControllerGetHolder(companyDid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns redirect information.
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async holderGatewayControllerGetOrderAuthConfig(orderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderAuthRedirects>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.holderGatewayControllerGetOrderAuthConfig(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get current state of identification.
         * @param {string} did A unique decentralized identifier connected to the current session.
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async holderGatewayControllerGetState(did: string, orderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HolderState>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.holderGatewayControllerGetState(did, orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the verifiable presentations of the JWTs of credentials.
         * @param {string} did A unique decentralized identifier connected to the current session.
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async holderGatewayControllerGetVerifiablePresentations(did: string, orderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.holderGatewayControllerGetVerifiablePresentations(did, orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the name of the verifier who created the order.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async holderGatewayControllerGetVerifierByOrderId(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.holderGatewayControllerGetVerifierByOrderId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Release credentials to verifier and get redirected to verifiers page.
         * @param {string} did A unique decentralized identifier connected to the current session.
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async holderGatewayControllerReleaseCredentials(did: string, orderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.holderGatewayControllerReleaseCredentials(did, orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Revoke a specific set of Credentials.
         * @param {string} did A unique decentralized identifier connected to the current session.
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async holderGatewayControllerRevokePersonCredentials(did: string, orderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Credential>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.holderGatewayControllerRevokePersonCredentials(did, orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set selected company for identification process.
         * @param {string} orderId 
         * @param {SelectedCompanyDto} selectedCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async holderGatewayControllerUpdateSelectedCompany(orderId: string, selectedCompanyDto: SelectedCompanyDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HolderState>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.holderGatewayControllerUpdateSelectedCompany(orderId, selectedCompanyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set selected ident-provider for identification process.
         * @param {string} orderId 
         * @param {SelectedIdentProviderDto} selectedIdentProviderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async holderGatewayControllerUpdateSelectedIdentProvider(orderId: string, selectedIdentProviderDto: SelectedIdentProviderDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HolderState>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.holderGatewayControllerUpdateSelectedIdentProvider(orderId, selectedIdentProviderDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set selected issuer.
         * @param {string} orderId 
         * @param {SelectedIssuerDto} selectedIssuerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async holderGatewayControllerUpdateSelectedIssuer(orderId: string, selectedIssuerDto: SelectedIssuerDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HolderState>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.holderGatewayControllerUpdateSelectedIssuer(orderId, selectedIssuerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set selected person for identification process.
         * @param {string} orderId 
         * @param {SelectedPersonDto} selectedPersonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async holderGatewayControllerUpdateSelectedPerson(orderId: string, selectedPersonDto: SelectedPersonDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HolderState>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.holderGatewayControllerUpdateSelectedPerson(orderId, selectedPersonDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update identification data to process / trigger identification request.
         * @param {string} did A unique decentralized identifier connected to the current session.
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {ContextPayloadDto} contextPayloadDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async holderGatewayControllerUpdateState(did: string, orderId: string, contextPayloadDto: ContextPayloadDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HolderState>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.holderGatewayControllerUpdateState(did, orderId, contextPayloadDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webhooksGatewayControllerWebhookPersonVerificationFinalization(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webhooksGatewayControllerWebhookPersonVerificationFinalization(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HolderApi - factory interface
 * @export
 */
export const HolderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HolderApiFp(configuration)
    return {
        /**
         * 
         * @summary Aborts an order.
         * @param {string} did A unique decentralized identifier connected to the current session.
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holderGatewayControllerAbort(did: string, orderId: string, options?: any): AxiosPromise<void> {
            return localVarFp.holderGatewayControllerAbort(did, orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get current state of identification.
         * @param {string} did A unique decentralized identifier connected to the current session.
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holderGatewayControllerDebugState(did: string, orderId: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: any): AxiosPromise<HolderState> {
            return localVarFp.holderGatewayControllerDebugState(did, orderId, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get current holder.
         * @param {string} [companyDid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holderGatewayControllerGetHolder(companyDid?: string, options?: any): AxiosPromise<Holder> {
            return localVarFp.holderGatewayControllerGetHolder(companyDid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns redirect information.
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holderGatewayControllerGetOrderAuthConfig(orderId: string, options?: any): AxiosPromise<OrderAuthRedirects> {
            return localVarFp.holderGatewayControllerGetOrderAuthConfig(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get current state of identification.
         * @param {string} did A unique decentralized identifier connected to the current session.
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holderGatewayControllerGetState(did: string, orderId: string, options?: any): AxiosPromise<HolderState> {
            return localVarFp.holderGatewayControllerGetState(did, orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the verifiable presentations of the JWTs of credentials.
         * @param {string} did A unique decentralized identifier connected to the current session.
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holderGatewayControllerGetVerifiablePresentations(did: string, orderId: string, options?: any): AxiosPromise<void> {
            return localVarFp.holderGatewayControllerGetVerifiablePresentations(did, orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the name of the verifier who created the order.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holderGatewayControllerGetVerifierByOrderId(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.holderGatewayControllerGetVerifierByOrderId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Release credentials to verifier and get redirected to verifiers page.
         * @param {string} did A unique decentralized identifier connected to the current session.
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holderGatewayControllerReleaseCredentials(did: string, orderId: string, options?: any): AxiosPromise<void> {
            return localVarFp.holderGatewayControllerReleaseCredentials(did, orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Revoke a specific set of Credentials.
         * @param {string} did A unique decentralized identifier connected to the current session.
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holderGatewayControllerRevokePersonCredentials(did: string, orderId: string, options?: any): AxiosPromise<Array<Credential>> {
            return localVarFp.holderGatewayControllerRevokePersonCredentials(did, orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set selected company for identification process.
         * @param {string} orderId 
         * @param {SelectedCompanyDto} selectedCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holderGatewayControllerUpdateSelectedCompany(orderId: string, selectedCompanyDto: SelectedCompanyDto, options?: any): AxiosPromise<HolderState> {
            return localVarFp.holderGatewayControllerUpdateSelectedCompany(orderId, selectedCompanyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set selected ident-provider for identification process.
         * @param {string} orderId 
         * @param {SelectedIdentProviderDto} selectedIdentProviderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holderGatewayControllerUpdateSelectedIdentProvider(orderId: string, selectedIdentProviderDto: SelectedIdentProviderDto, options?: any): AxiosPromise<HolderState> {
            return localVarFp.holderGatewayControllerUpdateSelectedIdentProvider(orderId, selectedIdentProviderDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set selected issuer.
         * @param {string} orderId 
         * @param {SelectedIssuerDto} selectedIssuerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holderGatewayControllerUpdateSelectedIssuer(orderId: string, selectedIssuerDto: SelectedIssuerDto, options?: any): AxiosPromise<HolderState> {
            return localVarFp.holderGatewayControllerUpdateSelectedIssuer(orderId, selectedIssuerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set selected person for identification process.
         * @param {string} orderId 
         * @param {SelectedPersonDto} selectedPersonDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holderGatewayControllerUpdateSelectedPerson(orderId: string, selectedPersonDto: SelectedPersonDto, options?: any): AxiosPromise<HolderState> {
            return localVarFp.holderGatewayControllerUpdateSelectedPerson(orderId, selectedPersonDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update identification data to process / trigger identification request.
         * @param {string} did A unique decentralized identifier connected to the current session.
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {ContextPayloadDto} contextPayloadDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holderGatewayControllerUpdateState(did: string, orderId: string, contextPayloadDto: ContextPayloadDto, options?: any): AxiosPromise<HolderState> {
            return localVarFp.holderGatewayControllerUpdateState(did, orderId, contextPayloadDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhooksGatewayControllerWebhookPersonVerificationFinalization(options?: any): AxiosPromise<void> {
            return localVarFp.webhooksGatewayControllerWebhookPersonVerificationFinalization(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HolderApi - object-oriented interface
 * @export
 * @class HolderApi
 * @extends {BaseAPI}
 */
export class HolderApi extends BaseAPI {
    /**
     * 
     * @summary Aborts an order.
     * @param {string} did A unique decentralized identifier connected to the current session.
     * @param {string} orderId A valid identifier, created by a verifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HolderApi
     */
    public holderGatewayControllerAbort(did: string, orderId: string, options?: AxiosRequestConfig) {
        return HolderApiFp(this.configuration).holderGatewayControllerAbort(did, orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get current state of identification.
     * @param {string} did A unique decentralized identifier connected to the current session.
     * @param {string} orderId A valid identifier, created by a verifier.
     * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
     * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HolderApi
     */
    public holderGatewayControllerDebugState(did: string, orderId: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig) {
        return HolderApiFp(this.configuration).holderGatewayControllerDebugState(did, orderId, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get current holder.
     * @param {string} [companyDid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HolderApi
     */
    public holderGatewayControllerGetHolder(companyDid?: string, options?: AxiosRequestConfig) {
        return HolderApiFp(this.configuration).holderGatewayControllerGetHolder(companyDid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns redirect information.
     * @param {string} orderId A valid identifier, created by a verifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HolderApi
     */
    public holderGatewayControllerGetOrderAuthConfig(orderId: string, options?: AxiosRequestConfig) {
        return HolderApiFp(this.configuration).holderGatewayControllerGetOrderAuthConfig(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get current state of identification.
     * @param {string} did A unique decentralized identifier connected to the current session.
     * @param {string} orderId A valid identifier, created by a verifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HolderApi
     */
    public holderGatewayControllerGetState(did: string, orderId: string, options?: AxiosRequestConfig) {
        return HolderApiFp(this.configuration).holderGatewayControllerGetState(did, orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the verifiable presentations of the JWTs of credentials.
     * @param {string} did A unique decentralized identifier connected to the current session.
     * @param {string} orderId A valid identifier, created by a verifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HolderApi
     */
    public holderGatewayControllerGetVerifiablePresentations(did: string, orderId: string, options?: AxiosRequestConfig) {
        return HolderApiFp(this.configuration).holderGatewayControllerGetVerifiablePresentations(did, orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the name of the verifier who created the order.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HolderApi
     */
    public holderGatewayControllerGetVerifierByOrderId(id: string, options?: AxiosRequestConfig) {
        return HolderApiFp(this.configuration).holderGatewayControllerGetVerifierByOrderId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Release credentials to verifier and get redirected to verifiers page.
     * @param {string} did A unique decentralized identifier connected to the current session.
     * @param {string} orderId A valid identifier, created by a verifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HolderApi
     */
    public holderGatewayControllerReleaseCredentials(did: string, orderId: string, options?: AxiosRequestConfig) {
        return HolderApiFp(this.configuration).holderGatewayControllerReleaseCredentials(did, orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Revoke a specific set of Credentials.
     * @param {string} did A unique decentralized identifier connected to the current session.
     * @param {string} orderId A valid identifier, created by a verifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HolderApi
     */
    public holderGatewayControllerRevokePersonCredentials(did: string, orderId: string, options?: AxiosRequestConfig) {
        return HolderApiFp(this.configuration).holderGatewayControllerRevokePersonCredentials(did, orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set selected company for identification process.
     * @param {string} orderId 
     * @param {SelectedCompanyDto} selectedCompanyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HolderApi
     */
    public holderGatewayControllerUpdateSelectedCompany(orderId: string, selectedCompanyDto: SelectedCompanyDto, options?: AxiosRequestConfig) {
        return HolderApiFp(this.configuration).holderGatewayControllerUpdateSelectedCompany(orderId, selectedCompanyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set selected ident-provider for identification process.
     * @param {string} orderId 
     * @param {SelectedIdentProviderDto} selectedIdentProviderDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HolderApi
     */
    public holderGatewayControllerUpdateSelectedIdentProvider(orderId: string, selectedIdentProviderDto: SelectedIdentProviderDto, options?: AxiosRequestConfig) {
        return HolderApiFp(this.configuration).holderGatewayControllerUpdateSelectedIdentProvider(orderId, selectedIdentProviderDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set selected issuer.
     * @param {string} orderId 
     * @param {SelectedIssuerDto} selectedIssuerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HolderApi
     */
    public holderGatewayControllerUpdateSelectedIssuer(orderId: string, selectedIssuerDto: SelectedIssuerDto, options?: AxiosRequestConfig) {
        return HolderApiFp(this.configuration).holderGatewayControllerUpdateSelectedIssuer(orderId, selectedIssuerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set selected person for identification process.
     * @param {string} orderId 
     * @param {SelectedPersonDto} selectedPersonDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HolderApi
     */
    public holderGatewayControllerUpdateSelectedPerson(orderId: string, selectedPersonDto: SelectedPersonDto, options?: AxiosRequestConfig) {
        return HolderApiFp(this.configuration).holderGatewayControllerUpdateSelectedPerson(orderId, selectedPersonDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update identification data to process / trigger identification request.
     * @param {string} did A unique decentralized identifier connected to the current session.
     * @param {string} orderId A valid identifier, created by a verifier.
     * @param {ContextPayloadDto} contextPayloadDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HolderApi
     */
    public holderGatewayControllerUpdateState(did: string, orderId: string, contextPayloadDto: ContextPayloadDto, options?: AxiosRequestConfig) {
        return HolderApiFp(this.configuration).holderGatewayControllerUpdateState(did, orderId, contextPayloadDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HolderApi
     */
    public webhooksGatewayControllerWebhookPersonVerificationFinalization(options?: AxiosRequestConfig) {
        return HolderApiFp(this.configuration).webhooksGatewayControllerWebhookPersonVerificationFinalization(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IdentProviderApi - axios parameter creator
 * @export
 */
export const IdentProviderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhooksGatewayController: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/webhooks/pin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IdentProviderApi - functional programming interface
 * @export
 */
export const IdentProviderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IdentProviderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webhooksGatewayController(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webhooksGatewayController(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IdentProviderApi - factory interface
 * @export
 */
export const IdentProviderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IdentProviderApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhooksGatewayController(options?: any): AxiosPromise<void> {
            return localVarFp.webhooksGatewayController(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IdentProviderApi - object-oriented interface
 * @export
 * @class IdentProviderApi
 * @extends {BaseAPI}
 */
export class IdentProviderApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdentProviderApi
     */
    public webhooksGatewayController(options?: AxiosRequestConfig) {
        return IdentProviderApiFp(this.configuration).webhooksGatewayController(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrderHistoryApi - axios parameter creator
 * @export
 */
export const OrderHistoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {AddOrderStateDto} addOrderStateDto 
         * @param {string} [xCrefotrustSystemId] 
         * @param {string} [xCrefotrustApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersStateControllerCreateOrderState: async (id: string, addOrderStateDto: AddOrderStateDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('ordersStateControllerCreateOrderState', 'id', id)
            // verify required parameter 'addOrderStateDto' is not null or undefined
            assertParamExists('ordersStateControllerCreateOrderState', 'addOrderStateDto', addOrderStateDto)
            const localVarPath = `/api/order-states/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCrefotrustSystemId != null) {
                localVarHeaderParameter['x-crefotrust-system-id'] = String(xCrefotrustSystemId);
            }

            if (xCrefotrustApiKey != null) {
                localVarHeaderParameter['x-crefotrust-api-key'] = String(xCrefotrustApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addOrderStateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersStateControllerGetOrderHistory: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('ordersStateControllerGetOrderHistory', 'id', id)
            const localVarPath = `/api/order-states/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} did 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersStateControllerGetUserHistory: async (did: string, orderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'did' is not null or undefined
            assertParamExists('ordersStateControllerGetUserHistory', 'did', did)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('ordersStateControllerGetUserHistory', 'orderId', orderId)
            const localVarPath = `/api/order-states`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (did !== undefined) {
                localVarQueryParameter['did'] = did;
            }

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderHistoryApi - functional programming interface
 * @export
 */
export const OrderHistoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderHistoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {AddOrderStateDto} addOrderStateDto 
         * @param {string} [xCrefotrustSystemId] 
         * @param {string} [xCrefotrustApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersStateControllerCreateOrderState(id: string, addOrderStateDto: AddOrderStateDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderState>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersStateControllerCreateOrderState(id, addOrderStateDto, xCrefotrustSystemId, xCrefotrustApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersStateControllerGetOrderHistory(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderState>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersStateControllerGetOrderHistory(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} did 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersStateControllerGetUserHistory(did: string, orderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderState>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersStateControllerGetUserHistory(did, orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderHistoryApi - factory interface
 * @export
 */
export const OrderHistoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderHistoryApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {AddOrderStateDto} addOrderStateDto 
         * @param {string} [xCrefotrustSystemId] 
         * @param {string} [xCrefotrustApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersStateControllerCreateOrderState(id: string, addOrderStateDto: AddOrderStateDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: any): AxiosPromise<OrderState> {
            return localVarFp.ordersStateControllerCreateOrderState(id, addOrderStateDto, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersStateControllerGetOrderHistory(id: string, options?: any): AxiosPromise<Array<OrderState>> {
            return localVarFp.ordersStateControllerGetOrderHistory(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} did 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersStateControllerGetUserHistory(did: string, orderId: string, options?: any): AxiosPromise<Array<OrderState>> {
            return localVarFp.ordersStateControllerGetUserHistory(did, orderId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderHistoryApi - object-oriented interface
 * @export
 * @class OrderHistoryApi
 * @extends {BaseAPI}
 */
export class OrderHistoryApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {AddOrderStateDto} addOrderStateDto 
     * @param {string} [xCrefotrustSystemId] 
     * @param {string} [xCrefotrustApiKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderHistoryApi
     */
    public ordersStateControllerCreateOrderState(id: string, addOrderStateDto: AddOrderStateDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig) {
        return OrderHistoryApiFp(this.configuration).ordersStateControllerCreateOrderState(id, addOrderStateDto, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderHistoryApi
     */
    public ordersStateControllerGetOrderHistory(id: string, options?: AxiosRequestConfig) {
        return OrderHistoryApiFp(this.configuration).ordersStateControllerGetOrderHistory(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} did 
     * @param {string} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderHistoryApi
     */
    public ordersStateControllerGetUserHistory(did: string, orderId: string, options?: AxiosRequestConfig) {
        return OrderHistoryApiFp(this.configuration).ordersStateControllerGetUserHistory(did, orderId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PersonApi - axios parameter creator
 * @export
 */
export const PersonApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns all credentials for a given person which belongs to a company.
         * @param {string} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personControllerGetPersonCompanyCredentials: async (personId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('personControllerGetPersonCompanyCredentials', 'personId', personId)
            const localVarPath = `/api/person/{personId}/company-credentials`
                .replace(`{${"personId"}}`, encodeURIComponent(String(personId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PersonApi - functional programming interface
 * @export
 */
export const PersonApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PersonApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns all credentials for a given person which belongs to a company.
         * @param {string} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personControllerGetPersonCompanyCredentials(personId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PersonCompanyCredentialsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personControllerGetPersonCompanyCredentials(personId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PersonApi - factory interface
 * @export
 */
export const PersonApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PersonApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns all credentials for a given person which belongs to a company.
         * @param {string} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personControllerGetPersonCompanyCredentials(personId: string, options?: any): AxiosPromise<Array<PersonCompanyCredentialsDto>> {
            return localVarFp.personControllerGetPersonCompanyCredentials(personId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PersonApi - object-oriented interface
 * @export
 * @class PersonApi
 * @extends {BaseAPI}
 */
export class PersonApi extends BaseAPI {
    /**
     * 
     * @summary Returns all credentials for a given person which belongs to a company.
     * @param {string} personId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonApi
     */
    public personControllerGetPersonCompanyCredentials(personId: string, options?: AxiosRequestConfig) {
        return PersonApiFp(this.configuration).personControllerGetPersonCompanyCredentials(personId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PostRegistrationApi - axios parameter creator
 * @export
 */
export const PostRegistrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary update post registration redirect url
         * @param {CreatePostRegistrationDto} createPostRegistrationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRegistrationControllerSetPostRegistration: async (createPostRegistrationDto: CreatePostRegistrationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPostRegistrationDto' is not null or undefined
            assertParamExists('postRegistrationControllerSetPostRegistration', 'createPostRegistrationDto', createPostRegistrationDto)
            const localVarPath = `/api/post-registration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPostRegistrationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PostRegistrationApi - functional programming interface
 * @export
 */
export const PostRegistrationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PostRegistrationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary update post registration redirect url
         * @param {CreatePostRegistrationDto} createPostRegistrationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRegistrationControllerSetPostRegistration(createPostRegistrationDto: CreatePostRegistrationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostRegistration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRegistrationControllerSetPostRegistration(createPostRegistrationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PostRegistrationApi - factory interface
 * @export
 */
export const PostRegistrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PostRegistrationApiFp(configuration)
    return {
        /**
         * 
         * @summary update post registration redirect url
         * @param {CreatePostRegistrationDto} createPostRegistrationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRegistrationControllerSetPostRegistration(createPostRegistrationDto: CreatePostRegistrationDto, options?: any): AxiosPromise<PostRegistration> {
            return localVarFp.postRegistrationControllerSetPostRegistration(createPostRegistrationDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PostRegistrationApi - object-oriented interface
 * @export
 * @class PostRegistrationApi
 * @extends {BaseAPI}
 */
export class PostRegistrationApi extends BaseAPI {
    /**
     * 
     * @summary update post registration redirect url
     * @param {CreatePostRegistrationDto} createPostRegistrationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostRegistrationApi
     */
    public postRegistrationControllerSetPostRegistration(createPostRegistrationDto: CreatePostRegistrationDto, options?: AxiosRequestConfig) {
        return PostRegistrationApiFp(this.configuration).postRegistrationControllerSetPostRegistration(createPostRegistrationDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RequestsApi - axios parameter creator
 * @export
 */
export const RequestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {UpdateRequestDto} updateRequestDto 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestsControllerUpdateRequest: async (id: string, updateRequestDto: UpdateRequestDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('requestsControllerUpdateRequest', 'id', id)
            // verify required parameter 'updateRequestDto' is not null or undefined
            assertParamExists('requestsControllerUpdateRequest', 'updateRequestDto', updateRequestDto)
            const localVarPath = `/api/requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCrefotrustSystemId != null) {
                localVarHeaderParameter['x-crefotrust-system-id'] = String(xCrefotrustSystemId);
            }

            if (xCrefotrustApiKey != null) {
                localVarHeaderParameter['x-crefotrust-api-key'] = String(xCrefotrustApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RequestsApi - functional programming interface
 * @export
 */
export const RequestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RequestsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {UpdateRequestDto} updateRequestDto 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestsControllerUpdateRequest(id: string, updateRequestDto: UpdateRequestDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RequestsControllerUpdateRequest200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestsControllerUpdateRequest(id, updateRequestDto, xCrefotrustSystemId, xCrefotrustApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RequestsApi - factory interface
 * @export
 */
export const RequestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RequestsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {UpdateRequestDto} updateRequestDto 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestsControllerUpdateRequest(id: string, updateRequestDto: UpdateRequestDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: any): AxiosPromise<RequestsControllerUpdateRequest200Response> {
            return localVarFp.requestsControllerUpdateRequest(id, updateRequestDto, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RequestsApi - object-oriented interface
 * @export
 * @class RequestsApi
 * @extends {BaseAPI}
 */
export class RequestsApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {UpdateRequestDto} updateRequestDto 
     * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
     * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestsApi
     */
    public requestsControllerUpdateRequest(id: string, updateRequestDto: UpdateRequestDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig) {
        return RequestsApiFp(this.configuration).requestsControllerUpdateRequest(id, updateRequestDto, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SSOUserManagementApi - axios parameter creator
 * @export
 */
export const SSOUserManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Getting role assignments for crid in the SSO User Management API
         * @param {any} crid 
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssoUserManagementController: async (crid: any, orderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'crid' is not null or undefined
            assertParamExists('ssoUserManagementController', 'crid', crid)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('ssoUserManagementController', 'orderId', orderId)
            const localVarPath = `/api/user-management/role-assignments/{crid}`
                .replace(`{${"crid"}}`, encodeURIComponent(String(crid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderId != null) {
                localVarHeaderParameter['order-id'] = String(orderId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a User from the SSO User Management API
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssoUserManagementControllerIsSameCrefoId: async (orderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('ssoUserManagementControllerIsSameCrefoId', 'orderId', orderId)
            const localVarPath = `/api/user-management/person/match`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderId != null) {
                localVarHeaderParameter['order-id'] = String(orderId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a persons unverifiedCrefoNummer in the SSO User Management API
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssoUserManagementControllerUpdateUnverifiedCrefoId: async (orderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('ssoUserManagementControllerUpdateUnverifiedCrefoId', 'orderId', orderId)
            const localVarPath = `/api/user-management/person/crefo-id`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderId != null) {
                localVarHeaderParameter['order-id'] = String(orderId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhooksGatewayController: async (orderId: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('webhooksGatewayController', 'orderId', orderId)
            const localVarPath = `/api/webhooks/user-management/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SSOUserManagementApi - functional programming interface
 * @export
 */
export const SSOUserManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SSOUserManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Getting role assignments for crid in the SSO User Management API
         * @param {any} crid 
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ssoUserManagementController(crid: any, orderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ssoUserManagementController(crid, orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a User from the SSO User Management API
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ssoUserManagementControllerIsSameCrefoId(orderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IsSameCrefoIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ssoUserManagementControllerIsSameCrefoId(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a persons unverifiedCrefoNummer in the SSO User Management API
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ssoUserManagementControllerUpdateUnverifiedCrefoId(orderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ssoUserManagementControllerUpdateUnverifiedCrefoId(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webhooksGatewayController(orderId: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webhooksGatewayController(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SSOUserManagementApi - factory interface
 * @export
 */
export const SSOUserManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SSOUserManagementApiFp(configuration)
    return {
        /**
         * 
         * @summary Getting role assignments for crid in the SSO User Management API
         * @param {any} crid 
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssoUserManagementController(crid: any, orderId: string, options?: any): AxiosPromise<void> {
            return localVarFp.ssoUserManagementController(crid, orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a User from the SSO User Management API
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssoUserManagementControllerIsSameCrefoId(orderId: string, options?: any): AxiosPromise<IsSameCrefoIdResponse> {
            return localVarFp.ssoUserManagementControllerIsSameCrefoId(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a persons unverifiedCrefoNummer in the SSO User Management API
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssoUserManagementControllerUpdateUnverifiedCrefoId(orderId: string, options?: any): AxiosPromise<void> {
            return localVarFp.ssoUserManagementControllerUpdateUnverifiedCrefoId(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhooksGatewayController(orderId: any, options?: any): AxiosPromise<void> {
            return localVarFp.webhooksGatewayController(orderId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SSOUserManagementApi - object-oriented interface
 * @export
 * @class SSOUserManagementApi
 * @extends {BaseAPI}
 */
export class SSOUserManagementApi extends BaseAPI {
    /**
     * 
     * @summary Getting role assignments for crid in the SSO User Management API
     * @param {any} crid 
     * @param {string} orderId A valid identifier, created by a verifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SSOUserManagementApi
     */
    public ssoUserManagementController(crid: any, orderId: string, options?: AxiosRequestConfig) {
        return SSOUserManagementApiFp(this.configuration).ssoUserManagementController(crid, orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a User from the SSO User Management API
     * @param {string} orderId A valid identifier, created by a verifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SSOUserManagementApi
     */
    public ssoUserManagementControllerIsSameCrefoId(orderId: string, options?: AxiosRequestConfig) {
        return SSOUserManagementApiFp(this.configuration).ssoUserManagementControllerIsSameCrefoId(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a persons unverifiedCrefoNummer in the SSO User Management API
     * @param {string} orderId A valid identifier, created by a verifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SSOUserManagementApi
     */
    public ssoUserManagementControllerUpdateUnverifiedCrefoId(orderId: string, options?: AxiosRequestConfig) {
        return SSOUserManagementApiFp(this.configuration).ssoUserManagementControllerUpdateUnverifiedCrefoId(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SSOUserManagementApi
     */
    public webhooksGatewayController(orderId: any, options?: AxiosRequestConfig) {
        return SSOUserManagementApiFp(this.configuration).webhooksGatewayController(orderId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UseCasesApi - axios parameter creator
 * @export
 */
export const UseCasesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateUseCaseDto} createUseCaseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        useCaseControllerCreateUseCase: async (createUseCaseDto: CreateUseCaseDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUseCaseDto' is not null or undefined
            assertParamExists('useCaseControllerCreateUseCase', 'createUseCaseDto', createUseCaseDto)
            const localVarPath = `/api/use-cases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUseCaseDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        useCaseControllerGetUseCase: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('useCaseControllerGetUseCase', 'name', name)
            const localVarPath = `/api/use-cases/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} start 
         * @param {string} end 
         * @param {string} [order] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        useCaseControllerGetUseCases: async (start: string, end: string, order?: string, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'start' is not null or undefined
            assertParamExists('useCaseControllerGetUseCases', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('useCaseControllerGetUseCases', 'end', end)
            const localVarPath = `/api/use-cases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {UpdateUseCaseDto} updateUseCaseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        useCaseControllerUpdateUseCase: async (name: string, updateUseCaseDto: UpdateUseCaseDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('useCaseControllerUpdateUseCase', 'name', name)
            // verify required parameter 'updateUseCaseDto' is not null or undefined
            assertParamExists('useCaseControllerUpdateUseCase', 'updateUseCaseDto', updateUseCaseDto)
            const localVarPath = `/api/use-cases/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUseCaseDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {UpdateUseCaseContentDto} updateUseCaseContentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        useCaseControllerUpdateUseCaseContent: async (name: string, updateUseCaseContentDto: UpdateUseCaseContentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('useCaseControllerUpdateUseCaseContent', 'name', name)
            // verify required parameter 'updateUseCaseContentDto' is not null or undefined
            assertParamExists('useCaseControllerUpdateUseCaseContent', 'updateUseCaseContentDto', updateUseCaseContentDto)
            const localVarPath = `/api/use-cases/{name}/content`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUseCaseContentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UseCasesApi - functional programming interface
 * @export
 */
export const UseCasesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UseCasesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateUseCaseDto} createUseCaseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async useCaseControllerCreateUseCase(createUseCaseDto: CreateUseCaseDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UseCase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.useCaseControllerCreateUseCase(createUseCaseDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async useCaseControllerGetUseCase(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UseCase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.useCaseControllerGetUseCase(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} start 
         * @param {string} end 
         * @param {string} [order] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async useCaseControllerGetUseCases(start: string, end: string, order?: string, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UseCase>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.useCaseControllerGetUseCases(start, end, order, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {UpdateUseCaseDto} updateUseCaseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async useCaseControllerUpdateUseCase(name: string, updateUseCaseDto: UpdateUseCaseDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UseCase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.useCaseControllerUpdateUseCase(name, updateUseCaseDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {UpdateUseCaseContentDto} updateUseCaseContentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async useCaseControllerUpdateUseCaseContent(name: string, updateUseCaseContentDto: UpdateUseCaseContentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UseCase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.useCaseControllerUpdateUseCaseContent(name, updateUseCaseContentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UseCasesApi - factory interface
 * @export
 */
export const UseCasesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UseCasesApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateUseCaseDto} createUseCaseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        useCaseControllerCreateUseCase(createUseCaseDto: CreateUseCaseDto, options?: any): AxiosPromise<UseCase> {
            return localVarFp.useCaseControllerCreateUseCase(createUseCaseDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        useCaseControllerGetUseCase(name: string, options?: any): AxiosPromise<UseCase> {
            return localVarFp.useCaseControllerGetUseCase(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} start 
         * @param {string} end 
         * @param {string} [order] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        useCaseControllerGetUseCases(start: string, end: string, order?: string, sort?: string, options?: any): AxiosPromise<Array<UseCase>> {
            return localVarFp.useCaseControllerGetUseCases(start, end, order, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {UpdateUseCaseDto} updateUseCaseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        useCaseControllerUpdateUseCase(name: string, updateUseCaseDto: UpdateUseCaseDto, options?: any): AxiosPromise<UseCase> {
            return localVarFp.useCaseControllerUpdateUseCase(name, updateUseCaseDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {UpdateUseCaseContentDto} updateUseCaseContentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        useCaseControllerUpdateUseCaseContent(name: string, updateUseCaseContentDto: UpdateUseCaseContentDto, options?: any): AxiosPromise<UseCase> {
            return localVarFp.useCaseControllerUpdateUseCaseContent(name, updateUseCaseContentDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UseCasesApi - object-oriented interface
 * @export
 * @class UseCasesApi
 * @extends {BaseAPI}
 */
export class UseCasesApi extends BaseAPI {
    /**
     * 
     * @param {CreateUseCaseDto} createUseCaseDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UseCasesApi
     */
    public useCaseControllerCreateUseCase(createUseCaseDto: CreateUseCaseDto, options?: AxiosRequestConfig) {
        return UseCasesApiFp(this.configuration).useCaseControllerCreateUseCase(createUseCaseDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UseCasesApi
     */
    public useCaseControllerGetUseCase(name: string, options?: AxiosRequestConfig) {
        return UseCasesApiFp(this.configuration).useCaseControllerGetUseCase(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} start 
     * @param {string} end 
     * @param {string} [order] 
     * @param {string} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UseCasesApi
     */
    public useCaseControllerGetUseCases(start: string, end: string, order?: string, sort?: string, options?: AxiosRequestConfig) {
        return UseCasesApiFp(this.configuration).useCaseControllerGetUseCases(start, end, order, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {UpdateUseCaseDto} updateUseCaseDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UseCasesApi
     */
    public useCaseControllerUpdateUseCase(name: string, updateUseCaseDto: UpdateUseCaseDto, options?: AxiosRequestConfig) {
        return UseCasesApiFp(this.configuration).useCaseControllerUpdateUseCase(name, updateUseCaseDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {UpdateUseCaseContentDto} updateUseCaseContentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UseCasesApi
     */
    public useCaseControllerUpdateUseCaseContent(name: string, updateUseCaseContentDto: UpdateUseCaseContentDto, options?: AxiosRequestConfig) {
        return UseCasesApiFp(this.configuration).useCaseControllerUpdateUseCaseContent(name, updateUseCaseContentDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VerifierApi - axios parameter creator
 * @export
 */
export const VerifierApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates a new order.
         * @param {CreateOrderDto} createOrderDto 
         * @param {string} [xCrefotrustKey] API key for verifiers
         * @param {string} [xVerifierId] Verifier id for verifiers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerCreate: async (createOrderDto: CreateOrderDto, xCrefotrustKey?: string, xVerifierId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrderDto' is not null or undefined
            assertParamExists('orderControllerCreate', 'createOrderDto', createOrderDto)
            const localVarPath = `/api/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCrefotrustKey != null) {
                localVarHeaderParameter['x-crefotrust-key'] = String(xCrefotrustKey);
            }

            if (xVerifierId != null) {
                localVarHeaderParameter['x-verifier-id'] = String(xVerifierId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrderDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all orders based on filter.
         * @param {string} [id] 
         * @param {boolean} [completed] 
         * @param {string} [forPersonDid] 
         * @param {string} [xCrefotrustSystemId] 
         * @param {string} [xCrefotrustApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerFind: async (id?: string, completed?: boolean, forPersonDid?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (completed !== undefined) {
                localVarQueryParameter['completed'] = completed;
            }

            if (forPersonDid !== undefined) {
                localVarQueryParameter['forPerson.did'] = forPersonDid;
            }

            if (xCrefotrustSystemId != null) {
                localVarHeaderParameter['x-crefotrust-system-id'] = String(xCrefotrustSystemId);
            }

            if (xCrefotrustApiKey != null) {
                localVarHeaderParameter['x-crefotrust-api-key'] = String(xCrefotrustApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the an order based on the provided id.
         * @param {string} id 
         * @param {string} [xCrefotrustKey] API key for verifiers
         * @param {string} [xVerifierId] Verifier id for verifiers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerGetById: async (id: string, xCrefotrustKey?: string, xVerifierId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderControllerGetById', 'id', id)
            const localVarPath = `/api/orders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCrefotrustKey != null) {
                localVarHeaderParameter['x-crefotrust-key'] = String(xCrefotrustKey);
            }

            if (xVerifierId != null) {
                localVarHeaderParameter['x-verifier-id'] = String(xVerifierId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateVerifierDto} createVerifierDto 
         * @param {string} [xCrefotrustSystemId] 
         * @param {string} [xCrefotrustApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifiersControllerCreateVerifier: async (createVerifierDto: CreateVerifierDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createVerifierDto' is not null or undefined
            assertParamExists('verifiersControllerCreateVerifier', 'createVerifierDto', createVerifierDto)
            const localVarPath = `/api/verifiers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCrefotrustSystemId != null) {
                localVarHeaderParameter['x-crefotrust-system-id'] = String(xCrefotrustSystemId);
            }

            if (xCrefotrustApiKey != null) {
                localVarHeaderParameter['x-crefotrust-api-key'] = String(xCrefotrustApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createVerifierDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xCrefotrustSystemId] 
         * @param {string} [xCrefotrustApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifiersControllerGetVerifierById: async (id: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('verifiersControllerGetVerifierById', 'id', id)
            const localVarPath = `/api/verifiers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCrefotrustSystemId != null) {
                localVarHeaderParameter['x-crefotrust-system-id'] = String(xCrefotrustSystemId);
            }

            if (xCrefotrustApiKey != null) {
                localVarHeaderParameter['x-crefotrust-api-key'] = String(xCrefotrustApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {string} [name] 
         * @param {string} [verifierId] 
         * @param {string} [order] 
         * @param {string} [sort] 
         * @param {string} [xCrefotrustSystemId] 
         * @param {string} [xCrefotrustApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifiersControllerGetVerifiers: async (start?: string, end?: string, name?: string, verifierId?: string, order?: string, sort?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/verifiers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (verifierId !== undefined) {
                localVarQueryParameter['verifierId'] = verifierId;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (xCrefotrustSystemId != null) {
                localVarHeaderParameter['x-crefotrust-system-id'] = String(xCrefotrustSystemId);
            }

            if (xCrefotrustApiKey != null) {
                localVarHeaderParameter['x-crefotrust-api-key'] = String(xCrefotrustApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IsValidVerifierDto} isValidVerifierDto 
         * @param {string} [xCrefotrustSystemId] 
         * @param {string} [xCrefotrustApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifiersControllerIsValidVerifier: async (isValidVerifierDto: IsValidVerifierDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'isValidVerifierDto' is not null or undefined
            assertParamExists('verifiersControllerIsValidVerifier', 'isValidVerifierDto', isValidVerifierDto)
            const localVarPath = `/api/verifiers/isValid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCrefotrustSystemId != null) {
                localVarHeaderParameter['x-crefotrust-system-id'] = String(xCrefotrustSystemId);
            }

            if (xCrefotrustApiKey != null) {
                localVarHeaderParameter['x-crefotrust-api-key'] = String(xCrefotrustApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(isValidVerifierDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VerifierApi - functional programming interface
 * @export
 */
export const VerifierApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VerifierApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates a new order.
         * @param {CreateOrderDto} createOrderDto 
         * @param {string} [xCrefotrustKey] API key for verifiers
         * @param {string} [xVerifierId] Verifier id for verifiers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerCreate(createOrderDto: CreateOrderDto, xCrefotrustKey?: string, xVerifierId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerCreate(createOrderDto, xCrefotrustKey, xVerifierId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns all orders based on filter.
         * @param {string} [id] 
         * @param {boolean} [completed] 
         * @param {string} [forPersonDid] 
         * @param {string} [xCrefotrustSystemId] 
         * @param {string} [xCrefotrustApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerFind(id?: string, completed?: boolean, forPersonDid?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExtOrderResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerFind(id, completed, forPersonDid, xCrefotrustSystemId, xCrefotrustApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the an order based on the provided id.
         * @param {string} id 
         * @param {string} [xCrefotrustKey] API key for verifiers
         * @param {string} [xVerifierId] Verifier id for verifiers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerGetById(id: string, xCrefotrustKey?: string, xVerifierId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExtOrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerGetById(id, xCrefotrustKey, xVerifierId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateVerifierDto} createVerifierDto 
         * @param {string} [xCrefotrustSystemId] 
         * @param {string} [xCrefotrustApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifiersControllerCreateVerifier(createVerifierDto: CreateVerifierDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Verifier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifiersControllerCreateVerifier(createVerifierDto, xCrefotrustSystemId, xCrefotrustApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xCrefotrustSystemId] 
         * @param {string} [xCrefotrustApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifiersControllerGetVerifierById(id: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Verifier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifiersControllerGetVerifierById(id, xCrefotrustSystemId, xCrefotrustApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {string} [name] 
         * @param {string} [verifierId] 
         * @param {string} [order] 
         * @param {string} [sort] 
         * @param {string} [xCrefotrustSystemId] 
         * @param {string} [xCrefotrustApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifiersControllerGetVerifiers(start?: string, end?: string, name?: string, verifierId?: string, order?: string, sort?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifierResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifiersControllerGetVerifiers(start, end, name, verifierId, order, sort, xCrefotrustSystemId, xCrefotrustApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IsValidVerifierDto} isValidVerifierDto 
         * @param {string} [xCrefotrustSystemId] 
         * @param {string} [xCrefotrustApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifiersControllerIsValidVerifier(isValidVerifierDto: IsValidVerifierDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifiersControllerIsValidVerifier(isValidVerifierDto, xCrefotrustSystemId, xCrefotrustApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VerifierApi - factory interface
 * @export
 */
export const VerifierApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VerifierApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates a new order.
         * @param {CreateOrderDto} createOrderDto 
         * @param {string} [xCrefotrustKey] API key for verifiers
         * @param {string} [xVerifierId] Verifier id for verifiers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerCreate(createOrderDto: CreateOrderDto, xCrefotrustKey?: string, xVerifierId?: string, options?: any): AxiosPromise<OrderCreationResponse> {
            return localVarFp.orderControllerCreate(createOrderDto, xCrefotrustKey, xVerifierId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all orders based on filter.
         * @param {string} [id] 
         * @param {boolean} [completed] 
         * @param {string} [forPersonDid] 
         * @param {string} [xCrefotrustSystemId] 
         * @param {string} [xCrefotrustApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerFind(id?: string, completed?: boolean, forPersonDid?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: any): AxiosPromise<Array<ExtOrderResponse>> {
            return localVarFp.orderControllerFind(id, completed, forPersonDid, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the an order based on the provided id.
         * @param {string} id 
         * @param {string} [xCrefotrustKey] API key for verifiers
         * @param {string} [xVerifierId] Verifier id for verifiers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerGetById(id: string, xCrefotrustKey?: string, xVerifierId?: string, options?: any): AxiosPromise<ExtOrderResponse> {
            return localVarFp.orderControllerGetById(id, xCrefotrustKey, xVerifierId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateVerifierDto} createVerifierDto 
         * @param {string} [xCrefotrustSystemId] 
         * @param {string} [xCrefotrustApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifiersControllerCreateVerifier(createVerifierDto: CreateVerifierDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: any): AxiosPromise<Verifier> {
            return localVarFp.verifiersControllerCreateVerifier(createVerifierDto, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xCrefotrustSystemId] 
         * @param {string} [xCrefotrustApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifiersControllerGetVerifierById(id: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: any): AxiosPromise<Verifier> {
            return localVarFp.verifiersControllerGetVerifierById(id, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {string} [name] 
         * @param {string} [verifierId] 
         * @param {string} [order] 
         * @param {string} [sort] 
         * @param {string} [xCrefotrustSystemId] 
         * @param {string} [xCrefotrustApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifiersControllerGetVerifiers(start?: string, end?: string, name?: string, verifierId?: string, order?: string, sort?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: any): AxiosPromise<VerifierResponse> {
            return localVarFp.verifiersControllerGetVerifiers(start, end, name, verifierId, order, sort, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IsValidVerifierDto} isValidVerifierDto 
         * @param {string} [xCrefotrustSystemId] 
         * @param {string} [xCrefotrustApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifiersControllerIsValidVerifier(isValidVerifierDto: IsValidVerifierDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: any): AxiosPromise<void> {
            return localVarFp.verifiersControllerIsValidVerifier(isValidVerifierDto, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VerifierApi - object-oriented interface
 * @export
 * @class VerifierApi
 * @extends {BaseAPI}
 */
export class VerifierApi extends BaseAPI {
    /**
     * 
     * @summary Creates a new order.
     * @param {CreateOrderDto} createOrderDto 
     * @param {string} [xCrefotrustKey] API key for verifiers
     * @param {string} [xVerifierId] Verifier id for verifiers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerifierApi
     */
    public orderControllerCreate(createOrderDto: CreateOrderDto, xCrefotrustKey?: string, xVerifierId?: string, options?: AxiosRequestConfig) {
        return VerifierApiFp(this.configuration).orderControllerCreate(createOrderDto, xCrefotrustKey, xVerifierId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all orders based on filter.
     * @param {string} [id] 
     * @param {boolean} [completed] 
     * @param {string} [forPersonDid] 
     * @param {string} [xCrefotrustSystemId] 
     * @param {string} [xCrefotrustApiKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerifierApi
     */
    public orderControllerFind(id?: string, completed?: boolean, forPersonDid?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig) {
        return VerifierApiFp(this.configuration).orderControllerFind(id, completed, forPersonDid, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the an order based on the provided id.
     * @param {string} id 
     * @param {string} [xCrefotrustKey] API key for verifiers
     * @param {string} [xVerifierId] Verifier id for verifiers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerifierApi
     */
    public orderControllerGetById(id: string, xCrefotrustKey?: string, xVerifierId?: string, options?: AxiosRequestConfig) {
        return VerifierApiFp(this.configuration).orderControllerGetById(id, xCrefotrustKey, xVerifierId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateVerifierDto} createVerifierDto 
     * @param {string} [xCrefotrustSystemId] 
     * @param {string} [xCrefotrustApiKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerifierApi
     */
    public verifiersControllerCreateVerifier(createVerifierDto: CreateVerifierDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig) {
        return VerifierApiFp(this.configuration).verifiersControllerCreateVerifier(createVerifierDto, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [xCrefotrustSystemId] 
     * @param {string} [xCrefotrustApiKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerifierApi
     */
    public verifiersControllerGetVerifierById(id: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig) {
        return VerifierApiFp(this.configuration).verifiersControllerGetVerifierById(id, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [start] 
     * @param {string} [end] 
     * @param {string} [name] 
     * @param {string} [verifierId] 
     * @param {string} [order] 
     * @param {string} [sort] 
     * @param {string} [xCrefotrustSystemId] 
     * @param {string} [xCrefotrustApiKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerifierApi
     */
    public verifiersControllerGetVerifiers(start?: string, end?: string, name?: string, verifierId?: string, order?: string, sort?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig) {
        return VerifierApiFp(this.configuration).verifiersControllerGetVerifiers(start, end, name, verifierId, order, sort, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IsValidVerifierDto} isValidVerifierDto 
     * @param {string} [xCrefotrustSystemId] 
     * @param {string} [xCrefotrustApiKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerifierApi
     */
    public verifiersControllerIsValidVerifier(isValidVerifierDto: IsValidVerifierDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig) {
        return VerifierApiFp(this.configuration).verifiersControllerIsValidVerifier(isValidVerifierDto, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WebhooksApi - axios parameter creator
 * @export
 */
export const WebhooksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhooksGatewayController: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/webhooks/pin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhooksGatewayControllerWebhookPersonVerificationFinalization: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/webhooks/holder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhooksGatewayController_1: async (orderId: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('webhooksGatewayController_1', 'orderId', orderId)
            const localVarPath = `/api/webhooks/user-management/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebhooksApi - functional programming interface
 * @export
 */
export const WebhooksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebhooksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webhooksGatewayController(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webhooksGatewayController(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webhooksGatewayControllerWebhookPersonVerificationFinalization(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webhooksGatewayControllerWebhookPersonVerificationFinalization(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webhooksGatewayController_1(orderId: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webhooksGatewayController_1(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WebhooksApi - factory interface
 * @export
 */
export const WebhooksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebhooksApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhooksGatewayController(options?: any): AxiosPromise<void> {
            return localVarFp.webhooksGatewayController(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhooksGatewayControllerWebhookPersonVerificationFinalization(options?: any): AxiosPromise<void> {
            return localVarFp.webhooksGatewayControllerWebhookPersonVerificationFinalization(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhooksGatewayController_1(orderId: any, options?: any): AxiosPromise<void> {
            return localVarFp.webhooksGatewayController_1(orderId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WebhooksApi - object-oriented interface
 * @export
 * @class WebhooksApi
 * @extends {BaseAPI}
 */
export class WebhooksApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public webhooksGatewayController(options?: AxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).webhooksGatewayController(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public webhooksGatewayControllerWebhookPersonVerificationFinalization(options?: AxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).webhooksGatewayControllerWebhookPersonVerificationFinalization(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public webhooksGatewayController_1(orderId: any, options?: AxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).webhooksGatewayController_1(orderId, options).then((request) => request(this.axios, this.basePath));
    }
}


