import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import translationDE from './locales/de/translation.json';
import translationEN from './locales/en/translation.json';

export function initI18n() {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      lng: 'de',
      fallbackLng: 'en',
      debug: false,
      interpolation: {
        escapeValue: false,
      },
      resources: {
        de: {
          translation: translationDE,
        },
        en: {
          translation: translationEN,
        },
      },
    });
}
